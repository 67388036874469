import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import routes from './routes'
import { branding } from 'src/commons/branding'
import { computed } from 'vue'
import { i18n } from 'src/boot/i18n'
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const createHistory = process.env.SERVER
  ? createMemoryHistory
  : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

export const Router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(process.env.VUE_ROUTER_BASE)
})

const brandName = computed(() => {
  return branding.value?.BRAND_NAME || 'Panel'
})

Router.beforeEach((to, from, next) => {
  const title = to.meta.title as string
  document.title = title ? `${brandName.value} - ${i18n.global.t(title)}` : brandName.value
  next()
})
export default route(function (/* { store, ssrContext } */) {
  return Router
})
