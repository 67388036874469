import { defineStore } from 'pinia'
/* eslint-disable no-console */
import echo from 'src/commons/echo'
import push from 'push.js'
import { Notify } from 'quasar'

export const notificationsStore = defineStore('notifications', {
  state: () => ({
    channel: '',
    notifications: [] as { message?: string; text: string; popup?: string }[],
    options: {} as { message?: string, classes: 'positive' | 'negative' | 'info' | 'warning', icon: string, timeout?: number }
  }),
  actions: {
    /**
     * @field { String } text
     * @field { Boolean } popup
     * @field { String } color
     * @field { Integer } timeout? [5000]
     * @field { String } pos? [top-right]
     */
    notify (payload: {
      message?: string;
      text: string;
      popup?: string;
      timeout?: number;
      color?: 'positive' | 'negative' | 'info' | 'warning'
    }) {
      if (payload.message) {
        payload.text = payload.message
      } else {
        payload.message = payload.text
      }
      if (payload.popup) {
        if (push.Permission.get() === 'granted') {
          push.create(payload.text).catch(err => {
            console.error(err)
          })
          return this.addNotification(payload)
        }
      }
      this.setOptions(payload)
      Notify.create(this.options)
      this.addNotification(payload)
    },
    subscribe (customerId: string) {
      if (!customerId) {
        return
      }
      const channel = 'notifications-' + customerId
      this.setSubscribe(channel)
      echo
        .private(channel)
        .listen(
          '.NOTIFICATION',
          (payload: { message: string; text: string; popup: string, classes: 'positive' | 'negative' | 'info' | 'warning' }) => {
            this.notify(payload)
          }
        )
    },
    unsubscribe () {
      return new Promise<unknown>(() => {
        if (this.channel?.length) {
          this.reset()
          echo.leave(this.channel)
          this.subscribe('')
        }
      })
    },
    setSubscribe (channel: string) {
      this.channel = channel
    },
    addNotification (payload: {
      message?: string;
      text: string;
      popup?: string;
    }) {
      this.notifications.push(payload)
    },
    reset () {
      this.notifications = []
    },
    setOptions (payload: {
      message?: string;
      text: string;
      popup?: string;
      timeout?: number;
      color?: 'positive' | 'negative' | 'info' | 'warning'
    }) {
      this.options.timeout = payload.timeout
      this.options.message = payload.message
      switch (payload.color) {
        case 'positive':
          this.options.classes = 'positive'
          this.options.icon = 'mdi-check-circle-outline'
          break
        case 'negative':
          this.options.classes = 'negative'
          this.options.icon = 'mdi-alert-octagon-outline'
          break
        case 'info':
          this.options.classes = 'info'
          this.options.icon = 'mdi-information-outline'
          break
        case 'warning':
          this.options.classes = 'warning'
          this.options.icon = 'mdi-alert-outline'
          break
        default:
          this.options.classes = 'info'
          this.options.icon = 'mdi-information-outline'
      }
    }
  },
  getters: {
    getNotifications (state) {
      return state.notifications
    }
  }
})
