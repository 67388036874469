import { register } from 'register-service-worker'
import { Notify } from 'quasar'
import { v4 } from 'uuid'
import { ref } from 'vue'
import { highlightedLog } from 'src/commons/Utilities'

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

const warning = () => {
  Notify.create({
    color: 'negative',
    icon: 'mdi-alert',
    message: 'Failed to update, close other tabs and try again.',
    position: 'top-right',
    classes: 'warning'
  })
}

const uniqueID = v4()
let counter = 1
const broadcaster = new BroadcastChannel('Consumer')

broadcaster.onmessage = function (event) {
  if (event.data === 'plus') {
    counter++
  } else if (event.data !== uniqueID) {
    broadcaster.postMessage('plus')
  }
}

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready () {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      highlightedLog('App is being served from cache by a service worker.', ['background: #222', 'color: #16a34a'])
    }
  },

  registered (/* registration */) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      highlightedLog('Service worker has been registered.', ['background: #222', 'color: #16a34a'])
    }
  },

  cached (/* registration */) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      highlightedLog('Content has been cached for offline use.', ['background: #222', 'color: #16a34a'])
    }
  },

  updatefound (/* registration */) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      highlightedLog('New content is downloading.', ['background: #222', 'color: #16a34a'])
    }
  },

  updated (registration) {
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' })
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      highlightedLog('New content is available; please refresh.', ['background: #222', 'color: #16a34a'])
    }

    notif.value = Notify.create({
      color: 'warning',
      icon: 'mdi-cached',
      message: 'There is an update available. Click to update now.',
      timeout: 0,
      group: false,
      multiLine: true,
      position: 'top-right',
      classes: 'warning',
      actions: [
        {
          label: 'Update',
          color: 'warning',
          class: 'warning-b font-bold',
          noDismiss: true,
          handler: () => {
            notifAction(registration)
          }
        }
      ]
    })
  },

  offline () {
    // highlightedLog('No internet connection found. App is running in offline mode.', ['background: #222', 'color: #16a34a'])
  },

  error (/* err */) {
    // console.error('Error during service worker registration:', err)
  }
})

const notif = ref()
const loading = ref(false)

const notifAction = (registration: ServiceWorkerRegistration) => {
  // start loading state by recreating the notify
  loading.value = !loading.value
  notif.value({
    actions: [
      {
        label: 'Update',
        color: 'warning',
        loading: loading.value,
        noDismiss: true,
        handler: () => {
          notifAction(registration)
        }
      }
    ]
  })

  // the functionality takes place right after the loading state
  counter = 1
  broadcaster.postMessage(uniqueID)
  setTimeout(() => {
    highlightedLog(counter, ['background: #222', 'color: #16a34a'])
    if (counter > 1) {
      // if more that 1 tabs warning and stop loading state by recreating the notify
      warning()
      loading.value = !loading.value
      notif.value({
        actions: [
          {
            label: 'Update',
            color: 'warning',
            loading: loading.value,
            noDismiss: true,
            handler: () => {
              // start the steps again by running the function
              notifAction(registration)
            }
          }
        ]
      })
    } else {
      if ('serviceWorker' in navigator) {
        (navigator.serviceWorker as unknown as ServiceWorkerContainer)
          .getRegistrations()
          .then(function (registrations) {
            if (registrations.length) {
              for (const navigatorRegistration of registrations) {
                navigatorRegistration.unregister()
              }
              registration.unregister().then(function () {
                window.location.reload()
              })
            } else {
              registration.unregister().then(function () {
                window.location.reload()
              })
            }
          })
      }
    }
  }, 1000)
}
