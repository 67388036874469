import {
  ActionTypes

} from 'src/commons/Enums'

export default {

  type: {
    // Server Task Types

    [ActionTypes.ADDONS_ASSOCIATE]: 'Associate Add On',
    [ActionTypes.ADDONS_CREATE]: 'Create Add On',
    [ActionTypes.ADDONS_DELETE]: 'Delete Add On',
    [ActionTypes.ADDONS_DISASSOCIATE]: 'Disassociate Add on',
    [ActionTypes.ADDONS_UPDATE]: 'Update Add On',
    [ActionTypes.BACKUP_SERVER_CREATE]: 'Create Backup Server',
    [ActionTypes.BACKUP_SERVER_DELETE]: 'Delete Backup Server',
    [ActionTypes.BACKUP_SERVER_UPDATE]: 'Update Backup Server',
    [ActionTypes.CLOUD_SERVER_BACKUPS_UPDATE]: 'Update Cloud Server Backup',
    [ActionTypes.CLOUD_SERVER_CONSOLE]: 'Create Console Token',
    [ActionTypes.CLOUD_SERVER_DISK_EDIT_INFOS]: 'Edit Cloud Server Disk Infos',
    [ActionTypes.CLOUD_SERVER_EDIT_INFOS]: 'Edit Cloud Server Infos',
    [ActionTypes.CLOUD_SERVER_FIRWALL_RULE_ADD]: 'Add Cloud Server Firewall Rule',
    [ActionTypes.CLOUD_SERVER_FIRWALL_RULE_DELETE]: 'Delete Cloud Server Firewall Rule',
    [ActionTypes.CLOUD_SERVER_FIRWALL_RULE_EDIT]: 'Edit Cloud Server Firewall Rule',
    [ActionTypes.CLOUD_SERVER_OPERATING_SYSTEM_VERSION_UPDATE]: 'Update Cloud Server Operating System Version',
    [ActionTypes.CLOUD_SERVER_PROTECTION_DISABLE]: 'Disable Cloud Server Protection',
    [ActionTypes.CLOUD_SERVER_PROTECTION_ENABLE]: 'Enable Cloud Server Protection',
    [ActionTypes.CLOUD_SERVER_READ_ONLY_MODE_UPDATE]: 'Update Cloud Server Read Only Mode',
    [ActionTypes.CLOUD_SERVER_SNAPSHOT_EDIT]: 'Edit Cloud Server Snapshot',
    [ActionTypes.CLOUD_SERVER_STATUS_UPDATE]: 'Update Cloud Server Status',
    [ActionTypes.CUSTOMER_CREATE]: 'Create Customer',
    [ActionTypes.CUSTOMER_UPDATE]: 'Update Customer',
    [ActionTypes.CUSTOMER_INFO_UPDATE]: 'Update Customer Info',
    [ActionTypes.CUSTOMER_LEAD_APPROVE]: 'Approve Customer Lead',
    [ActionTypes.CUSTOMER_LEAD_DUPLICATE]: 'Duplicate Customer Lead',
    [ActionTypes.CUSTOMER_LEAD_REJECT]: 'Reject Customer Lead',
    [ActionTypes.CUSTOMER_LEAD_SUBMIT]: 'Submit Customer Lead',
    [ActionTypes.CUSTOMER_PRICING_CREATE]: 'Create Customer Pricing',
    [ActionTypes.CUSTOMER_PRICING_UPDATE]: 'Update Customer Pricing',
    [ActionTypes.CUSTOMER_RESOURCES_UPDATE]: 'Update Customer Resources',
    [ActionTypes.CUSTOMER_USERS_INVITE]: 'Invite Customer Users',
    [ActionTypes.CUSTOMER_USER_EDIT]: 'Edit Customer Users',
    [ActionTypes.CUSTOMER_USER_REMOVE]: 'Remove Customer Users',
    [ActionTypes.DATACENTER_CREATE]: 'Create Datacenter',
    [ActionTypes.DATACENTER_UPDATE]: 'Update Datacenter',
    [ActionTypes.DATACENTER_DELETE]: 'Delete Datacenter',
    [ActionTypes.DEDICATED_SERVER_CONSOLE]: 'Create Dedicated Server Console Token',
    [ActionTypes.DEDICATED_SERVER_CONSOLE_RESTART]: 'Restart Dedicated Server Console',
    [ActionTypes.DEDICATED_SERVER_EDIT_INFOS]: 'Edit Dedicated Server Infos',
    [ActionTypes.IP_ADDRESS_BLOCK_CREATE]: 'Create Ip Address Block',
    [ActionTypes.IP_ADDRESS_BLOCK_UPDATE]: 'Update Ip Address Block',
    [ActionTypes.IP_ADDRESS_BLOCK_DELETE]: 'Delete Ip Address Block',
    [ActionTypes.IP_INTERNAL_NOTE_UPDATE]: 'Update Ip Address Internal Note',
    [ActionTypes.LICENCES_ASSOCIATE]: 'Associate Licences',
    [ActionTypes.LICENCES_DISASSOCIATE]: 'Disassociate Licences',
    [ActionTypes.LICENCES_CREATE]: 'Create Licences',
    [ActionTypes.LICENCES_DELETE]: 'Delete Licences',
    [ActionTypes.LICENCES_UPDATE]: 'Update Licences',
    [ActionTypes.LICENCES_QUANTITY_EDIT]: 'Edit Licences Quantity',
    [ActionTypes.NETWORK_STATUS_UPDATE]: 'Update Network Status',
    [ActionTypes.PRIVATE_NETWORK_EDIT_INFOS]: 'Edit Private Network Infos',
    [ActionTypes.PRODUCT_CATEGORIES_CREATE]: 'Create Product Category',
    [ActionTypes.PRODUCT_CATEGORIES_UPDATE]: 'Update Product Category',
    [ActionTypes.PRODUCT_CATEGORIES_DELETE]: 'Delete Product Category',
    [ActionTypes.PRODUCT_CREATE]: 'Create Product',
    [ActionTypes.PRODUCT_UPDATE]: 'Update Product',
    [ActionTypes.PRODUCT_DELETE]: 'Delete Product',
    [ActionTypes.PUBLIC_NETWORK_CREATE]: 'Create Public Network',
    [ActionTypes.PUBLIC_NETWORK_UPDATE]: 'Update Public Network',
    [ActionTypes.PUBLIC_NETWORK_DELETE]: 'Delete Public Network',
    [ActionTypes.PRIVATE_NETWORK_PROTECTION_ENABLE]: 'Private Network Protection Enable',
    [ActionTypes.PRIVATE_NETWORK_PROTECTION_DISABLE]: 'Private Network Protection Disable',
    [ActionTypes.RESOURCES_CREATE]: 'Create Resources',
    [ActionTypes.RESOURCES_UPDATE]: 'Update Resources',
    [ActionTypes.RESOURCES_DELETE]: 'Delete Resources',
    [ActionTypes.RESOURCE_GROUPS_CREATE]: 'Create Resource Groups',
    [ActionTypes.RESOURCE_GROUPS_UPDATE]: 'Update Resource Groups',
    [ActionTypes.RESOURCE_GROUPS_DELETE]: 'Delete Resource Groups',
    [ActionTypes.ROUTER_CERTIFICATE_ADD]: 'Add Router Certificate',
    [ActionTypes.ROUTER_CERTIFICATE_DELETE]: 'Delete Router Certificate',
    [ActionTypes.ROUTER_CERTIFICATE_NOTES_EDIT]: 'Edit Router Certificate Notes',
    [ActionTypes.ROUTER_EDIT_INFOS]: 'Edit Router Infos',
    [ActionTypes.ROUTER_FIRWALL_RULE_ADD]: 'Add Router Firewall Rule',
    [ActionTypes.ROUTER_FIRWALL_RULE_DELETE]: 'Delete Router Firewall Rule',
    [ActionTypes.ROUTER_FIRWALL_RULE_EDIT]: 'Edit Router Firewall Rule',
    [ActionTypes.ROUTER_IPSEC_ADD]: 'Add Router IP Sec',
    [ActionTypes.ROUTER_IPSEC_DELETE]: 'Delete Router IP Sec',
    [ActionTypes.ROUTER_IPSEC_EDIT]: 'Edit Router IP Sec',
    [ActionTypes.ROUTER_IPSEC_GLOBAL_UPDATE]: 'Update Global Router IP Sec',
    [ActionTypes.ROUTER_IPSEC_GLOBAL_DELETE]: 'Delete Global Router IP Sec',
    [ActionTypes.ROUTER_NAT_ADD]: 'Add Router NAT Rule',
    [ActionTypes.ROUTER_NAT_DELETE]: 'Delete Router NAT Rule',
    [ActionTypes.ROUTER_NAT_EDIT]: 'Edit Router NAT Rule',
    [ActionTypes.ROUTER_PROTECTION_ENABLE]: 'Enable Router Protection',
    [ActionTypes.ROUTER_PROTECTION_DISABLE]: 'Disable Router Protection',
    [ActionTypes.ROUTER_STATIC_ROUTE_ADD]: 'Add Router Static Route',
    [ActionTypes.ROUTER_STATIC_ROUTE_DELETE]: 'Delete Router Static Route',
    [ActionTypes.ROUTER_STATIC_ROUTE_EDIT]: 'Edit Router Static Route',
    [ActionTypes.SSH_KEY_CREATE]: 'Create SSH key',
    [ActionTypes.SSH_KEY_EDIT_INFOS]: 'Edit SSH key Infos',
    [ActionTypes.SSH_KEY_DELETE]: 'Delete SSH key',
    [ActionTypes.STORAGE_API_TOKEN_CREATE]: 'Create Storage Api Token',
    [ActionTypes.STORAGE_EDIT_INFOS]: 'Edit Storage Infos',
    [ActionTypes.STORAGE_PROTECTION_ENABLE]: 'Enable Storage Protection',
    [ActionTypes.STORAGE_PROTECTION_DISABLE]: 'Disable Storage Protection',
    [ActionTypes.USER_ROLE_UPDATE]: 'Update User Role',
    [ActionTypes.USER_STATUS_UPDATE]: 'Update User Status',
    [ActionTypes.VMWARE_VCENTER_CREATE]: 'Create Vmware Vcenter',
    [ActionTypes.VMWARE_VCENTER_DISK_TYPES_ASSIGN]: 'Assign Vmware Vcenter Disk Types',
    [ActionTypes.VMWARE_VCENTER_UPDATE]: 'Update Vmware Vcenter',
    [ActionTypes.VPN_CONCENTRATOR_EDIT_INFOS]: 'Edit VPN Concentrators Infos'
  }

}
