// TODO gamietai gia kapoio logo
import { defineStore } from 'pinia'

export const changelogStore = defineStore('changelog', {
  state: () => ({
    loading: 0 as number, // TODO να μπει οταν γινει το backend
    versions: [
      {
        version: '2.7.0',
        release_date: '06-02-2024',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Private IPs information and improved visibility on all instances'
              },
              {
                type: 'REWORK',
                description: 'Improved search functionallity, instance & dashboard'
              },
              {
                type: 'REWORK',
                description: 'Improved Look on all pages and dialogs'
              },
              {
                type: 'REWORK',
                description: 'Improved loading speeds'
              }
            ]
          },
          {
            title: 'Cloud Server',
            changes: [
              {
                type: 'REWORK',
                description: 'Improved Clone Cloud Server proccesses'
              },
              {
                type: 'FEATURE',
                description: 'Keys & Access Management'
              },
              {
                type: 'REWORK',
                description: 'Improved Backup Speeds'
              },
              {
                type: 'FEATURE',
                description: 'Added Backup File Level Restore for Windows Servers'
              },
              {
                type: 'FEATURE',
                description: 'Added process aware backups'
              },
              {
                type: 'FEATURE',
                description: 'Added boot type changer in advanced settings'
              }
            ]
          },
          {
            title: 'Object Storage',
            changes: [
              {
                type: 'FEATURE',
                description: 'File Browsing'
              },
              {
                type: 'FEATURE',
                description: 'Keys & Access Management'
              }
            ]
          },
          {
            title: 'Cloud Server',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed various validation issues with dialogs'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed hydration issues with dialogs'
              }
            ]
          }
        ]
      },
      {
        version: '2.6.2',
        release_date: '24-09-2024',
        changelog: [
          {
            title: 'Object Storage',
            changes: [
              {
                type: 'FEATURE',
                description: 'Enabled Object Storage Instances.'
              }
            ]
          }
        ]
      },
      {
        version: '2.6.1',
        release_date: '12-09-2024',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed bug various tasks failing on some occations .'
              },
              {
                type: 'REWORK',
                description: 'Fixed responsivity issues with full screen dialogs.'
              },
              {
                type: 'REWORK',
                description: 'Reworked realtime updates.'
              },
              {
                type: 'FEATURE',
                description: 'Added brand colors.'
              },
              {
                type: 'REWORK',
                description: 'Generic performance REWORKs.'
              }
            ]
          }
        ]
      },
      {
        version: '2.5.6',
        release_date: '12-07-2024',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with refreshing data for big instances.'
              },
              {
                type: 'REWORK',
                description: 'Added customer order notifications.'
              },
              {
                type: 'REWORK',
                description: 'Added DNS Zones to Dashboard.'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with CPU Tier change on Rescale.'
              }
            ]
          },
          {
            title: 'VPN Concentrators',
            changes: [
              {
                type: 'REWORK',
                description: 'Improved default firewall setup.'
              },
              {
                type: 'REWORK',
                description: 'Allow only one connection per VPN Account.'
              }
            ]
          },
          {
            title: 'DNS Zones',
            changes: [
              {
                type: 'REWORK',
                description: 'Added the ability to add records with default zone TTL.'
              },
              {
                type: 'REWORK',
                description: 'Improved import flow.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug that crashed records table in some occations.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed issues with resources.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug that would not update DNS Zones on customer change.'
              }
            ]
          }
        ]
      },
      {
        version: '2.5.3',
        release_date: '03-07-2024',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with language settings.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed update notification bug.'
              },
              {
                type: 'REWORK',
                description: 'Fixed update notification bug.'
              },
              {
                type: 'FEATURE',
                description: 'Added DNS Instance.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed resource limiting visual bugs.'
              },
              {
                type: 'FEATURE',
                description: 'Last task issue inication for all instances.'
              },
              {
                type: 'REWORK',
                description: 'Improved user invite dialog.'
              },
              {
                type: 'REWORK',
                description: 'Improved user security settings.'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Clone Server functionallity.'
              },
              {
                type: 'REWORK',
                description: 'Added Addon Services on server overview page.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed permission bug with Virtual Media mount.'
              },
              {
                type: 'REWORK',
                description: 'Improve UI on backup dialog.'
              },
              {
                type: 'FEATURE',
                description: 'Added private CPU Tiers.'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'REWORK',
                description: 'Improve NAT Validation.'
              }
            ]
          },
          {
            title: 'Storage Accounts',
            changes: [
              {
                type: 'REWORK',
                description: 'Improved file manager functionallities.'
              }
            ]
          }
        ]
      },
      {
        version: '2.4.14',
        release_date: '27-05-2024',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'REWORK',
                description: 'Added graph history 90 Days.'
              },
              {
                type: 'REWORK',
                description: 'Added customer order notifications.'
              }
            ]
          }
        ]
      },

      {
        version: '2.4.2',
        release_date: '24-01-2024',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added traffic graphs to instances.'
              },
              {
                type: 'REWORK',
                description: 'Improved translations.'
              },
              {
                type: 'REWORK',
                description: 'Improved task icons.'
              },
              {
                type: 'REWORK',
                description: 'Improved resource calculations on rescale components.'
              },
              {
                type: 'REWORK',
                description: 'Added graph numbers formatting.'
              },
              {
                type: 'FEATURE',
                description: 'Added current instances export.'
              }
            ]
          },
          {
            title: 'Storage Accounts',
            changes: [
              {
                type: 'REWORK',
                description: 'Improved file manager.'
              },
              {
                type: 'FEATURE',
                description: 'Added protection.'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'REWORK',
                description: 'Added licenses to overview page.'
              },
              {
                type: 'REWORK',
                description: 'Added IP Address actions to overview page.'
              },
              {
                type: 'FEATURE',
                description: 'Added latency graphs.'
              },
              {
                type: 'REWORK',
                description: 'Improved deployment speed by 70%'
              }

            ]
          }
        ]
      },

      {
        version: '2.3.1',
        release_date: '21-09-2023',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed issue that would not switch selected customer on instance pages.'
              },
              {
                type: 'REWORK',
                description: 'Reworked and added translations.'
              },
              {
                type: 'REWORK',
                description: 'Improved homepage search, ability to search with service from your statements.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed issue that would page unresponsive when you cleared your input.'
              },
              {
                type: 'REWORK',
                description: 'Improved multi input elements to be editable and individually validated.'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed graphs limits not working correctly uppon page refresh.'
              },
              {
                type: 'FEATURE',
                description: 'New backup functionalities, disk backup and restore.'
              },
              {
                type: 'FEATURE',
                description: 'Added ability to edit your daily backup configuration.'
              },
              {
                type: 'FEATURE',
                description: 'CPU Tiering is now available.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed firewall issue with multiple ports.'
              },
              {
                type: 'REWORK',
                description: 'Improved copy paste behaviour.'
              },
              {
                type: 'FEATURE',
                description: 'Added 90 Days history on graphs.'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'REWORK',
                description: 'Improved inputs for IP/Port on all forms.'
              }
            ]
          },
          {
            title: 'VPN Concentrators',
            changes: [
              {
                type: 'FEATURE',
                description: 'New one file config, with compatibility with more devices.'
              },
              {
                type: 'FEATURE',
                description: 'Added static routing.'
              },
              {
                type: 'REWORK',
                description: 'Added pagination with search to VPN Users.'
              }
            ]
          },
          {
            title: 'SSH Keys',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added search capabilities.'
              }
            ]
          }
        ]
      },
      {
        version: '2.2.12',
        release_date: '24-05-2023',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed not found errors on profile page.'
              },
              {
                type: 'BUG_FIX',
                description: 'Wrong icons in some menus.'
              },
              {
                type: 'BUG_FIX',
                description: 'Various Router v2 Fixes.'
              },
              {
                type: 'REWORK',
                description: 'Forgot password flow and messages.'
              }
            ]

          }
        ]
      },
      {
        version: '2.2.11',
        release_date: '11-05-2023',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with profile update.'
              },
              {
                type: 'REWORK',
                description: 'Email Validation proccess.'
              },
              {
                type: 'FEATURE',
                description: 'Added the ability to change timezone from customer page.'
              },
              {
                type: 'REWORK',
                description: 'Email Validation proccess.'
              },
              {
                type: 'REWORK',
                description: 'Reworked search from Home Page.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed minor bugs with captcha verification flow.'
              }
            ]

          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added the ability to have unassigned network cards.'
              },
              {
                type: 'FEATURE',
                description: 'Added the ability to connect/diconnect network cards.'
              }
            ]

          },
          {
            title: 'VPN Concentrators',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added the ability to have unassigned network cards.'
              },
              {
                type: 'FEATURE',
                description: 'Added the ability to connect/diconnect network cards.'
              }
            ]

          }
        ]
      },
      {
        version: '2.2.0',
        release_date: '04-04-2023',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Seach also searches with Order/Service Code (also found in your invoice)'
              },
              {
                type: 'FEATURE',
                description: 'Added user notification for Login and Failed 2FA Attempt'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed order overview appearing blank in some cases'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug that made task get stuck after long processing time'
              },
              {
                type: 'REWORK',
                description: 'Reworked error pages'
              },
              {
                type: 'FEATURE',
                description: 'Added protection on unauthenticated forms (Login/Register)'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed copy paste issues with console'
              },
              {
                type: 'REWORK',
                description: 'Improved graphs max size (disks)'
              },
              {
                type: 'FEATURE',
                description: 'Added duplicate feature on Firewall Rules'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed search issue on routers page'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with overlapping networks on IPSEC Dialog'
              },
              {
                type: 'FEATURE',
                description: 'Added ability to change Uplink Speed'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with IPSEC Flash Status (on list) would occationaly not load'
              },
              {
                type: 'FEATURE',
                description: 'Added duplicate feature on NAT,Firewall Rules'
              }
            ]
          },
          {
            title: 'VPN Concentrators',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with validation block on non-overlapping networks'
              }
            ]
          }
        ]
      },
      {
        version: '2.1.1',
        release_date: '07-02-2023',
        changelog: [
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed varius issues that impacted console performance and experience'
              },
              {
                type: 'REWORK',
                description: 'Improved graphs max size (disks)'
              }
            ]
          },
          {
            title: 'Storage Accounts',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed issue that reported wrong storage account size'
              }
            ]
          }
        ]
      },
      {
        version: '2.1.0',
        release_date: '03-02-2023',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed issue with 2FA Verification on session timeout'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'REWORK',
                description: 'Reworked Console'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed Ordering for Backup Options'
              },
              {
                type: 'FEATURE',
                description: 'Added max graph value for CPU'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed issue with editing nat rules'
              }
            ]
          }
        ]
      },
      {
        version: '2.0.17',
        release_date: '19-01-2023',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added customer page'
              },
              {
                type: 'FEATURE',
                description: 'Added customer roles and user management'
              },
              {
                type: 'FEATURE',
                description: 'Added customer orders listing'
              },
              {
                type: 'REWORK',
                description: 'Reworked Customer Selector'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed issue that Help Pages would open only the default page'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added custom templates for customers'
              },
              {
                type: 'FEATURE',
                description: 'Updated Templates to EFI'
              },
              {
                type: 'REWORK',
                description: 'Refined import logic'
              },
              {
                type: 'REWORK',
                description: 'Backup Feature will also change size of backup space'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed operating system order'
              }
            ]
          },
          {
            title: 'Cloud Server Disks',
            changes: [
              {
                type: 'REWORK',
                description: 'Better cloud server listing on move'
              }

            ]
          },
          {
            title: 'Storage Accounts',
            changes: [
              {
                type: 'REWORK',
                description: 'Reworked processes to be more robust'
              }

            ]
          }
        ]
      },
      {
        version: '1.3.14',
        release_date: '16-02-2022',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'REWORK',
                description: 'Reworked resources view'
              },
              {
                type: 'REWORK',
                description: 'Search on homepage for all instances'
              },
              {
                type: 'REWORK',
                description: 'Updated loading screens'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed issue that Help Pages would open only the default page'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added notification option for Daily Backups'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed that disks would not show task progress'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed that disabled update button on firewall rules'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'REWORK',
                description: 'Updated statistics for IPSEC Tunnels'
              },
              {
                type: 'FEATURE',
                description: 'Added Redirection mode on Load Balancers'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed bug that would block you from updating Load Balancer settings'
              }
            ]
          },
          {
            title: 'Private Networks',
            changes: [
              {
                type: 'FEATURE',
                description:
                  'Added option to view all connected instances on private network with relevant information (IP Address, MAC Address)'
              }
            ]
          }
        ]
      },
      {
        version: '1.2.36',
        release_date: '30-12-2021',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'REWORK',
                description: 'Reworked public network (Internet) allocation'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed visual bugs'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Addon Services'
              },
              {
                type: 'FEATURE',
                description: 'Added extra licenses'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed issues with /32 networks on firewall rules'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'REWORK',
                description:
                  'Updated static routes to accept more specific networks'
              }
            ]
          }
        ]
      },
      {
        version: '1.2.25',
        release_date: '13-12-2021',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'REWORK',
                description:
                  'Added checks to ensure that network is not being used when deleted'
              },
              {
                type: 'REWORK',
                description:
                  'Fixed multiple minor visual bugs related to dark theme'
              },
              {
                type: 'REWORK',
                description:
                  'Changelog notification will appear only if there are unread changelogs'
              },
              {
                type: 'REWORK',
                description: 'Reworked translations and notifications'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bugged billing option on side menu'
              },
              {
                type: 'BUG_FIX',
                description:
                  "Fixed bug that IP Addresses wouldn't allocate randomly"
              },
              {
                type: 'BUG_FIX',
                description: 'Zero value resources appeared as unlimited'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'REWORK',
                description: 'Changed Public Network options for Cloud Servers'
              },
              {
                type: 'REWORK',
                description:
                  'Locked Cloud Server Disk operations when Cloud Server is busy'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'REWORK',
                description: 'Better validation for Load Balancers'
              },
              {
                type: 'REWORK',
                description: 'Changed appearance for inactive IPSEC VPNs'
              },
              {
                type: 'REWORK',
                description:
                  "Added check when deleting a network card that's used for Load Balancers"
              },
              {
                type: 'REWORK',
                description:
                  'Added check when deleting the last internal network card'
              }
            ]
          }
        ]
      },
      {
        version: '1.2.15',
        release_date: '22-11-2021',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed greek font appearance'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug with PTR update on server release'
              },
              {
                type: 'FEATURE',
                description: 'Added Dark Theme(beta)'
              },
              {
                type: 'REWORK',
                description: 'Reworked name validations'
              },
              {
                type: 'REWORK',
                description: 'Reworked phone verification flow'
              },
              {
                type: 'REWORK',
                description: 'Various translation fixes'
              },
              {
                type: 'REWORK',
                description:
                  'Added better feedback for user on update operations'
              },
              {
                type: 'REWORK',
                description: 'Reworked CIDR Validations'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Windows Server 2022'
              },
              {
                type: 'FEATURE',
                description: 'Added 30 Days Daily Backup'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added support for Dynamic Mode IPSEC VPN'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed NAT issue with editing when you have more that 2 public ips'
              },
              {
                type: 'REWORK',
                description:
                  'Update Load Balancer statistics for better readability and usability'
              },
              {
                type: 'REWORK',
                description:
                  'IPSEC VPN Listing includes tunnel cidr for route based VPNs'
              }
            ]
          },
          {
            title: 'VPN Concentrators',
            changes: [
              {
                type: 'REWORK',
                description:
                  'Added message to re-download accounts when changing routes'
              }
            ]
          }
        ]
      },
      {
        version: '1.2.0',
        release_date: '29-10-2021',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Billing Category'
              },
              {
                type: 'FEATURE',
                description: 'Added Translations for Greek language'
              },
              {
                type: 'REWORK',
                description: 'Updated Knowledge Base'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed issue that email deferred in some occasions'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed issue with Storage Accounts that failed on deletion'
              },
              {
                type: 'REWORK',
                description: 'Optimized experience for vpn user connection'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Debian 11'
              },
              {
                type: 'REWORK',
                description: 'Improved Disk Type delegation'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed issue with reinstalling server with no public IPs'
              },
              {
                type: 'REWORK',
                description: 'Updated Knowledge Base'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'REWORK',
                description: 'Load Balancers are allowed to have 1 member'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed load balancer monitoring issue with requests.'
              },
              {
                type: 'FEATURE',
                description: 'Added IPSEC VPN Route Based mode'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed minor validation issues with IPSEC,Firewall,Static Routes'
              },
              {
                type: 'REWORK',
                description:
                  'Reworked validation for network card ips, and public ip assignement policies'
              }
            ]
          }
        ]
      },
      {
        version: '1.1.0',
        release_date: '10-9-2021',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Vpn Concentrators'
              },
              {
                type: 'FEATURE',
                description: 'Added IP protection for public networks'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added retention policy for backup'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Load Balancer persistence modes'
              },
              {
                type: 'FEATURE',
                description: 'Added Ip Sec IKE version'
              },
              {
                type: 'FEATURE',
                description: 'Added Ip Sec remote id'
              },
              {
                type: 'REWORK',
                description: 'Updated router graphs'
              }
            ]
          },
          {
            title: 'Storage Accounts',
            changes: [
              {
                type: 'BUG_FIX',
                description: "Fixed email's credentials"
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed disk issue for storage accounts'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed issue that sometimes storage account creation failed'
              }
            ]
          }
        ]
      },
      {
        version: '1.0.16',
        release_date: '24-3-2021',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Storage Accounts'
              },
              {
                type: 'FEATURE',
                description: 'Added system notifications'
              },
              {
                type: 'REWORK',
                description: 'Added real time refresh to Resources'
              },
              {
                type: 'REWORK',
                description: 'Added extra validation on user registration'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed various resources calculation bugs'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description:
                  'Added MAC Address, IP and Connection Status information on Network Cards'
              },
              {
                type: 'REWORK',
                description:
                  'Added option to create server with private networking'
              },
              {
                type: 'REWORK',
                description:
                  'Redesigned listing structure and search capabilities'
              },
              {
                type: 'REWORK',
                description: 'Added safety validation for powering-off server'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed bug that locked rescaling on powered-off server'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug that made large disks operations fail'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug that made disks move fail sometimes'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug when adding a new cloud disk'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed bug that created network cards would be disconnected'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added NAT'
              },
              {
                type: 'FEATURE',
                description:
                  'Added ability to add multiple secondary ips on each network card'
              },
              {
                type: 'FEATURE',
                description: 'Added ability to change notes from menu'
              },
              {
                type: 'FEATURE',
                description: 'Added IPSEC VPN status and statistics'
              },
              {
                type: 'REWORK',
                description:
                  'Added option to create router with private networking'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed bug when adding internal network cards'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed bug that removed default gateway on static routes application'
              }
            ]
          },
          {
            title: 'Graphs',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added option to disable live refresh'
              },
              {
                type: 'FEATURE',
                description: 'Added option to change graph reporting period'
              },
              {
                type: 'FEATURE',
                description: 'Added option to filter out network cards'
              },
              {
                type: 'REWORK',
                description: 'Added network card and disk names'
              },
              {
                type: 'REWORK',
                description: 'Redesigned graphs representations'
              }
            ]
          },
          {
            title: 'Storage Accounts',
            changes: [
              {
                type: 'FEATURE',
                description: 'Released Storage Accounts for FTP/FTPS/SFTP.'
              },
              {
                type: 'FEATURE',
                description: 'In-Browser File Manager.'
              },
              {
                type: 'FEATURE',
                description: 'Resize Operations.'
              }
            ]
          }
        ]
      },
      {
        version: '1.0.9',
        release_date: '4-12-2020',
        changelog: [
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added configurable resources.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed Cloud Server create issue.'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed NOT_FOUND issues on re-login and registration.'
              },
              {
                type: 'REWORK',
                description: 'Changed Public & Private Cloud notation.'
              },
              {
                type: 'REWORK',
                description: 'Visual fixes for modals and menus.'
              },
              {
                type: 'REWORK',
                description: 'Changed Icons and Login/Registration Screens.'
              },
              {
                type: 'REWORK',
                description: 'Added backup for multiple locations.'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'BUG_FIX',
                description: 'Fixed Cloud Server deletion failures.'
              },
              {
                type: 'BUG_FIX',
                description:
                  'Fixed snapshot bug that could make them disappear on frontend.'
              },
              {
                type: 'REWORK',
                description:
                  'Added password confirmation on Cloud Server create.'
              },
              {
                type: 'FEATURE',
                description: 'Added virtual disk media mount/unmount option.'
              }
            ]
          },
          {
            title: 'Ip Addresses',
            changes: [
              {
                type: 'FEATURE',
                description: 'IP Addresses an be moved thru services.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed a bug with reverse dns records.'
              }
            ]
          },
          {
            title: 'Routers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added IPsec VPN.'
              },
              {
                type: 'FEATURE',
                description: 'Added Static Routing.'
              },
              {
                type: 'FEATURE',
                description: 'Added Router Firewalling.'
              },
              {
                type: 'FEATURE',
                description: 'Routers can now have multiple IP Addresses.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed validation issues with load balancers.'
              }
            ]
          }
        ]
      },
      {
        version: '1.0.7',
        release_date: '26-11-2020',
        changelog: [
          {
            title: 'Newly Added',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Routers and Load Balancers.'
              }
            ]
          },
          {
            title: 'Cloud Servers',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Cloud Server Reinstall feature.'
              },
              {
                type: 'FEATURE',
                description:
                  'Added option to create backups and restore to them on demand.'
              }
            ]
          },
          {
            title: 'Ip Addresses',
            changes: [
              {
                type: 'FEATURE',
                description:
                  'Added Dedicated Ip Address Subnets to be used by specific customer.'
              },
              {
                type: 'BUG_FIX',
                description: 'Fixed a bug when releasing IP Addresses.'
              }
            ]
          },
          {
            title: 'General',
            changes: [
              {
                type: 'FEATURE',
                description: 'Added Status page link.'
              },
              {
                type: 'FEATURE',
                description: "Added Changelog (that's me) on help menu."
              },
              {
                type: 'BUG_FIX',
                description: 'Updated Date formats to also display timezone.'
              },
              {
                type: 'REWORK',
                description: 'Changed overall styling in Popups.'
              }
            ]
          }
        ]
      }
    ]
  }),
  actions: {
    setLoading () {
      this.loading += 1
    },
    stopLoading () {
      if (this.loading === 0) {
        return
      }
      this.loading -= 1
    }
  },
  getters: {
    isLoading (state) {
      return state.loading > 0
    },
    getVersions (state) {
      return state.versions
    }
  }
})
