// This is just an example,
// so you can safely delete all default props below

import tasks from './tasks'

export default {
  failed: 'Η ενέργεια απέτυχε',
  success: 'Ενέργεια επιτυχής',
  q_mark: ';',
  errorDialogs: {
    AUTH_ERROR: 'Λάθος όνομα χρήστη ή κωδικού πρόσβασης'
  },
  warnings: {
    cannot_find_your_server: 'Δεν μπορείτε να βρείτε τον server σας;',
    no_servers_found_head: 'Δεν υπάρχουν ακόμα servers.',
    no_servers_found_desc:
      'Δεν βρέθηκαν servers, δημιουργήστε έναν για να υλοποιήσετε τις cloud υπηρεσίες μας.',
    no_disks_found_head: 'Δεν υπάρχουν ακόμα cloud disks.',
    no_disks_found_desc:
      'Οι cloud disks αυξάνουν τη χωρητικότητα του server σας. Προσθέστε έναν στον server τώρα!',
    no_private_networks_found_head: 'Δεν υπάρχουν ακόμα private networks.',
    no_public_networks_found_head: 'Δεν υπάρχουν διαθέσιμα public networks.',
    no_private_networks_found_desc:
      'Δημιουργήστε ένα private network, μέσω ενός dedicated link, χωρίς πρόσβαση στο διαδίκτυο.',
    no_ips_found_head: 'Δεν υπάρχουν ακόμα IP addresses.',
    no_ips_found_desc:
      'Μπορείτε να παραχωρήσετε παραπάνω από μία IP διευθύνσεις.',
    no_servers_for_disk_found:
      'Δεν βρέθηκαν servers. Παρακαλούμε δημιουργήστε έναν.',
    no_ssh_keys_found_head: 'Δεν υπάρχουν ακόμα SSH keys.',
    no_ssh_keys_found_desc:
      'Η χρήση των SSH keys επιτρέπει την γρηγορότερη, ασφαλέστερη και πιο απλουστευμένη διαδικασία εισόδου στον server σας.',
    rescale_restriction_create:
      'Το μέγεθος της μνήμης RAM που έχετε επιλέξει είναι μικρότερο από 4GB. Αυτό απαιτεί shutdown του server σε περίπτωση αύξησης της RAM σε περισσότερο από 4GB.',
    rescale_restriction_rescale:
      'Το τρέχον μέγεθος της μνήμης RAM είναι μικρότερο από 4GB. Απαιτείται shutdown του server πριν την αύξηση της RAM σε περισσότερο από 4GB.',
    disk_resize_irreversible:
      'Η επέκταση του δίσκου είναι μη αναστρέψιμη, δεν θα είναι δυνατή η συρρίκνωσή του στο μέλλον.',
    unmount_before_move_disk:
      'Σιγουρευτείτε ότι ο δίσκος είναι unmounted πριν προχωρήσετε σε μετακίνησή του.',
    downscale:
      'Η μείωση σε CPU/RAM στον server απαιτεί το κλείσιμο του μηχανήματος. Παρακαλούμε προχωρήστε σε shutdown πριν την μείωση των resources.',
    no_vpn_found_head: 'Δεν υπάρχουν ακόμα VPN.',
    no_vpn_found_desc: 'Δημιουργία νέου VPN',
    no_servers_for_load_balancer_found:
      'Δεν βρέθηκαν servers, δημιουργήστε έναν για την υλοποίηση του load balancer.',
    no_load_balancer_found_head: 'Δεν υπάρχουν ακόμα load balancers.',
    no_load_balancer_found_desc:
      'Δημιουργία νέου load balancer για το διαμοιρασμό της εισερχόμενης δικτυακής κίνησης.',
    no_certificates_found_head: 'Δεν υπάρχουν ακόμα certificates',
    no_certificates_found_desc: 'Δημιουργία νέου certificate',
    no_servers_for_router_found:
      'Δεν βρέθηκαν servers, δημιουργήστε έναν για να ενεργοποιήσετε στη συνέχεια routers.',
    no_router_found_head: 'Δεν υπάρχουν ακόμα routers',
    no_router_found_desc: 'Δημιουργία νέου router',
    default_firewall_policy: 'Default Policy: Allowed',
    no_network_found_head: 'Δεν βρέθηκαν ακόμα networks.',
    no_network_found_desc: 'Δημιουργία νέου network',
    no_backup_found_head: 'Δεν υπάρχουν ακόμα backups.',
    no_backup_found_desc: 'Το backup δεν έχει ενεργοποιηθεί.',
    no_firewall_found_head: 'Δεν υπάρχει ακόμα firewall.',
    no_firewall_found_desc: 'Δημιουργία νέου firewall.',
    no_snapshot_found_head: 'Δεν υπάρχουν snapshots.',
    no_snapshot_found_desc: 'Δημιουργία snapshot',
    no_imports_found_head: 'Δεν βρέθηκαν imports.',
    no_site_found_head: 'No site found yet.',
    no_site_found_desc: 'Create a site',
    no_static_route_found_head: 'Δεν υπάρχουν ακόμα static routes.',
    no_static_route_found_desc: 'Δημιουργία static route',
    no_pendings_registration_found: 'No Pending Registration Found',
    no_resolved_registration_found: 'No Resolved Registration Found',
    no_storage_accounts_found_head: 'Δεν βρέθηκαν storage accounts',
    no_storage_accounts_found_desc: 'Δημιουργία storage account',
    server_power_on_to_rescale: 'Ο server πρέπει να είναι ενεργοποιημένος',
    server_power_off_to_rescale: 'Ο server πρέπει να είναι απενεργοποιημένος',
    no_nat_found_head: 'Δεν υπάρχουν ακόμα κανόνες NAT',
    no_nat_found_desc: 'Δημιουργία κανόνα NAT',
    no_statistics_found_head: 'Δεν βρέθηκαν στατιστικά',
    no_users_found_head: 'Δεν βρέθηκαν χρήστες',
    no_users_found_desc: 'Δημιουργία χρήστη',
    no_product_pricing_found_head: 'No Product Pricing Found',
    no_pricing_override_found_head: 'No Pricing Override Found',
    no_active_order_found_head: 'Δεν βρέθηκε ενεργή παραγγελία',
    no_inactive_order_found_head: 'Δεν βρέθηκε ανενεργή παραγγελία',
    no_safe_devices_found_head: 'No Safe Devices Found',
    no_logs_found_head: 'No User Logs Found',
    no_sessions_found_head: 'No Sessions Found',
    folder_is_empty: 'Ο φάκελος είναι άδειος',
    no_vpn_concentrators_found_head: 'Δεν υπάρχουν ακόμα VPN Concentrators.',
    no_vpn_concentrators_found_desc:
      'Δεν βρέθηκαν VPN Concentrators, δημιουργήστε έναν για να χρησιμοποιήσετε τις αντίστοιχες υπηρεσίες.',
    no_profile_found_head: 'Δεν υπάρχουν ακόμα profiles.',
    no_profile_found_desc: 'Δεν βρέθηκε κάποιο profile, δημιουργήστε ένα.',
    no_user_found_head: 'Δεν υπάρχουν ακόμα χρήστες.',
    no_user_found_desc: 'Δεν βρέθηκαν χρήστες, δημιουργήστε έναν.',
    no_ip_pool_found_head: 'Δεν υπάρχουν ακόμα IP Pools.',
    no_ip_pool_found_desc: 'Δεν βρέθηκε IP Pool, δημιουργήστε ένα.',
    cannot_be_deleted: 'Δεν μπορεί να διαγραφεί.',
    no_dedicated_server_found_head: 'Δεν υπάρχουν ακόμα Dedicated Servers.',
    no_dedicated_server_found_desc:
      'Δεν βρέθηκαν Dedicated Servers, δημιουργήστε ένα.',

    no_customer_found_head: 'You are not a member of a company.',
    no_customer_found_desc:
      "Please contact your company's administrator to invite you.",
    no_cpu_tier_options: 'Δεν υπάρχουν διαθέσιμες βαθμίδες CPU για επιλογή.',
    something_went_wrong: 'Κάτι πήγε στραβά.',
    no_resources_group_found: 'Δεν βρέθηκαν Resources Groups',
    router_has_only_one_ip: 'Δεν μπορούν να αφαιρεθούν όλες οι IP διευθύνσεις από το Router.',
    no_customer_flags: 'Δεν βρέθηκαν Flags Πελάτη',
    no_feature_flags: 'Δεν βρέθηκαν Feature Flags',
    no_dns_zones_found: 'Δεν βρέθηκαν DNS Zones',
    no_dns_records_found: 'Δεν βρέθηκαν DNS Zone Records',
    changes_on_file_content: 'Υπάρχουν κάποιες αλλαγές στο περιεχόμενο του αρχείου. Πατήστε Εφαρμογή για να ενημερωθούν',
    no_records: 'Δεν υπάρχουν ακόμα εγγραφές σε αυτό το record.',
    no_notification_options: 'Δεν υπάρχουν ρυθμίσεις ειδοποιήσεων για επεξεργασία.',
    no_licenses_found_head: 'Δεν βρέθηκαν άδειες χρήσης',
    no_licenses_found_desc:
      'Δημιουργία άδειας χρήσης',
    no_data_available: 'Δεν υπάρχουν διαθέσιμα δεδομένα',
    last_task_failed: 'Η τελευταία εργασία απέτυχε',
    icon_warning: 'Αυτή η εικόνα ίσως υπάρχει και σε άλλο License. Διαγραφή ούτοε ή άλλως;',
    no_buckets_found_head: 'Δεν υπάρχουν ακόμα buckets.',
    no_buckets_found_desc: 'Τα Object Buckets είναι μια κλιμακούμενη, συμβατή με S3 λύση σχεδιασμένη για την αποθήκευση μεγάλων όγκων δεδομένων. Εξασφαλίζει εύκολη ενσωμάτωση με εφαρμογές, παρέχοντας υψηλή διαθεσιμότητα, ανθεκτικότητα και ευελιξία τόσο για δομημένα όσο και για μη δομημένα δεδομένα. Ιδανικό για αντίγραφα ασφαλείας, αποθήκευση περιεχομένου και αρχειοθέτηση δεδομένων.',
    no_datacenters_found_head: 'Δεν βρέθηκαν Datacenters',
    no_subnets_found_head: 'Δεν βρέθηκαν Subnets',
    no_feedback_issues_found: 'Δεν βρέθηκαν Feedback/Issues',
    no_help_topics_found: 'Δεν βρέθηκαν Help Topics',
    no_customers_found_head: 'Δεν βρέθηκαν Customers',
    no_resources_found_head: 'Δεν βρέθηκαν Resources',
    no_addon_services_found_head: 'Δεν βρέθηκαν Addon Services',
    no_vcenters_found: 'Δεν βρέθηκαν vCenters',
    no_datastores_found: 'Δεν βρέθηκαν Datastores',
    no_hosts_found: 'Δεν βρέθηκαν Hosts',
    no_imports_found: 'Δεν βρέθηκαν Imports',
    no_backups_found: 'Δεν βρέθηκαν Backups',
    no_disk_images_found: 'Δεν βρέθηκαν Disk Images',
    no_cpu_tiers_found: 'Δεν βρέθηκαν CPU Tiers',
    no_disk_types_found: 'Δεν βρέθηκαν Disk Types',
    validation_error: 'Λάθος είσοδος τιμής',
    billing_payment: 'ΠΡΟΣΟΧΗ: Έχετε πραγματοποιήσει μεταγενέστερη πληρωμή από την ημερομηνία ανανέωσης του υπολοίπου σας, υπάρχει ενδεχόμενο να μην έχει λογιστικοποιηθεί ακόμα.',
    boot_type_warning: 'Ο Server πρέπει να είναι απενεργοποιημένος πριν την αλλαγή του Boot Type',
    key_download_warning: 'Μπορείτε να κάνετε λήψη αυτού του κλειδιού μόνο μία φορά',
    file_management_disabled: 'Η διαχείριση αρχείων δεν είναι διαθέσιμη κατά τη διάρκεια εργασιών.'
  },
  rules: {
    confirm_does_not_match: 'Ο κωδικός επιβεβαίωσης δεν ταιριάζει',
    password_length_short:
      'Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες',
    stronger_password: 'Ο κωδικός πρόσβασης δεν είναι αρκετά ισχυρός',
    field_is_required: 'Απαιτείται',
    _is_not_valid_FQDN: 'δεν είναι έγκυρο FQDN',
    _is_valid_email: 'δεν είναι έγκυρη διεύθυνση email',
    _is_not_valid_ip_subnet: 'δεν είναι έγκυρη IP/Subnet',
    _is_not_valid_subnet: 'δεν είναι έγκυρο Subnet',
    _is_not_valid_subnets: 'δεν είναι έγκυρα Subnets',
    _is_not_valid_ipv4: 'δεν είναι έγκυρη διεύθυνση IPv4',
    _is_not_valid_ipv6: 'δεν είναι έγκυρη διεύθυνση IPv6',
    _is_not_valid_domain: 'δεν είναι έγκυρo domain',
    _is_not_valid_fqdn: 'δεν είναι έγκυρο FQDN',
    _is_not_valid_port: 'δεν είναι έγκυρο port',
    _are_not_valid_ports: 'δεν είναι έγκυρα ports',
    _is_not_valid_url: 'δεν είναι έγκυρο URL',
    _is_required: 'απαιτείται',
    password_at_least_twenty_chars:
      'Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 20 χαρακτήρες',
    not_valid_subnet: 'δεν είναι valid subnet',
    overlapping_ip: 'IP is overlapping',
    next_hop_range_uplink: "Next hop can't be in the range of network uplink",
    next_hop_same_internal: "Next hop can't be the same as an internal network",
    next_hop_same_internal_start:
      "Next hop can't be the same as an internal network first ip",
    next_hop_same_internal_end:
      "Next hop can't be the same as an internal network last ip",
    next_hop_range_internal:
      'Next hop must be in the same range as an internal network',
    _is_valid_contact_number: 'δεν είναι έγκυρος αριθμός επικοινωνίας',
    invalid_confirm_pass: 'Ο κωδικός επιβεβαίωσης δεν ταιριάζει',
    wrong_iso_format: 'Πρέπει να γίνει mount το ISO σε Universal format ή ',
    not_match_password: 'Οι κωδικοί πρέπει να ταιριάζουν',
    password_must_be_stronger: 'Ο κωδικός πρέπει να είναι ισχυρότερος',
    _is_not_valid_ipv4_or_cidr: 'δεν είναι έγκυρη διεύθυνση IPv4 ή IP/Subnet',
    ip_must_be_in_the_same_network: 'πρέπει να είναι στο ίδιο δίκτυο',
    _is_not_valid_subnet_mask: 'δεν είναι έγκυρη διεύθυνση subnet mask',
    end_address_out_of_range: 'End Address is out of range',
    _is_reserved_address: 'είναι reserved διεύθυνση',
    start_address_out_of_range: 'Start address is overlapping end address',
    ip_pool_overllaping_network_card: 'IP Pool is overlapping network card',
    ip_pool_overllaping_pool: 'IP Pool is overlapping existing pool',
    network_card_overllaping_pool: 'Network card is overlapping existing pool',
    cannot_be_same_as_tunnel:
      'Το next hop δεν μπορεί να είναι ίδιο με την IP του tunnel.',
    network_overlaps_tunnel: 'Network card is overlapping tunnel',
    tunnel_overalps_network: 'Tunnel is overlapping network card',
    same_port: 'Αυτό το port χρησιμοποιείται ήδη απο αυτό τον Router',
    _not_valid_mask: 'δεν είναι έγκυρη μάσκα δικτύου.',
    invalid_network_values: 'Μη έγκυρες τιμές δικτύου.',
    _is_not_valid_type:
      "δεν είναι έγκυρος τύπος. Παρακαλούμε χρησιμοποιείστε μόνο 'A-Z', '0-9' και '_'",
    same_internal_uplink_network:
      'Δεν μπορείτε να χρησιμοποιήσετε το ίδιο δίκτυο στα πεδία Internal Network και Uplink Network.',
    same_vcenter_uplink: 'Το Stand By uplink δεν μπορεί να είναι το ίδιο με το Active uplink.',
    caa_type_flag_validation: 'Η τιμή του flag πρέπει να είναι μεταξύ 0 και 255',
    caa_type_value_double_quotes: 'Η τιμή πρέπει να βρίσκεται ανάμεσα σε διπλά εισαγωγικά',
    caa_type_value_slash: 'Τα εσωτερικά διπλά εισαγωγικά πρέπει να διαφεύγουν με \\" ακολουθία χαρακτήρων'
  },
  menu: {
    home: 'Home',
    CLOUD: 'CLOUD',
    servers: 'Cloud Servers',
    disks: 'Cloud Disks',
    ssh_keys: 'SSH Keys',
    ip_addresses: 'IP Addresses',
    private_networks: 'Private Networks',
    MANAGEMENT: 'MANAGEMENT',
    datacenters: 'Datacenters',
    subnets: 'Subnets',
    customers: 'Customers',
    resource_pools: 'Resource Pools',
    volumes: 'Volumes',
    SUPPORT: 'SUPPORT',
    tickets: 'Tickets',
    tasks: 'Tasks',
    vpn: 'VPN',
    feedback_issues: 'Feedback/Issues',
    load_balancer: 'Load Balancer',
    certificates: 'Certificates',
    routers: 'Routers',
    imports: 'Imports',
    customer_overrides: 'Customer Overrides',
    service_overrides: 'Service Overrides',
    products: 'Products',
    status: 'Status',
    registrations: 'Registrations',
    backups: 'Backups',
    storage_accounts: 'Storage Accounts',
    object_buckets: 'Object Buckets',
    resource_groups: 'Resources Groups',
    product_categories: 'Product Categories',
    users: 'Users',
    vpn_concentrators: 'VPN Concentrators',
    resources_management: 'Resources Management',
    cpu_tiers: 'CPU Tiers',
    hosts: 'Hosts',
    datastores: 'Datastores',
    disk_types: 'Τύποι Δίσκων',
    settings: 'Ρυθμίσεις'
  },
  tabs: {
    dashboard: 'Dashboard',
    servers: 'Cloud Servers',
    create_server: 'Δημιουργία Server',
    disks: 'Cloud Disks',
    create_disk: 'Δημιουργία Disk',
    routers: 'Routers',
    create_router: 'Δημιουργία Router',
    vpn_concentrators: 'VPN Concentrators',
    create_vpn_concentrator: 'Δημιουργία VPN Concentrator',
    dns_zones: 'DNS Zones',
    create_dns_zone: 'Δημιουργία DNS Zone',
    ssh_keys: 'SSH Keys',
    ip_addresses: 'IP Adresses',
    add_ip: 'Προσθήκη IP',
    private_networks: 'Private Networks',
    create_private_network: 'Δημιουργία Private Network',
    storage_accounts: 'Storage Accounts',
    object_buckets: 'Object Buckets',
    create_object_bucket: 'Δημιουργία Object Bucket',
    create_storage_account: 'Δημιουργία Storage Account',
    tasks: 'Tasks',
    datacenters: 'Datacenters',
    create_datacenter: 'Δημιουργία Datacenter',
    subnets: 'Subnets',
    create_subnet: 'Δημιουργία Subnet',
    products: 'Products',
    product_categories: 'Product Categories',
    service_overrides: 'Service Overrides',
    customers: 'Customers',
    users: 'Users',
    feedback_issues: 'Feedback/Issues',
    help_topics: 'Help Topics',
    vcenters: 'vCenters',
    create_vcenter: 'Δημιουργία vCenter',
    resource_pools: 'Resource Pools',
    hosts: 'Hosts',
    datastores: 'Datastores',
    imports: 'Imports',
    backups: 'Backups',
    backup_servers: 'Backup Servers',
    disk_image_manager: 'Disk Image Manager',
    cpu_tiers: 'CPU Tiers',
    disk_types: 'Τύποι Δίσκων',
    registrations: 'Registrations',
    resources_groups: 'Resources Groups',
    resources_management: 'Resources Management',
    licenses: 'Licenses',
    addon_services: 'Addon Services',
    dns_clusters: 'DNS Clusters',
    settings: 'Ρυθμίσεις',
    changelog: 'Changelog',
    billing: 'Billing',
    ip_bandwidth: 'IP Bandwidth',
    login: 'Σύνδεση',
    register: 'Εγγραφή',
    reset_password: 'Επαναφορά κωδικού',
    forgot_password: 'Ξεχάσατε τον κωδικό',
    demo_application: 'Demo application',
    mobile_verification_send: 'Επαλήθευση μέσω κινητού',
    mobile_verification_validation: 'Επικύρωση επαλήθευσης μέσω κινητού',
    two_factor_authentication: 'Έλεγχος ταυτότητας δύο παραγόντων',
    email_validation: 'Επικύρωση email',
    two_factor_enable: 'Ενεργοποίηση ταυτότητας δύο παραγόντων'
  },
  words: {
    options: 'Επιλογές',
    open: 'Άνοιγμα',
    id: 'ID',
    PRIVATE: 'PRIVATE',
    PUBLIC: 'PUBLIC',
    account: 'Account',
    accounts: 'Accounts',
    action: 'Ενέργεια',
    active: 'Ενεργός',
    deactivated: 'Deactivated',
    expired: 'Έληξε',
    active_tasks: 'Active Tasks',
    address: 'Διεύθυνση',
    advanced_settings: 'Σύνθετες ρυθμίσεις',
    algorithm: 'Algorithm',
    algorithms: 'Algorithms',
    allow: 'Allow',
    any: 'Any',
    apply: 'Εφαρμογή',
    attached_to: 'Attached to',
    backup: 'Backup',
    backups: 'Backups',
    brand: 'Brand',
    cancel: 'Άκυρο',
    capacity: 'Χωρητικότητα',
    certificate: 'Certificate',
    certificate_contents: 'Certificate Contents',
    certificates: 'Certificates',
    code: 'Κωδικός',
    clear: 'Εκκαθάριση',
    close: 'Κλείσιμο',
    cloud: 'Cloud',
    cluster: 'Cluster',
    company: 'Εταιρία',
    confirmation: 'Επιβεβαίωση',
    connect: 'Σύνδεση',
    console: 'Console',
    create: 'Δημιουργία',
    created_at: 'Δημιουργήθηκε',
    created_at_utc: 'Δημιουργήθηκε (UTC)',
    currency: '€',
    customer: 'Πελάτης',
    customers: 'Customers',
    dashboard: 'Dashboard',
    data: 'Δεδομένα',
    datacenter: 'Datacenter',
    datacenters: 'Datacenters',
    datastores: 'Datastores',
    default: 'Προεπιλογή',
    delete: 'Διαγραφή',
    deny: 'Deny',
    description: 'Περιγραφή',
    destination_port: 'Destination Port',
    disconnect: 'Αποσύνδεση',
    disk: 'Δίσκος',
    disk_size: 'Μέγεθος δίσκου',
    disks: 'Δίσκοι',
    domain: 'Domain',
    edit: 'Επεξεργασία',
    email: 'Email',
    end_port: 'End Port',
    extend: 'Επέκταση',
    feedback: 'Σχόλια',
    firewall_rules: 'Κανόνες Firewall',
    folder: 'Φάκελος',
    free_space: 'Ελεύθερος Χώρος',
    fullscreen: 'Πλήρης οθόνη',
    gateway: 'Gateway',
    graphs: 'Γραφήματα',
    healthCheck: 'Έλεγχος Υγείας',
    health_check: 'Έλεγχος Υγείας',
    help: 'Βοήθεια',
    history: 'Ιστορικό',
    host: 'Host',
    hosts: 'Hosts',
    hour_short: 'ω',
    information: 'Πληροφορίες',
    internal_ip_config: 'Internal IP Config',
    interval_time: 'Interval Time',
    internal_name: 'Internal Name',
    ip: 'IP',
    ip_firewall_rules: 'IP Firewall Rules',
    ip_range: 'IP Range',
    ips: 'IPs',
    label: 'Ετικέτα',
    last: 'Τελευταία',
    load_balancer: 'Load Balancer',
    loading: 'Φόρτωση',
    location: 'Τοποθεσία',
    login: 'Είσοδος',
    back_to_login: 'Επιστροφή στην Είσοδο',
    logout: 'Έξοδος',
    memory: 'Μνήμη',
    message: 'Μήνυμα',
    meta: 'Metadata',
    month_short: 'μη',
    move: 'Μετακίνηση',
    name: 'Όνομα',
    netmask: 'Netmask',
    network: 'Δίκτυο',
    network_cards: 'Κάρτες Δικτύου',
    networks: 'Δίκτυα',
    no: 'Όχι',
    notes: 'Σημειώσεις',
    old: 'Παλιό',
    operating: 'Λειτουργικό',
    order: 'Παραγγελία',
    overview: 'Επισκόπηση',
    password: 'Κωδικός Πρόσβασης',
    password_confirm: 'Επιβεβαίωση Κωδικού Πρόσβασης',
    paste: 'Επικόλληση',
    path: 'Διαδρομή',
    port_group: 'Port Group',
    ports: 'Ports',
    primary_dns: 'Primary DNS',
    private: 'Ιδιωτικό',
    private_key: 'Private Key',
    private_network: 'Private Network',
    private_network_name: 'Όνομα Private Network',
    protocol: 'Πρωτόκολλο',
    provisioned: 'Provisioned',
    public: 'Δημόσιο',
    public_key: 'Public Key',
    random: 'Τυχαίο',
    regenerate: 'Επανέκδοση',
    release: 'Αποδέσμευση',
    remove: 'Αφαίρεση',
    report: 'Δήλωση προβλήματος',
    rescale: 'Αυξομείωση',
    server_rescale: 'Προσθαφαίρεση resources',
    reset: 'Επαναφορά',
    resource_pools: 'Resource Pools',
    response_timeout: 'Response Timeout',
    restore: 'Επαναφορά',
    result: 'Αποτέλεσμα',
    retries: 'Προσπάθειες',
    retype_password: 'Επαναπληκτρολόγηση Κωδικού Πρόσβασης',
    reverse_dns: 'Reverse DNS',
    reverse_record: 'Reverse Record',
    role: 'Ρόλος',
    global_role: 'Global Ρόλος',
    customer_role: 'Ρόλος Πελάτη',
    router: 'Router',
    routes: 'Routes',
    save: 'Αποθήκευση',
    screenshot: 'Screenshot',
    search: 'Αναζήτηση',
    secondary_dns: 'Secondary DNS',
    select_network: 'Επιλογή Δικτύου',
    semi_managed: 'Semi-Managed',
    send: 'Αποστολή',
    server: 'Server',
    server_cost: 'Κόστος Server',
    servers: 'Servers',
    servers_info: 'Πληροφορίες Servers',
    service: 'Υπηρεσία',
    snapshot: 'Snapshot',
    snapshots: 'Snapshots',
    source_list: 'Source List',
    source_port: 'Source Port',
    start_port: 'Start Port',
    status: 'Κατάσταση',
    submit: 'Εφαρμογή',
    subnets: 'Subnets',
    task: 'Διεργασία',
    tasks: 'Διεργασίες',
    type: 'Τύπος',
    uncommitted: 'Uncommitted',
    undo: 'Αναίρεση',
    update: 'Ενημέρωση',
    updated_at: 'Ενημερώθηκε',
    updated_at_utc: 'Ενημερώθηκε (UTC)',
    user: 'Χρήστης',
    username: 'Όνομα χρήστη',
    users: 'Χρήστες',
    users_email: 'Email χρήστη',
    vat: 'ΑΦΜ',
    vcpu: 'vCPUs',
    version: 'Έκδοση',
    view: 'Προβολή',
    vpn: 'VPN',
    vpn_user: 'Χρήστες VPN',
    yes: 'Ναι',
    changelog: 'Changelog',
    enable_backup: 'Ενεργοποίηση Daily Backup',
    no_backups: 'Δεν έχει δημιουργηθεί ακόμα backup.',
    private_network_overview: 'Επισκόπηση Private Network',
    create_snapshot: 'Δημιουργία Snapshot',
    issued_to: 'Καταχωρήθηκε στο',
    issued_by: 'Καταχωρήθηκε από',
    expires_on: 'Λήγει στις',
    member: 'Μέλος',
    members: 'Μέλη',
    port: 'Port',
    condition: 'Συνθήκη',
    server_name: 'Όνομα Server',
    vcenter_name: 'Όνομα vCenter',
    vm_internal_name: 'Όνομα VM Internal',
    date: 'Ημερομηνία',
    import: 'Import',
    sync: 'Sync',
    field: 'Απέτυχε',
    reinstall: 'Επανεγκατάσταση',
    download_audit: 'Λήψη Audit',
    kind_of_ip: 'Είδος IP',
    ipsec_sites: 'IPSEC Sites',
    local: 'Τοπικό',
    remote: 'Απομακρυσμένο',
    ike_version: 'IKE Version',
    local_endpoint: 'Τοπικό Endpoint',
    remote_endpoint: 'Απομακρυσμένο Endpoint',
    digest_algorithm: 'Digest Algorithm',
    encryption_algorithm: 'Encryption Algorithm',
    preshared_key: 'Pre-Shared Key',
    hellman_group: 'Hellman Group',
    lifetime: 'Lifetime',
    global: 'Global',
    phase_one: 'Phase 1',
    phase_two: 'Phase 2',
    authentication: 'Authentication',
    encryption: 'Encryption',
    perfect_forward_secrecy: 'Perfect Forward Secrecy (PFS)',
    local_subnets: 'Τοπικά Subnets',
    remote_subnets: 'Απομακρυσμένα Subnets',
    ipsec_site: 'IPSEC Site',
    ip_type: 'Τύπος IP',
    size: 'Μέγεθος',
    os: 'Λειτουργικό σύστημα',
    cpu: 'CPU',
    backup_specification: 'Προδιαγραφές Backup',
    monitoring_type: 'Monitor Protocol Type',
    health_check_status: 'Έλεγχος κατάστασης',
    x_forward_for: 'X Forward For',
    monitor_port: 'Monitor Port',
    upload: 'Μεταφόρτωση',
    disable_backup: 'Απενεργοποίηση Backup',
    price: 'Τιμή',
    gui_element: 'GUI Element',
    gui_type: 'GUI Type',
    products: 'Products',
    product: 'Product',
    customer_overrides: 'Customer Overrides',
    customer_id: 'Customer ID',
    customer_name: 'Customer Name',
    customer_product: 'Customer Product',
    service_overrides: 'Service Overrides',
    customer_override: 'Customer Override',
    hourly: 'Ωριαίο',
    monthly: 'Μηνιαίο',
    special_rates: 'Special Rates',
    service_type: 'Service Type',
    service_name: 'Service Name',
    product_name: 'Product Name',
    service_override: 'Service Override',
    static_routing: 'Static Route',
    next_hop: 'Next Hop',
    source_port_list: 'Source Port List',
    destination_port_list: 'Destination Port List',
    destination_list: 'Destination List',
    accept: 'Αποδοχή',
    mount: 'Mount',
    unmount: 'Unmount',
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    companyName: 'Επωνυμία εταιρίας',
    contactNumber: 'Τηλέφωνο επικοινωνίας',
    full_name: 'Ονοματεπώνυμο',
    validation_number: 'Αριθμός επικύρωσης',
    two_factor_auth: 'Two Factor Authentication',
    enable: 'Ενεργοποίηση',
    disable: 'Απενεργοποίηση',
    change_password: 'Αλλαγή κωδικού πρόσβασης',
    profile: 'Προφίλ',
    old_password: 'Παλιός κωδικός πρόσβασης',
    new_password: 'Νέος κωδικός πρόσβασης',
    new_password_confirm: 'Επιβεβαίωση νέου κωδικού πρόσβασης',
    update_password: 'Ενημέρωση κωδικού πρόσβασης',
    verification_code: '2FA Verification Code',
    verify_code: 'Επιβεβαίωση κωδικού',
    logout_all_sessions: 'Έξοδος από όλα τα sessions',
    registrations: 'Εγγραφές',
    registration_action: 'Ενέργεια εγγραφής',
    pending_registrations: 'Εκκρεμείς εγγραφές',
    resolved_registrations: 'Ολοκληρωμένες εγγραφές',
    submit_date: 'Ημερομηνία καταχώρησης',
    resolved_date: 'Ημερομηνία επίλησης',
    country: 'Χώρα',
    mobile_number: 'Κινητό τηλέφωνο',
    continue: 'Συνέχεια',
    resend_code: 'Επαναποστολή κωδικού',
    newsletter_email: 'Newsletter Email',
    newsletter_sms: 'Newsletter Sms',
    welcome: 'Καλώς ορίσατε,',
    import_differences: 'Import Differences',
    current: 'Τρέχον',
    real: 'Πραγματικό',
    differences: 'Αλλαγές',
    public_cloud: 'Public Cloud',
    private_cloud: 'Private Cloud',
    routers: 'Routers',
    resources: 'Resources',
    site_enabled: 'Ενεργοποιημένο site',
    site_disabled: 'Απενεργοποιημένο site',
    ip_address: 'IP Address',
    reset_password: 'Επαναφορά κωδικού πρόσβασης',
    file_manager: 'Διαχειριστής αρχείων',
    file_browser: 'Browser Αρχείων',
    create_folder: 'Δημιουργία φακέλου',
    delete_folder: 'Διαγραφή φακέλου',
    create_file: 'Δημιουργία αρχείου',
    delete_file: 'Διαγραφή αρχείου',
    upload_file: 'Μεταφόρτωση αρχείου',
    download_file: 'Λήψη αρχείου',
    nat: 'NAT',
    nat_rules: 'Κανόνες NAT',
    applied_on: 'Applied On',
    nat_type: 'NAT type',
    source_ip: 'Source IP/Range',
    original_destination_ip: 'Original Destination IP/Range',
    original_destination_port: 'Original Destination Port/Range',
    translated_ip: 'Translated IP/Range',
    translated_port: 'Translated Port/Range',
    original_source_ip: 'Original Source IP/Range',
    original_source_port: 'Original Source Port/Range',
    destination_ip: 'Destination IP/Range',
    translated: 'Translated',
    storage_accounts: 'Storage Accounts',
    storage_accounts_size: 'Μέγεθος Storage Accounts',
    rename_folder: 'Μετονομασία φακέλου',
    rename_file: 'Μετονομασία αρχείου',
    refresh: 'Ανανέωση',
    rename: 'Μετονομασία',
    cut: 'Αποκοπή',
    paste_here: 'Επικόλληση εδώ',
    cancel_cut: 'Ακύρωση αποκοπής',
    last_modified: 'Τελευταία τροποποίηση',
    permissions: 'Δικαιώματα',
    up: 'Πάνω',
    down: 'Κάτω',
    time_frame: 'Χρονικό πλαίσιο',
    live_server: 'Αυτόματη ανανέωση',
    live: 'Ενεργό',
    offline: 'Ανενεργό',
    resource_group: 'Resource Group',
    resource_group_name: 'Resource Group Name',
    value: 'Τιμή',
    rules: 'Κανόνες',
    billing_period_policy: 'Billing Period Policy',
    billing_type: 'Billing Type',
    resource_name: 'Resource Name',
    product_category: 'Product Category',
    division: 'Division',
    orders: 'Orders',
    product_pricing: 'Product Pricing',
    edit_resources: 'Επεξεργασία Resources',
    update_resources: 'Ενημεέρωση Resources',
    pricing_overrides: 'Pricing Overrides',
    period_start: 'Έναρξη περιόδου',
    period_end: 'Τερματισμός περιόδου',
    pricing_override: 'Pricing Override',
    network_type: 'Τύπος δικτύου',
    internal_network: 'Εσωτερικό δίκτυο',
    statistics: 'Στατιστικά',
    local_subnet: 'Τοπικό Subnet',
    peer_subnet: 'Peer Subnet',
    tunnel_state: 'Κατάσταση Tunnel',
    tunnel_status: 'Κατάσταση Tunnel',
    statistic_details: 'Λεπτομέρειες στατιστικών',
    serial: 'Serial',
    billing_period: 'Περίοδος τιμολόγησης',
    order_details: 'Λεπτομέρειες παραγγελίας',
    task_details: 'Λεπτομέρειες διεργασίας',
    details: 'Λεπτομέρειες',
    quantity: 'Ποσότητα',
    portgroup_name: 'Portgroup Name',
    mac_address: 'MAC Address',
    is_connected: 'Είναι συνδεδεμένο',
    admin_info: 'Πληροφορίες Admin',
    disk_info: 'Πληροφορίες δίσκου',
    vmware_info: 'Πληροφορίες VMware',
    uplink_network: 'Uplink Network',
    network_card: 'Κάρτα δικτύου',
    seconday_ips: 'Seconday IPs',
    history_tasks: 'Ιστορικό διεργασιών',
    general: 'Γενικά',
    sessions: 'Sessions',
    last_activity: 'Τελευταία δραστηριότητα',
    safe_devices: 'Ασφαλείς συσκευές',
    trust_device: 'Trusted συσκευές',
    platform: 'Platform',
    platform_version: 'Platform Version',
    device_type: 'Device Type',
    browser: 'Browser',
    browser_version: 'Browser Version',
    saved_date: 'Ημερομηνία αποθήκευσης',
    notifications: 'Ειδοποιήσεις',
    telephone: 'Τηλέφωνο',
    save_informations: 'Πληροφορίες αποθήκευσης',
    verified_email: 'Επιβεβαιωμένο email',
    not_verified_email: 'Μη επιβεβαιωμένο email',
    verified_mobile: 'Επιβεβαιωμένο κινητό',
    not_verified_mobile: 'Μη επιβεβαιωμένο κινητό',
    ip_list: 'Λίστα IPs',
    save_ips: 'Αποθηκευμένες IPs',
    traffic: 'Traffic',
    outgoing_traffic: 'Εξερχόμενο traffic',
    incoming_traffic: 'Εισερχόμενο traffic',
    security: 'Ασφάλεια',
    user_id: 'User ID',
    user_logs: 'User Logs',
    bytes_in: 'Bytes In',
    bytes_out: 'Bytes Out',
    cur_sessions: 'Τρέχοντα sessions',
    max_sessions: 'Μέγιστα sessions',
    total_sessions: 'Συνολικά sessions',
    enable_rescue_mode: 'Ενεργοποίηση Rescue Mode',
    disable_rescue_mode: 'Απενεργοποίηση Rescue Mode',
    cpu_per_socket: 'CPU ανά socket',
    vpn_concentrator: 'VPN Concentrator',
    local_address: 'Local Address',
    ip_pools: 'IP Pools',
    profiles: 'Profiles',
    dns: 'DNS',
    start_address: 'Start Address',
    end_address: 'End Address',
    subnet_mask: 'Subnet Mask',
    remote_id: 'Απομακρυσμένο Id',
    resolved_by: 'Resolved By',
    ip_pool: 'IP Pool',
    route_list: 'Route List',
    dns_list: 'DNS List',
    download_certificate: 'Λήψη Certificate',
    persistence_mode: 'Persistence Mode',
    cookie_name: 'Cookie Name',
    cookie_mode: 'Cookie Mode',
    expires_in: 'Λήγει στις',
    authenticator: 'Authenticator',
    authenticator_enabled: 'Authenticator Ενεργοποιημένος',
    download_authenticator: 'Λήψη {brandname} Authenticator',
    connected_from: 'Συνδεδεμένο από',
    uptime: 'Uptime',
    migrate: 'Migrate',
    datastore: 'Datastore',
    admin_options: 'Επιλογές Admin',
    enable_strict: 'Enable Strict',
    enable_loose: 'Enable Loose',
    instance: 'Instance',
    available_ips: 'Διαθέσιμες IPs',
    unassigned_ip: 'Unassigned IP address',
    cidr: 'CIDR',
    available: 'Διαθέσιμο',
    reserved: 'Reserved',
    storage_account: 'Storage Account',
    cloud_disk: 'Cloud Disk',
    vm_name: 'Όνομα VM',
    vpn_concentrator_user: 'Χρήστης VPN Concentrator',
    regenerate_config: ' Επανέκδοση VPN Config Χρήστη',
    config_updated: 'Το Config Επανεκδοθήκε',
    vcenter: 'vCenter',
    vcenters: 'vCenters',
    backup_policy: 'Backup Policy',
    amount: 'Ποσό',
    balance: 'Υπόλοιπο',
    expired_balance: 'Ληξιπρόθεσμο Υπόλοιπο',
    billing: 'Χρέωση',
    branch: 'Branch',
    companyVAT: 'ΑΦΜ εταιρίας',
    completed_at: 'Ολοκληρώθηκε στις',
    destination_ports: 'Destination Ports',
    disabled: 'Απενεργοποιημένο',
    enabled: 'Ενεργοποιημένο',
    failed_at: 'Εμφάνισε πρόβλημα στις',
    invoice: 'Τιμολόγιο',
    invoice_referennce: 'Αναφορά τιμολογίου',
    invoice_type: 'Τύπος τιμολογίου',
    invoices: 'Τιμολόγια',
    irs: 'IRS',
    language: 'Γλώσσα',
    mode: 'Ρύθμιση',
    net_value: 'Net Value',
    payment_with: 'Πληρωμή με',
    payment_methods: 'Τρόποι πληρωμής',
    reference: 'Αναφορά',
    router_cost: 'Κόστος router',
    server_status: 'Κατάσταση server',
    source_ports: 'Source Ports',
    tax_value: 'Αξία φόρου',
    total_value: 'Συνολική αξία',
    transactions: 'Λογιστική Καρτέλα',
    verify: 'Επιβεβαίωση',
    vpnConcentrator_cost: 'Κόστος VPN Concentrator',
    warning: 'Προσοχή',
    hours: 'Ώρες',
    _hours: 'ώρες',
    failure_cause: 'Αιτία Αποτυχίας',
    tunnel_cidr: 'Tunnel CIDR',
    dynamic_mode: 'Dynamic Mode',
    psk: 'Pre-Shared Key',
    update_psk: 'Ενημέρωση Pre-Shared Key',
    delete_psk: 'Delete Pre-Shared Key',
    dynamic_mode_preshared_key: 'Dynamic Mode Pre-Shared Key',
    installed: 'Εγκατεστημένο',
    pending_apply_activate: 'Εκκρεμεί εφαρμογή (Ενεργοποίηση)',
    pending_apply_delete: 'Εκκρεμεί εφαρμογή (Διαγραφή)',
    failed: 'Απέτυχε',
    pylon_product: 'Pylon Product',
    pylon_customer: 'Pylon Customer',
    sdn_type: 'SDN Type',
    vdn_scope: 'VDN Scope',
    nsx_user: 'NSX Username',
    nsx_password: 'NSX Password',
    get_scopes: 'Get Scopes',
    lights_on: 'Ανοιχτά φώτα',
    lights_off: 'Κλειστά φώτα',
    instance_name: 'Instance Name',
    instance_type: 'Instance Type',
    resource: 'Resource',
    licenses: 'Licenses',
    select: 'Επιλογή',
    since: 'Από',
    license_quantity: 'License Quantity',
    system_operations: 'System Operations',
    system_operation_type: 'System Operations Type',
    dispatch: 'Dispatch',
    license: 'License',
    redirect_url: 'URL Ανακατεύθυνσης',
    redirect_mode: 'Redirect Mode',
    activate: 'Ενεργοποίηση',
    deactivate: 'Απενεργοποίηση',
    instances: 'Instances',
    connected: 'Συνδεδεμένο',
    icon: 'Εικονίδιο',
    internal_note: 'Internal Note',
    dedicated_server: 'Dedicated Server',
    upgrade: 'Αναβάθμιση',
    add: 'Προσθήκη',
    approve: 'Approve',
    duplicate: 'Duplicate',
    software: 'Software',
    hardware: 'Hardware',
    hostname: 'Hostname',
    area: 'Τοποθεσία',
    zip: 'ΤΚ',
    contact_number: 'Αριθμός επικοινωνίας',
    timezone: 'Timezone',
    billing_overview: 'Οικονομικά Στοιχεία',
    password_confirmation: 'Επιβεβαίωση Κωδικού',
    repository_name: 'Όνομα Repository',
    notification_email: 'Email Ειδοποιήσεων',
    script_location: 'Τοποθεσία Scripts',
    default_gateway: 'Προεπιλεγμένη Πύλη',
    manage_customer: 'Διαχείριση Πελάτη',
    parent_company: 'Parent Company',
    customer_flags: 'Flags Πελάτη',
    feature_flags: 'Feature Flags',
    switch: 'Switch',
    dns_zone: 'DNS Zone',
    dns_zones: 'DNS Zones',
    dns_record: 'DNS Record',
    dns_zone_records: 'Zone Records',
    ttl: 'TTL',
    priority: 'Priority',
    weight: 'Weight',
    flag: 'Flag',
    tag: 'Tag',
    content: 'Content',
    records: 'Records',
    back: 'Πίσω',
    storage_policies: 'Storage Policies',
    dns_cluster: 'DNS Cluster',
    name_server: 'Name Server',
    name_servers: 'Name Servers',
    base_path: 'Base Path',
    key: 'Key',
    availability: 'Διαθεσιμότητα',
    backing: 'Backing',
    credentials: 'Διαπιστευτήρια',
    help_center: 'Κέντρο Βοήθειας',
    pfs_group: 'PFS Group',
    dpd_settings: 'Ρυθμίσεις DPD',
    download: 'Λήψη',
    object_bucket: 'Object Bucket',
    privacy: 'Ιδιωτικότητα',
    keys: 'Κλειδιά',
    account_number: 'Λογαριασμός',
    payments: 'Πληρωμές',
    credit: 'Πίστωση',
    debit: 'Χρέωση',
    payment_status: 'Κατάσταση Πληρωμής',
    paid: 'Πληρωμένο',
    partly_paid: 'Μερικώς Πληρωμένο',
    unpaid: 'Απλήρωτο',
    driver: 'Driver',
    database: 'Database',
    schema: 'Schema',
    boot_type: 'Boot Type',
    more: 'ακόμη',
    backup_server: 'Backup Server',
    invited: 'Προσκεκλημένος'
  },

  texts: {
    lorem: 'Λόρεμ ίψουμ?',
    disk_options: 'Επιλογές Δίσκων',
    full_vm_list_option:
      'Με την επιλογή Full VM List Backup σημαίνει ότι το backup συμπεριλαμβάνει όλους τους δίσκους',
    create_disk: 'Δημιουργία Cloud Disk',
    delete_disk: 'Διαγραφή δίσκου',
    moving_disk: 'Μετακίνηση δίσκου',
    to_server: 'στον server: ',
    delete_disk_confirm: 'Επιθυμείτε να διαγραφεί ο δίσκος',
    _for_server: 'στον server',
    disk_overview: 'Επισκόπηση δίσκων',
    name_is_missing: 'Λείπει το όνομα',
    server_is_missing: 'Λείπει ο server',
    servers_are_missing: 'Λείπουν οι servers',
    service_config_is_missing: 'Λείπει το service config',
    gb_disk: 'GB Disk',
    disk_name_is_invalid: 'Μη έγκυρο όνομα δίσκου',
    server_is_not_selected: 'Δεν έχει επιλεγεί ο server',
    disk_created: 'Εκκίνηση Δημιουργίας Δίσκου',
    show_primary_disks: 'Εμφάνιση primary δίσκου',
    add_disk: 'Προσθήκη δίσκου',

    add_ip: 'Προσθήκη IP',
    ip_overview: 'Επισκόπηση IP',
    id_address_is_missing: 'Λείπει η διεύθυνση IP',
    id_address_added: 'Προστέθηκε IP διεύθυνση',
    ip_address: 'Διεύθυνση IP',
    ip_addresses: 'Διευθύνσεις IP',
    id_address_fatal_error_message: 'Η IP απενεργοποιήθηκε λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    set_reverse_record: 'Εφαρμογή Reverse Record',
    release_ip: 'Αποδέσμευση IP',
    release_ip_confirm: 'Θέλετε να αποδεσμεύσετε την IP',
    ip_released: 'Η διεύθυνση IP απελευθερώθηκε',
    _for_ip: 'για την IP',
    field_cannot_be_empty: 'Το πεδίο δεν μπορεί να είναι κενό',
    reverse_record_updated: 'Ενημερώθηκε το Reverse Record',

    add_network: 'Δημιουργία Δικτύου',
    add_network_card: 'Προσθήκη Κάρτας Δικτύου',
    disconnect_network_card: 'Αποσύνδεση Κάρτας Δικτύου',
    connect_network_card: 'Σύνδεση Κάρτας Δικτύου',
    network_card_disconnected: 'Η Κάρτα Δικτύου Αποσυνδέθηκε',
    network_card_connected: 'Η Κάρτα Δικτύου Αποσυνδέθηκε',
    from_network: 'από το Δίκτυο',
    to_network: 'στο Δίκτυο',

    private_networks: 'Private Networks',
    network_fatal_error_message: 'Το δίκτυο απενεργοποιήθηκε λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    delete_network: 'Διαγραφή Δικτύου',
    delete_network_confirm: 'Θέλετε να διαγραφεί το δίκτυο',
    delete_network_card: 'Διαγραφή Κάρτας Δικτύου',
    delete_network_card_confirm: 'Θέλετε να διαγράψετε την κάρτα δικτύου',
    network_deleted: 'Το δίκτυο διαγράφτηκε',
    network_card_deleted: 'Η Κάρτα Δικτύου διαγράφτηκε',

    add_private_network: 'Προσθήκη Private Network',
    location_is_not_set: 'Δεν έχει οριστεί τοποθεσία',
    location_is_missing: 'Λείπει η τοποθεσία',
    network_created: 'Εκκίνηση Δημιουργίας Δικτύου',

    add_server: 'Προσθήκη Server',
    create_server: 'Δημιουργία Cloud Server',
    server_overview: 'Επισκόπηση Server',
    delete_server: 'Διαγραφή Server',
    delete_server_confirm: 'Θέλετε να διαγράψετε τον server',
    clone_server: 'Clone Server',
    server_deleted: 'Ο server διαγράφτηκε',
    server_created: 'Εκκίνηση Δημιουργίας Server',
    vnc_for_server: 'VNC για τον Server',
    confirm_server_name: 'Παρακαλούμε επιβεβαιώστε το όνομα του server',
    in_order_to_delete_id: 'για να τον διαγράψετε',
    server_deletion_dispatched: 'Εκκίνηση διαγραφής server',
    verification_name_does_not_match_server:
      'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του server',
    server_fatal_error_message: 'Η διαχείριση του Server είναι κλειδωμένη λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    server_disabled_error_message: 'Server προσωρινά μη διαθέσιμος. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    verification_name_does_not_match_dns_zone:
      'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του DNS Zone',
    disk_deleted: 'Ο δίσκος διαγράφτηκε',
    disk_deletion_dispatched: 'Εκκίνηση διαγραφής δίσκου',
    disk_server_name: 'Παρακαλούμε επιβεβαιώστε το όνομα του δίσκου',
    verification_name_does_not_match_disk:
      'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του δίσκου',

    ssh_key: 'SSH Key',
    ssh_keys: 'SSH Keys',
    add_key: 'Προσθήκη Key',
    edit_notes: 'Επεξεργασία σημειώσεων',
    change_type: 'Αλλαγή τύπου',
    delete_ssh_key: 'Διαγραφή SSH Key',
    delete_ssh_key_confirm: 'Θέλετε να διαγράψετε το SSH Key',

    datacenter_location: 'Τοποθεσία Datacenter',
    version: 'Έκδοση',
    disk_type: 'Τύπος δίσκου',
    disk_types: 'Τύποι δίσκων',
    disk_type_is_missing: 'Λείπει ο τύπος του δίσκου',

    select_datacenter: 'Επιλέξτε Datacenter',
    select_driver: 'Επιλέξτε Driver',
    select_server: 'Επιλέξτε Server',

    forgot_password: 'Ξέχασα τον κωδικό πρόσβασης',
    send_reminder: 'Αποστολή υπενθύμησης',
    reset_password: 'Επαναφορά κωδικού πρόσβασης',
    username_und_email: 'Όνομα χρήστη / Email',
    sign_in: 'Είσοδος',
    token_is_missing: 'Λείπει το token',

    create_subnet: 'Δημιουργία Subnet',
    subnet_created: 'Εκκίνηση Δημιουργίας Subnet',
    add_subnet: 'Προσθήκη subnet',
    edit_subnet: 'Επεξεργασία subnet',
    delete_subnet: 'Διαγραφή subnet',
    delete_subnet_confirm: 'Θέλετε να διαγράψετε το subnet',
    subnet_deleted: 'Το subnet διαγράφτηκε',
    subnet_updated: 'Το subnet ενημερώθηκε',

    edit_datacenter: 'Επεξεργασία Datacenter',
    delete_datacenter: 'Διαγραφή Datacenter',
    delete_datacenter_confirm: 'Θέλετε να διαγράψετε το datacenter',
    datacenter_deleted: 'Το Datacenter διαγράφτηκε',
    create_datacenter: 'Δημιουργία Datacenter',
    datacenter_created: 'Εκκίνηση Δημιουργίας Datacenter',
    datacenter_updated: 'Το Datacenter ενημερώθηκε',

    edit_notes_for: 'Επεξεργασία σημειώσεων για',

    disk_fatal_error: 'Ο δίσκος απενεργοποιήθηκε λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    server_includes_ip_address: 'Ο server περιλαμβάνει ήδη 1 IPv4 διεύθυνση',
    router_includes_ip_address: 'Ο router περιλαμβάνει ήδη 1 IPv4 διεύθυνση',
    includes_ip_address: 'περιλαμβάνει ήδη 1 IPv4 διεύθυνση',
    need_ip_address: 'Χρειάζεστε περισσότερες IP διευθύνσεις;',
    need_cpu: 'Χρειάζεστε περισσότερα vCPU;',
    need_ram: 'Χρειάζεστε περισσότερη RAM;',
    need_disk: 'Χρειάζεστε περισσότερη χωρητικότητα δίσκου;',
    ip_future: 'Μπορείτε να αιτηθείτε αναβάθμιση οποιαδήποτε στιγμή στο μέλλον',

    copied_to_clipboard: 'Αντιγράφτηκε στο πρόχειρο',
    not_copied_to_clipboard: 'Μη δυνατή η αντιγραφή στο πρόχειρο',

    select_parent_company: 'Επιλογή Parent Company',
    customer_type: 'Customer Type',
    customer_status: 'Customer Status',
    select_private_vcenter: 'Επιλογή Private vCenters',
    customer_emails: 'Customer Emails',
    invitation_links_to_emails:
      'Θα αποσταλούν email καλωσορίσματος στους λογαριασμούς του πελάτη',

    edit_network: 'Επεξεργασία δικτύου',
    network_updated: 'Το δίκτυο ενημερώθηκε',
    invite_users: 'Πρόσκληση χρηστών',
    enter_emails: 'Προσθήκη emails',
    remove_user: 'Διαγραφή χρήστη',
    remove_user_confirm: 'Θέλετε να διαγράψετε τον χρήστη',

    move_disk: 'Μετακίνηση δίσκου',
    disk_size: 'Μέγεθος δίσκου',
    change_disk_size: 'Αλλαγή μεγέθους δίσκου',
    resize_disk: 'Αλλαγή μεγέθους δίσκου',
    resize_disk_confirm: 'Θέλετε να αλλάξετε το μέγεθος του δίσκου',
    change_disk_type: 'Αλλαγή τύπου δίσκου',
    change_disk_type_warning: 'Πρέπει να επιλέξετε διαφορετικό τύπο δίσκου',

    manage_ssh_keys: 'Διαχείριση SSH Keys',

    server_network_card: 'Κάρτα δικτύου server',
    network_type: 'Τύπος δικτύου',
    network_card_updated: 'Η κάρτα δικτύου ενημερώθηκε',
    network_card_created: 'Η κάρτα δικτύου δημιουργήθηκε',

    no_history_record_found: 'Δεν βρέθηκαν δεδομένα ιστορικού',
    session_expired: 'Η σύνδεση έληξε',
    refresh_session: 'Ανανέωση σύνδεσης',

    public_key: 'Public Key',
    public_network: 'Public Network',
    public_networks: 'Public Networks',
    invitations_results: 'Αποτελέσματα προσκλήσεων',

    ip_address_blocks: 'Ip Address Blocks',
    ip_and_subnet: 'IP / Prefix',
    select_datacenter_location: 'Παρακαλούμε επιλέξτε τοποθεσία datacenter',

    esxi_profile: 'ESXI Profile',
    esxi_settings: 'ESXI Settings',
    nsx_settings: 'NSX Settings',
    nsx_host: 'NSX Host',
    policy_group: 'Policy Group',
    policy_group_missing: 'Δεν βρέθηκαν policies με τις τρέχουσες ρυθμίσεις',

    power_on: 'Ενεργοποίηση',
    power_off: 'Απενεργοποίηση',
    restart: 'Επαναφορά',
    shutdown_guest: 'Τερματισμός λειτουργίας',
    restart_guest: 'Επανεκκίνηση',

    rescale_server: 'Αλλαγή resources server',
    rescale_server_confirm: 'Θέλετε να αλλάξετε τα resources του server',
    current_snapshot: 'Τρέχον snapshot',
    no_snapshots_for_server: 'Δεν υπάρχουν snapshots για τον server.',
    restore_snapshot: 'Επαναφορά snapshot',
    restore_snapshot_confirm: 'Θέλετε να επαναφέρετε το snapshot',
    delete_snapshot: 'Διαγραφή snapshot',
    delete_snapshot_confirm: 'Θέλετε να διαγράψετε το snapshot',

    console_info: 'Επιλέξτε σύνδεση για να ανοίξει η κονσόλα',
    console_warning:
      'Ο server πρέπει να είναι ενεργοποιημένος για σύνδεση στην κονσόλα',
    _is_required: 'απαιτείται',
    select_ip: 'Επιλογή IP',
    no_results: 'Κανένα αποτέλεσμα',
    operating_system: 'Λειτουργικό σύστημα',
    cpu_tier: 'Βαθμίδα CPU',
    no_private_datacenters: 'Δεν έχετε private datacenters!',
    select_vcenter: 'Επιλογή vCenter',

    change_user_password: 'Αλλαγή κωδικού πρόσβασης χρήστη',
    old_password: 'Παλιός κωδικός πρόσβασης',
    password_confirmation: 'Επιβεβαίωση κωδικού πρόσβασης',

    no_active_tasks_found: 'Δεν βρέθηκαν ενεργές διεργασίες',
    active_tasks_loading: 'Φόρτωμα ενεργών διεργασιών',
    no_tasks_found: 'Δεν βρέθηκαν διεργασίες',
    tasks_loading: 'Φόρτωμα διεργασιών',
    task_status: 'Κατάσταση διεργασιών',
    requestedBy: 'Αιτήθηκε από',

    recycle_pool_name: 'Recycle Pool Name',
    machine_password: 'Machine Password',
    is_nsx_managed: 'NSX Managed',
    root_password: 'Νέος κωδικός πρόσβασης',
    administrator_password: 'Κωδικός πρόσβασης Administrator',
    repeat_root_password: 'Επανάληψη νέου κωδικού πρόσβασης',
    repeat_administrator_password: 'Επανάληψη κωδικού πρόσβασης Administrator',
    generate_password: 'Τυχαίος κωδικός πρόσβασης με αποστολή email',
    manual_password: 'Δημιουργία κωδικού πρόσβασης με αποστολή email',
    manual_password_no_email:
      'Δημιουργία κωδικού πρόσβασης χωρίς αποστολή email',
    password_mode: 'Επιλογή κωδικού πρόσβασης',

    feedback_sent: 'Το feedback έχει αποσταλεί',
    issue_sent: 'Το πρόβλημα έχει αποσταλεί',
    maintenance_queue_message:
      'Η ουρά εκτέλεσης βρίσκεται σε κατάσταση συντήρησης, οι διεργασίες θα καθυστερήσουν',
    maintenance_mode_message:
      'Η υπηρεσία βρίσκεται σε κατάσταση συντήρησης, θα επιστρέψουμε ξανά σύντομα',
    delete_vpn: 'Διαγραφή VPN',
    delete_vpn_confirm: 'Θέλετε να διαγράψετε το VPN',
    create_vpn: 'Δημιουργία VPN',
    vpn_type: 'Τύπος VPN',
    add_vpn: 'Προσθήκη VPN',
    routes_is_missing: 'Λείπουν τα routes',
    users_email_is_missing: 'Λείπει το email του χρήστη',
    internal_ip_config_is_missing: 'Λείπει το config της internal IP',
    vpn_overview: 'Επισκόπηση VPN',

    delete_this_issue: 'Διαγραφή προβλήματος',
    delete_this_issue_confirm: 'Θέλετε να διαγράψετε το πρόβλημα',
    issue_deleted: 'Το πρόβλημα διαγράφτηκε',
    issue_view: 'Προβολή προβλήματος',
    manage_firewall_rules: 'Διαχείριση κανόνων Firewall',

    delete_firewall_rule: 'Διαγραφή κανόνα Firewall',
    delete_firewall_rule_confirm: 'Θέλετε να διαγράψετε τον κανόνα firewall',
    firewall_rule_deleted: 'Ο κανόνας firewall διαγράφτηκε',

    snapshot_is_active: 'Ο server έχει ενεργό snapshot',
    read_only_is_active: 'Ο server είναι read-only',
    actions_disabled: 'Κάποιες ενέργειες είναι απενεργοποιημένες',
    pw_strength: 'Ισχύς κωδικού πρόσβασης',

    add_load_balancer: 'Προσθήκη Load Balancer',
    load_balancer_fatal_error: 'Ο load balancer είναι απενεργοποιημένος λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    create_load_balancer: 'Δημιουργία Load Balancer',
    add_certificate: 'Προσθήκη Certificate',
    load_balancer_overview: 'Επισκόπηση Load Balancer',
    network_is_missing: 'Λείπει το δίκτυο',
    algorithm_is_missing: 'Λείπει ο algorithm',
    delete_load_balancer: 'Διαγραφή Load Balancer',
    delete_load_balancer_confirm: 'Θέλετε να διαγράψετε τον load balancer',
    server_unavailable:
      'Οι servers που είναι σε read-only ή με ενεργό snapshot είναι μη διαθέσιμοι',
    choose_private_network_to_enable:
      'Επιλέξτε private network για να ενεργοποιήσετε τους server',
    no_servers_found: 'Δεν βρέθηκαν servers',

    delete_certificate: 'Διαγραφή Certificate',
    delete_certificate_confirm: 'Θέλετε να διαγράψετε το certificate',

    add_router: 'Προσθήκη Router',
    router_fatal_error: 'Ο router είναι απενεργοποιημένος λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    create_router: 'Δημιουργία Router',
    router_overview: 'Επισκόπηση Router',
    dns_overview: 'Επισκόπηση DNS Zone',
    object_bucket_overview: 'Επισκόπηση Object Bucket',
    ip_and_subnet_is_missing: 'Λείπει η IP/Subnet',
    rescalePackage: 'Πακέτο resources',
    rescalePackage_is_missing: 'Λείπει το πακέτο resources',
    delete_router_confirm: 'Θέλετε να διαγράψετε το router',
    delete_router: 'Διαγραφή router',
    confirm_router_name: 'Παρακαλούμε επιβεβαιώστε το όνομα του router',
    router_network_card: 'Κάρτα δικτύου router',
    services_is_missing: 'Λείπουν τα services',
    select_disk_type: 'Επιλέξτε τύπο δίσκου',
    add_customer: 'Προσθήκη Customer',
    add_vcenter: 'Προσθήκη vCenter',

    select_customer: 'Επιλογή Customer',
    graphs_empty_when_server_closed:
      'Τα γραφήματα μπορεί να είναι κενά όταν ο server είναι απενεργοποιημένος',

    router_created: 'Εκκίνηση Δημιουργίας Router',
    router_deletion_dispatched: 'Εκκίνηση διαγραφής router',
    certificate_card_deleted: 'Η κάρτα certificate διαγράφτηκε',
    edit_backups: 'Επεξεργασία backups',
    delete_backups: 'Διαγραφή backups',
    delete_backup_confirm: 'Θέλετε να διαγράψετε τα υπάρχοντα backup',
    backup_card_disable: 'Απενεργοποίηση κάρτας backup',
    certificated_created: 'Εκκίνηση Δημιουργίας Certificate',
    certificated_edited: 'Το Certificate ενημερώθηκε',
    add_certicate: 'Προσθήκη Certificate',
    wait_for_backups:
      'Παρακαλούμε περιμένετε μέχρι την ολοκλήρωση του αρχικού backup του server, ελέγξτε πάλι αύριο.',
    schedule_hardware_upgrade: 'Προγραμματισμός Αναβάθμισης Hardware ',

    create_firewall: 'Δημιουργία Firewall',
    create_snapshot: 'Δημιουργία Snapshot',
    server_is_active: 'Ο server είναι ενεργοποιημένος',
    delete_disabled:
      'Η επιλογή διαγραφής έχει απενεργοποιηθεί μέχρι να ολοκληρωθούν άλλες ενέργειες',
    load_balancer_created: 'Εκκίνηση Δημιουργίας Load Balancer',
    load_balancer_updated: 'Ο Load Balancer ενημερώθηκε',
    delete_member: 'Διαγραφή μέλους',
    delete_member_confirm: 'Θέλετε να διαγράψετε το μέλος',
    add_member: 'Προσθήκη μέλους',
    select_condition: 'Επιλογή condition',
    imports: 'Imports',
    sync_import: 'Sync import',
    sync_import_confirm: 'Θέλετε να γίνει sync import',
    cant_sync_not_exists:
      "You can't sync the rows with report issue 'Not Exist'",
    cant_import_with_differences:
      "You can't import the rows with report issue 'Differences'",
    select_row: 'Επιλέξτε γραμμή',
    reinstall_server_confirm: 'Θέλετε να γίνει επανεγκατάσταση του server',
    reinstallation_info:
      'Η επανεγκατάσταση προσφέρει την επιλογή επαναφοράς της αρχικής κατάστασης του server που ίσχυαν κατά την παραγγελία.',
    reinstall_warnings: {
      backup_data:
        'Επιβεβαιώστε ότι έχετε κρατήσει αντίγραφα ασφαλείας όλων των αρχείων σας πριν την επανεγκατάσταση',
      shutdown:
        'Ο server πρέπει να είναι απενεργοποιημένος πριν την επανεγκατάσταση.'
    },
    delete_warnings: {
      shutdown: 'Ο server πρέπει να είναι απενεργοποιημένος πριν τη διαγραφή.',
      files_exist: 'Το Bucket πρέπει να είναι κενό πριν την διαγραφή'
    },
    restore_backup: 'Επαναφορά backup',
    restore_backup_confirm:
      'Θέλετε όντως να επαναφέρετε το backup; Αυτή η ενέργεια είναι μη αναστρέψιμη, που σημαίνει ότι όλα τα δεδομένα θα έχουν διαγραφεί μετά την ',
    restoring_backup: 'Επαναφορά backup',
    in_order_to_restore_backup:
      'Για να γίνει επαναφορά του backup επιβεβαιώστε το όνομα του server ',
    verification_name_does_not_match_restore_name:
      'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του server',
    backup_running: 'Backup σε εξέλιξη',
    are_you_sure_reinstall:
      'Θέλετε σίγουρα να ξεκινήσει η διαδικασία επανεγκατάστασης του λειτουργικού συστήματος; Αυτή η ενέργεια θα διαγράψει όλα τα δεδομένα και τις ρυθμίσεις του server. Για να συνεχίσετε πληκτρολογήστε ',
    verification_name_does_not_match_server_name:
      'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του server',
    reinstalling_os: 'Επανεγκατάσταση λειτουργικού συστήματος',
    audit_dialog_required: 'Απαιτείται Software Audit!',
    audit_dialog_description: 'Παρακαλούμε εκτελέστε το audit service.',
    audit: 'Audit',
    select_kind: 'Επιλέξτε είδος',
    select_router: 'Επιλέξτε Router',
    different_version_from_server:
      'Ο server έχει διαφορετικό λειτουργικό σύστημα σε αυτό το backup',
    choose_server_or_router: 'Πρέπει πρώτα να επιλέξετε έναν server ή router.',
    load_balancer_deletion_dispatched: 'Εκκίνηση διαγραφής Load Balancer.',
    create_new_site: 'Δημιουργία νέας τοποθεσίας',
    creation_time: 'Ώρα δημιουργίας',
    enable_disable_site: 'Ενεργοποίηση/Απενεργοποίηση τοποθεσίας',
    delete_ipsec_site: 'Διαγραφή τοποθεσίας IPSec',
    delete_ipsec_site_confirm: 'Θέλετε να διαγράψετε την τοποθεσία IPSec',
    ipsec_site_deletion_dispatched: 'Εκκίνηση διαγραφής τοποθεσίας IPSec',
    ipsec_created: 'Εκκίνηση Δημιουργίας IPSec',
    ipsec_edited: 'Η τοποθεσία IPSec ενημερώθηκε',
    ipsec_site_fatal_error: 'Η τοποθεσία IPSec απενεργοποιήθηκε λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    invalid_same_remote_local_endpoint:
      'Η IP δεν μπορεί να είναι η ίδια στο τοπικό και στο απομακρυσμένο endpoint.',
    enable_console_task: 'Ενεργοποίηση κονσόλας',
    add_product: 'Προσθήκη Product',
    product_rates: 'Product Rates',
    add_customer_override: 'Προσθήκη Customer Override',
    delete_pricing_override: 'Διαγραφή Customer Override',
    delete_pricing_override_confirm: 'Θέλετε να διαγράψετε το pricing override',
    delete_service_override: 'Διαγραφή Service Override',
    delete_service_override_confirm: 'Θέλετε να διαγράψετε το service override',
    add_service_override: 'Προσθήκη Services Override',
    customer_name: 'Customer Name',
    customer_product: 'Customer Product',
    type: 'Type',
    rate_per_month: 'Rate Per Month',
    rate_per_hour: 'Rate Per Hour',
    service_type: 'Service Type',
    server_off_to_enable_console:
      'Ο server πρέπει να είναι απενεργοποιημένος για να ενεργοποιηθεί η κονσόλα',
    phase_one_is_missing: 'Λείπει το phase one',
    phase_two_is_missing: 'Λείπει το phase two',
    create_static_route: 'Δημιουργία static route',
    static_route: 'Static Route',
    delete_static_route_card: 'Διαγραφή Static Route',
    delete_static_route_card_confirm: 'Θέλετε να διαγράψετε το static route',
    static_route_card_deleted: 'Το static route διαγράφτηκε',
    static_route_edited: 'Το static route ενημερώθηκε',
    static_route_created: 'Εκκίνηση Δημιουργίας Static Route',
    firewall_fatal_error: 'Οι κανόνες firewall έχουν απενεργοποιηθεί λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    static_route_fatal_error: 'Τα static routes έχουν απενεργοποιηθεί λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    virtual_media: 'Virtual Media',
    select_iso: 'Επιλογή ISO',
    mount_on_server: 'Mount στον server',
    unmount_from_server: 'Unmount από τον server',
    eject_before_unmount: 'Πρέπει να εξάγετε το ISO πριν το unmount',
    form_has_submitted: 'Το αίτημά σας έχει καταχωρηθεί!',
    verification_has_sent: 'Έχει αποσταλεί μήνυμα επιβεβαίωσης!',
    mail_confirmation:
      'Θα λάβετε email επιβεβαίωσης με πρόσκληση στο panel μόλις η αίτησή σας γίνει αποδεκτή.',
    submit_to_send_message:
      'Συνέχεια στην αποστολή επιβεβαίωσης στο κινητό τηλέφωνο που έχετε δηλώσει.',
    message_validation_number:
      'Έχει αποσταλεί στο κινητό σας τηλέφωνο ένα μήνυμα με κωδικό επιβεβαίωσης, παρακαλούμε εισάγετέ το στο πεδίο για ολοκλήρωση της διαδικασίας.',
    server_mount_dispatched: 'Εκκίνηση διαδικασία mount του ISO',
    server_unmount_dispatched: 'Εκκίνηση διαδικασίας unmount του ISO',
    enable_two_fac_auth_dispatched:
      'Εκκίνηση διαδικασίας ενεργοποίησης Two Factor Authentication',
    verification_token_dispatched: 'Αποστολή Token',
    disable_two_fac_auth_dispatched:
      'Εκκίνηση διαδικασίας απενεργοποίησης Two Factor Authentication',
    change_password_dispatched:
      'Εκκίνηση διαδικασίας αλλαγής κωδικού πρόσβασης',
    forgot_password_dispatched:
      'Έγινε αποστολή συνδέσμου για αλλαγή κωδικού πρόβασης, παρακαλώ ελέγξτε το email σας.',
    router_is_missing: 'Λείπει ο router',
    unmount_iso: 'Unmount ISO',
    unmount_iso_confirm: 'Θέλετε να γίνει unmount το ISO',
    eject_from_console:
      "Πριν το unmount πρέπει να πληκτρολογήσετε στην κονσόλα 'eject'",
    approve_registration_confirm: 'Θέλετε να εγκριθεί η εγγραφή του ',
    reject_registration_confirm: 'Θέλετε να απορριφθεί η εγγραφή του ',
    duplicate_registration_confirm: 'Αυτή η εγγραφή είναι αντίγραφο της ',
    registration_action: 'Ενέργεια εγγραφής',
    panel_welcome_message:
      'Παρακαλούμε παραχωρήστε τα στοιχεία σας για να σας δοθεί πρόσβαση στο {brandname} panel.',
    auto_eject_in: 'Το ISO θα γίνει αυτόματα unmount στις ',
    network_card_in_use_for_load_balancer:
      'Η κάρτα δικτύου χρησιμοποιείται στον Load Balancer ',
    network_card_in_use_for_static_route_next_hop:
      'Η κάρτα δικτύου χρησιμοποιείται σε static route στο next hop ',
    network_card_in_use_for_ipsec_edge_internal:
      'Η κάρτα δικτύου χρησιμοποιείται στο εσωτερικό δίκτυο της τοποθεσία IPSec ',
    network_in_use_for_ipsec_edge_internal:
      'Το δίκτυο χρησιμοποιείται από την τοποθεσία IPSec ',
    ipsec_fatal_error: 'Η τοποθεσία IPSec έχει απενεργοποιηθεί λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    register_send: {
      title: 'Απαιτείται επιβεβαίωση κινητού τηλεφώνου',
      description:
        'Πατήστε εδώ για αποστολή κωδικού επιβεβαίωσης στο κινητό τηλέφωνο που έχετε καταχωρήσει',
      button: 'Αποστολή κωδικού'
    },
    register_verify: {
      title: 'Ο κωδικός επιβεβαίωσης έχει αποσταλεί!',
      description:
        'Έχει αποσταλεί στο κινητό σας τηλέφωνο ένας κωδικός επιβεβαίωσης.',
      input_label: 'Κωδικός επιβεβαίωσης'
    },
    select_type: 'Επιλογή τύπου',
    certificate_in_use_for_load_balancer:
      'Το certificate χρησιμοποιείται στον Load Balancer ',
    import_dispatched: 'Import Dispatched',
    extend_disk: 'Επέκταση δίσκου',
    cpu_resource_not_enough: 'Δεν επαρκούν οι διαθέσιμοι πόροι CPU',
    ram_resource_not_enough: 'Δεν επαρκούν οι διαθέσιμοι πόροι RAM',
    disk_resource_not_enough: 'Δεν επαρκούν οι διαθέσιμοι πόροι δίσκου',
    select_disk_type_first: 'Πρέπει πρώτα να επιλέξετε τύπο δίσκου',
    select_location_first: 'Πρέπει πρώτα να επιλέξετε τοποθεσία.',
    see_resources: 'Προβολή πόρων',
    resources_not_enough: 'οι πόροι δεν επαρκούν',
    add_storage_account: 'Προσθήκη Storage Account',
    delete_storage_account: 'Διαγραφή Storage Account',
    delete_storage_account_confirm: 'Θέλετε να διαγράψετε το storage account ',
    storage_account_deleted: 'Το storage account διαγράφτηκε',
    storage_account_fatal_error: 'Το storage account απενεργοποιήθηκε λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    storage_account_name: 'Όνομα Storage Account',
    create_storage_account: 'Δημιουργία Storage Account',
    service_type_is_missing: 'Λείπει το service type',
    storage_account_overview: 'Επισκόπηση Storage Account',
    storage_account_created: 'Εκκίνηση Δημιουργίας Storage Account',
    rescale_storage_account: 'Αλλαγή resources Storage Account',
    create_nat: 'Δημιουργία NAT',
    enable_disable_rule: 'Ενεργοποίηση/Απενεργοποίηση κανόνα',
    must_be_subset_of_internal_ip_or_static_route:
      'Πρέπει να είναι subnet της εσωτερικής IP ή static route',
    must_be_subset_of_internal_ip_or_static_route_or_remote_subnet_ipsec:
      'Πρέπει να είναι subnet της εσωτερικής IP ή static route ή απομακρυσμένο subnet IPSec',
    must_be_subset_of_internal_ip_or_static_route_not_applied_on:
      "Δεν θα πρέπει να είναι subnet στο 'applied on' interface",
    must_not_be_router_ip:
      'Δεν θα πρέπει να είναι IP χρησιμοποιούμενη από router',
    must_be_subset_of_internal_ip_or_static_route_not_applied_on_static:
      "Δεν θα πρέπει να είναι subnet από static route με next hop στο 'applied on' interface",
    must_not_be_subset_of_internal_ip_or_static_route:
      'Δεν θα πρέπει να είναι subnet από εσωτερική IP ή static route',
    must_belong_in_subnets: 'Η IP πρέπει να είναι εντός subnet',
    nat_fatal_error: 'Οι κανόνες NAT έχουν απενεργοποιηθεί λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    must_not_be_network_card_ip:
      'Η source IP δεν θα πρέπει να είναι IP κάρτας δικτύου',
    destination_ip_must_not_be_subset_of_original_source_ip:
      'Η destination IP δεν θα πρέπει να είναι subnet από την source IP',
    destination_ip_no_static_next_hop_source_ip:
      "Η destination IP δεν θα πρέπει να είναι subnet από static route με next hop στο 'source IP' δίκτυο",
    delete_nat_rule: 'Διαγραφή κανόνα NAT',
    delete_nat_rule_confirm: 'Θέλετε να διαγράψετε τον κανόνα NAT ',
    nat_rule_deleted: 'Ο κανόνας NAT διαγράφτηκε',
    source_ip_must_be_subset_of_applied_on:
      "Η source IP θα πρέπει να είναι subnet  ή ίδια με το 'applied on' δίκτυο",
    source_address_and_destination_not_same_network:
      'Οι διευθύνσεις source και destination δεν μπορούν να ανήκουν στο ίδιο δίκτυο',
    must_not_be_same_as_original_source_ip:
      'Η destination IP δεν μπορεί να είναι η ίδια με την source IP',
    please_put_valid_ports: 'Παρακαλούμε εισάγετε έγκυρο port range',
    please_put_valid_port: 'Παρακαλούμε εισάγετε έγκυρο port',
    must_be_greater_ports:
      'Το ending port number πρέπει να είναι μεγαλύτερο του starting port number',
    you_cant_downsize_disk_after:
      'Αν περάσετε το όριο των 100GB δεν θα μπορείτε στο μέλλον να μειώσετε τη χωρητικότητα του δίσκου',
    disksize_must_be_greater_than_usage:
      'Το μέγεθος του δίσκου δεν μπορεί να είναι μικρότερο από την πραγματική του χρήση',
    you_cant_downsize_disk_now:
      'Δεν μπορείτε να μειώσετε τη χωρητικότητα του δίσκου',
    add_resource_group: 'Προσθήκη Resource Group',
    resource_group_deletion_dispatched: 'Εκκίνηση διαγραφής Resource Group',
    at_least_one_resource: 'Θα πρέπει να υπάρχει τουλάχιστον ένα resource',
    delete_product: 'Διαγραφή Product',
    delete_product_confirm: 'Θέλετε να διαγράψετε το product',
    product_created: 'Το product δημιουργήθηκε',
    product_updated: 'Το product ενημερώθηκε',
    product_deleted: 'Το product διαγράφτηκε',
    product_category_created: 'Η κατηγορία προϊόντος δημιουργήθηκε',
    product_category_updated: 'Το κατηγορία προϊόντος ενημερώθηκε',
    add_product_category: 'Προσθήκη Product Category',
    delete_product_category: 'Διαγραφή Product Category',
    delete_product_category_confirm:
      'Θέλετε να διαγράψετε το product category;',
    product_category_deleted: 'Το Product Category διαγράφτηκε',
    customer_overview: 'Επισκόπηση Customer',
    update_customer: 'Ενημέρωση Customer',
    network_must_not_exceed_ten:
      'Δεν μπορείτε να ξεπεράσετε το όριο των δέκα δικτύων',
    file_with_that_name_already_exists:
      'Υπάρχει ήδη ένα αρχείο με αυτό το όνομα στον φάκελο',
    folder_with_that_name_already_exists:
      'Υπάρχει ήδη ένας φάκελος με αυτό το όνομα στον φάκελο',
    select_folder:
      'Παρακαλούμε επιλέξτε έναν φάκελο για να μεταφορτωθεί το αρχείο',
    ipsec_statistics: 'Στατιστικά IPSec',
    statistic_details: 'Λεπτομέρειες στατιστικών',
    must_be_valid_range: 'Το port θα πρέπει να είναι μεταξύ 1 και 65535',
    'power_shutdown-guest_server': 'Τερματισμός λειτουργίας server',
    'power_shutdown-guest_server_confirm':
      'Θέλετε σίγουρα να προχωρήσετε σε τερματισμό λειτουργίας του server;',
    'power_powered-off_server': 'Απενεργοποίηση server',
    'power_powered-off_server_confirm':
      'Θέλετε σίγουρα να προχωρήσετε σε απενεργοποίηση του server;',
    power_restarted_server: 'Επαναφορά server',
    power_restarted_server_confirm:
      'Θέλετε σίγουρα να προχωρήσετε σε επαναφορά του server;',
    'power_reboot-guest_server': 'Επανεκκίνηση server',
    'power_reboot-guest_server_confirm':
      'Θέλετε σίγουρα να προχωρήσετε σε επανεκκίνηση του server;',
    show_password: 'Εμφάνιση κωδικού πρόσβασης',
    loading_new_graph: 'Φόρτωση νέου γραφήματος',
    all_ips: 'Όλες οι IP',
    delete_node: 'Διαγραφή Node',
    delete_node_confirm: 'Θέλετε σίγουρα να διαγράψετε το ',
    delete_key: 'Διαγραφή Κλειδιού',
    delete_key_confirm: 'Θέλετε σίγουρα να διαγράψετε το ',
    file_cant_exceed_1GB:
      'Το μέγεθος του αρχείου δεν μπορεί να ξεπεράσει το 1GB',
    network_card_doesnt_have_secondary_ip:
      'Η κάρτα δικτύου δεν έχει secondary IP',
    delete_session: 'Διαγραφή session',
    delete_session_confirm:
      'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το session;',
    session_deleted: 'Το session διαγράφτηκε',
    delete_safe_device: 'Διαγραφή safe device',
    delete_safe_device_confirm:
      'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το safe device;',
    safe_device_deleted: 'Η συσκευή διαγράφτηκε',
    company_profile: 'Company Profile',
    edit_access_list: 'Επεξεργασία access list',
    allow_login_from: '(αποδοχή εισόδου από)',
    security_user_message:
      'Προσδιορίστε μεμονωμένες IP διευθύνσεις ή IP subnets για πρόσβαση στο {brandDomain} για τον λογαριασμό σας. Όταν δεν έχει προσδιοριστεί καμία IP, η σύνδεση επιτρέπεται από παντού.',
    security_user_caution_message:
      'Προσοχή! Επιτρέπονται μόνο public IPs. Λάθος ρύθμιση θα απαγορεύσει την πρόσβαση του χρήστη στο panel.',
    verify_email: 'Επιβεβαίωση',
    load_balancer_statistics: 'Στατιστικά Load Balancer',
    rescue_mode: 'Rescue Mode',
    change_rescue_mode: 'Αλλαγή Rescue Mode',
    rescue_mode_is_enabled: 'Το Rescue Mode είναι ενεργό',
    sure_disable_rescue_mode:
      'Είστε σίγουροι ότι θέλετε να απενεργοποιήσετε το rescue mode;',
    sure_enable_rescue_mode:
      'Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε το rescue mode;',
    valid_cpu_per_socket:
      'Οι πυρήνες CPU ανά socket θα πρέπει να είναι ίσοι ή μικρότεροι από το σύνολό τους',
    orders_details: 'Λεπτομέρειες Order',
    disable_protection: 'Απενεργοποίηση προστασίας',
    enable_protection: 'Ενεργοποίηση προστασίας',
    protection_is_enabled: 'Η προστασία είναι ενεργοποιημένη',
    add_vpn_concentrator: 'Προσθήκη VPN Concentrator',
    create_vpn_concentrator: 'Δημιουργία VPN Concentrator',
    vpnConcentrator_fatal_error: 'Ο VPN Concentrator έχει απενεργοποιηθεί λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    vpn_concentrator_overview: 'Επισκόπηση VPN Concentrator',
    vcenter_overview: 'Επισκόπηση vCenter',
    private_network_is_missing: 'Λείπει το private network',
    add_profile: 'Προσθήκη Profile',
    delete_vpn_concentrator_confirm:
      'Θέλετε να διαγράψετε τον VPN Concentrator ',
    delete_vpn_concentrator: 'Διαγραφή VPN Concentrator',
    confirm_vpn_concentrator_name:
      'Παρακαλούμε επιβεβαιώστε το όνομα του VPN Concentrator',
    add_user: 'Προσθήκη χρήστη',
    add_ip_pool: 'Προσθήκη IP Pool',
    delete_ip_pool: 'Διαγραφή IP Pool',
    delete_ip_pool_confirm: 'Θέλετε να διαγράψετε το IP Pool ',
    network_must_not_be_overlaping_ipsec_remote:
      'Network is overlaping ipsec remote',
    vpn_concentrator_created: 'Εκκίνηση Δημιουργίας VPN Concentrator',
    vpn_concentrator_network_card: 'Κάρτα δικτύου VPN Concentrator',
    delete_profile: 'Διαγραφή Profile',
    delete_profile_confirm: 'Θέλετε να διαγράψετε το profile ',
    profile_deleted: 'Το profile διαγράφτηκε',
    delete_user: 'Διαγραφή χρήστη',
    delete_user_confirm: 'Θέλετε να διαγράψετε τον χρήστη ',
    vpn_concentrator_deletion_dispatched: 'Εκκίνηση διαγραφής VPN Concentrator',
    verification_name_does_not_match_vpn_concentrator:
      'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του VPN Concentrator',
    user_deleted: 'Ο χρήστης διαγράφτηκε',
    profile_used_on_user: 'Ένας χρήστης χρησιμοποιεί αυτό το profile',
    ip_pool_created: 'Το IP Pool δημιουργήθηκε',
    ip_pool_updated: 'Το IP Pool ενημερώθηκε',
    ip_pool_deleted: 'Το IP Pool διαγράφτηκε',
    _ip_pool_can_not_be_deleted:
      'Το IP Pool δεν μπορεί να διαγραφεί. Χρησιμοποιείται από ένα profile',
    generate_password_simple: 'Τυχαίος κωδικός πρόσβασης',
    manual_password_simple: 'Εισαγωγή κωδικού πρόσβασης',
    user_created: 'Ο χρήστης δημιουργήθηκε',
    user_updated: 'Ο χρήστης ενημερώθηκε',
    _network_card_can_not_be_deleted:
      'Η κάρτα δικτύου δεν μπορεί να διαγραφεί. Χρησιμοποιείται από έναν χρήστη.',
    ip_pool_used_on_profile: 'Ένα profile χρησιμοποιεί το IP Pool',
    network_card_used_on_user:
      'Ένας χρήστης χρησιμοποιεί αυτή την κάρτα δικτύου',
    scan_qrcode:
      'Σκανάρετε αυτό το QR code με το Lancom Authenticator για να πιστοποιήσετε τη συσκευή σας',
    vpn_user_statistics: 'Στατιστικά χρήστη VPN',
    _is_not_connected: 'δεν είναι συνδεδεμένο',
    migrate_disk: 'Migrate δίσκου',
    no_other_datastores: 'Δεν υπάρχουν άλλα datastores για migration.',
    migration_successful: 'Επιτυχές migration',
    reverse_path_forwarding: 'Reverse Path Forwarding',
    select_type_reverse_path: 'Επιλέξτε τύπο για την κάρτα δικτύου με ID ',
    select_types_reverse_path: 'Επιλέξτε τύπο για τις κάρτες δικτύου με ID ',
    network_cards_selected: 'Επιλεγμένες κάρτες δικτύου',
    network_card_selected: 'Επιλεγμένη κάρτα δικτύου',
    not_set_yet: 'Δεν έχει οριστεί ακόμα',
    select_backup_option: 'Επιλογή backup',
    backup_enabled: 'Το backup ενεργοποιήθηκε',
    backup_updated: 'Το backup ενημερώθηκε',
    admin_rule: 'Admin Rule',
    move_resource_pool: 'Μετακίνηση Resource Pool',
    no_other_resource_pools: 'Δεν υπάρχουν άλλα resource pools για μετακίνηση.',
    movement_successful: 'Επιτυχής μεταφορά',
    days_7_backups: 'Backup 7 ημερών',
    days_2_backups: 'Backup 2 ημερών',
    demo_expiration_date: 'Ημερομηνία λήξης demo',
    customer_updated: 'Ο Customer ενημερώθηκε',
    customer_created: 'Εκκίνηση Δημιουργίας Customer',
    no_subnet_selected: 'Δεν επιλέχτηκε subnet',
    showing_ips_subnet: 'Εμφάνιση IPs από subnet',
    subnet_selected: 'Επιλέχτηκε το subnet',
    select_subnet: 'Επιλέξτε ένα subnet',
    reserve_ip: 'Κατοχύρωση IP',
    admin_rules: 'Admin Rules',
    user_rules: 'User Rules',
    no_user_rules: 'Δεν υπάρχουν user rules',
    no_admin_rules: 'Δεν υπάρχουν admin rules',
    edit_name: 'Επεξεργασία ονόματος',
    rename_successful: 'Επιτυχής μετονομασία',
    edit_info: 'Επεξεργασία πληροφοριών',
    edit_subnets: 'Επεξεργασία Subnet',
    edit_successful: 'Επιτυχής επεξεργασία',
    not_same_os: 'Δεν είναι το ίδιο λειτουργικό σύστημα',
    current_pool: 'Τρέχον Pool',
    not_same_vcenter: 'Δεν είναι το ίδιο vCenter',
    maximum_pools_reached: 'Μέγιστος αριθμός Pool',
    only_system_pool: 'Συστεμικό Resource Pool',
    not_for_cloud_servers: 'Δεν είναι για Cloud Servers',
    not_same_customer: 'Όχι ίδιος Customer',
    maintance_mode: 'Εργασίες Συντήρησης',
    active_customers: 'Εμφάνιση μόνο Active Customers',
    billing_feautures: 'Επιλογές Τιμολόγησης',
    billing_overview: 'Οικονομικά Στοιχεία',
    billing_prices: 'Τιμές',
    can_not_delete_ipsec_site:
      'Δεν μπορεί να διαγραφεί η τοποθεσία IPSec επειδή χρησιμοποιείται σε ένα static route.',
    automatic_edit: 'Αυτόματη Επεξεργασία',
    automatic_edit_desc:
      "Αν επιλέξετε την 'Αυτόματη Επεξεργασία' τα static routes που χρησιμοποιούσαν το προηγούμενο tunnel θα αλλάξουν αυτόματα για στο νέο.",
    contact_verified: 'Η επαφή επιβεβαιώθηκε',
    disable_network: 'Απενεργοποίηση δικτύου',
    download_invoice_pdf: 'Λήψη τιμολογίου σε μορφή PDF',
    enable_network: 'Ενεργοποίηση δικτύου',
    final_invoice: 'Τελικό τιμολόγιο',
    ipsec_automatic_select: "Ή επιλέξτε 'Αυτόματη Επεξεργασία' παρακάτω",
    ipsec_manually_select:
      'Προχωρήστε σε χειροκίνητη διαγραφή των static routes που σχετίζονται με αυτή την τοποθεσία IPSec.',
    lancom_pay: 'Πληρώστε με LancomPay',
    last_update: 'Τελευταία ενημέρωση',
    network_disabled: 'Δίκτυο απενεργοποιημένο',
    network_enabled: 'Δίκτυο ενεργοποιημένο',
    prepayment_invoice: 'Προτιμολόγιο',
    show_invoice_pdf: 'Εμφάνιση τιμολογίου σε μορφή PDF',
    tunnel_used_warning:
      'Ένα static route χρησιμοποιεί το tunnel αυτής της τοποθεσίας IPSec.',
    verify_contact: 'Επιβεβαίωση αριθμού επαφής',
    verification_code_sent: 'Στάλθηκε κωδικός επιβεβαίωσης στον αριθμό επαφής',
    fill_code_below: 'Συμπληρώστε τον κωδικό παρακάτω.',
    verification_mail_send:
      'Ελέγξτε το email σας. Ο κωδικός επιβεβαίωσης στάλθηκε.',
    information_saved: 'Πληροφορίες αποθηκεύτηκαν',
    records_per_page: 'Εμφάνιση ανά σελίδα:',
    delete_snap_auto_at: 'Το snapshot θα διαγραφεί αυτόματα στις',
    delete_snap_auto_after: 'Το snapshot θα διαγραφεί αυτόματα μετά από',
    network_disabled_error_message: 'Δίκτυο προσωρινά μη διαθέσιμο. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {email} για διευθέτηση του ζητήματος.',
    member_statistics: 'Στατιστικά Μέλους',
    download_user_config:
      'Εάν χρησιμοποιείτε αρχεία παραμετροποίησης χρήστη που σχετίζονται με την αλλαγή θα πρέπει να κάνετε λήψη ξανά',
    download_openvpn: 'Λήξη αρχείου παραμετροποίησης OpenVPN',
    days_30_backups: 'Backup 30 ημερών',
    enter_psk_text: 'Enter new Pre-Shared Key value',
    delete_psk: 'Delete Pre-Shared Key',
    delete_psk_confirm:
      'Η διαγραφή αυτού του Pre-Shared Key θα επηρεάσει όλα τα Ipsecs με Dynamic Mode',
    create_psk: 'Δημιουργία Pre-Shared Key',
    cannot_delete_psk_confirm:
      'Το Pre-Shared Key χρησιμοποείται από κάποιο Ipsec Site και δεν μπορεί να διαγραφεί.',
    key_created: 'Εκκίνηση Δημιουργίας Pre-Shared Key ',
    key_updated: 'Το Pre-Shared Key ενημερώθηκε',
    key_deleted: 'Το Pre-Shared Key διαγράφτηκε',
    apply_before_delete_psk:
      'Εφαρμόστε τις αλλαγές πριν την διαγραφή του Pre-Shared Key',
    approve_registration_dispatched: 'Έγκριση Εγγραφής Εστάλει',
    reject_registration_dispatched: 'Απόρριψη Εγγραφής Εστάλει',
    duplicate_registration_dispatched: 'Εντολή Διπλότυπης Εγγραφής Εστάλει',
    disabled_firewall: 'Απενεργοποιημένο Firewall. Δεν επιτρέπονται ενέργειες.',
    scopes_fetched: 'Τα Scopes ανακτήθηκαν',
    no_data: 'Δεν υπάρχουν δεδομένα',
    active_orders: 'Εμφάνιση μόνο Active Orders',
    cannot_delete_last_private_network_card:
      'Δεν μπορεί να διαγραφεί η τελευταία ιδιωτική κάρτα δικτύου',
    valid_ipv4_or_fqdn: 'Πρέπει να είναι έγκυρο FQDN ή IPv4',
    create_resource: 'Δημιουργία Resource',
    resources_management: 'Resources Management',
    delete_resource: 'Διαγραφή Resource',
    delete_resource_confirm: 'Θέλετε να διαγράψετε το Resource',
    resource_deleted: 'To Resource διαγράφηκε',
    manage_licenses: 'Διαχείρηση Licenses',
    associated_licenses: 'Associated Licenses',
    addon_services: 'Addon Services',
    manage_addon_services: 'Διαχείρηση Addon Services',
    associated_addon_services: 'Associated Addon Services',
    resource_icon: 'Resource Icon',
    default_limit: 'Προεπιλεγμένο Όριο',
    license_disassociated: 'License Disassociated',
    license_associated: 'License Associated',
    addon_disassociated: 'Addon Disassociated',
    addon_associated: 'Addon Associated',
    create_license: 'Δημιουργία License',
    create_addon_service: 'Δημιουργία Addon Service',
    addon_service: 'Addon Service',
    delete_addon_service: 'Διαγραφή Addon Service',
    delete_addon_service_confirm: 'Θέλετε να διαγράψετε το Addon Service: ',
    delete_license: 'Διαγραφή License',
    delete_license_confirm: 'Θέλετε να διαγράψετε το License: ',
    select_all: 'Select All',
    system_operation_dispatched: 'System Operation Dispatched',
    required_actions: 'Υποχρεωτικές ενέργειες',
    user_activated: 'Ο χρήστης Ενεργοποιήθηκε',
    user_deactivated: 'Ο χρήστης Απενεργοποιήθηκε',
    no_instances_found: 'No Instances found',
    sort_resources: 'Ταξινόμηση Resources',
    edit_internal_note: 'Επεξεργασία Internal Note',
    select_preference: 'Επιλογή προτίμησης',
    continue_vat: 'Συνέχεια με VAT',
    continue_pylon: 'Συνέχεια με Pylon ID',
    continue_custom: 'Συνέχεια με custom',
    fill_in_info: 'Συμπλήρωση πληροφοριών',
    commercial_title: 'Commercial Title',
    irs_name: 'Όνομα ΔΟΥ',
    order_dedicated: 'Order Dedicated Server',
    server_info: 'Πληροφορίες Server',
    not_installed_yet: 'Δεν έχει εγκατασταθεί λογισμικό ακόμα.',
    installing_software: 'Το λογισμικό εγκαθίσταται',
    package_type: 'Τύπος πακέτου',
    package_type_is_missing: 'Λείπει ο τύπος πακέτου',
    overview_financial: 'Financial Επισκόπηση',
    overview_technical: 'Technical Επισκόπηση',
    overview_sales: 'Sales Επισκόπηση',
    audit_txt: 'Επιλέξτε το audit αρχείο',
    enter_emails_and_roles: 'Προσθέστε το email και το αντίστοιχο ρόλο.',
    edit_role: 'Επεξεργασία Ρόλου',
    edit_global_role: 'Επεξεργασία global Ρόλου',
    remove_from_customer: 'Remove from customer',
    customer_role: 'Customer Role',
    global_role: 'Global Role',
    server_not_ready: 'Ο server δεν είναι έτοιμος.',
    backups_unavailable:
      'Τα Backups δεν είναι προσωρινά διαθέσιμα σε αυτή την τοποθεσία.',
    disk_image_manager: 'Disk Image Manager',
    delete_disk_image: 'Διαγραφή Disk Image',
    delete_disk_image_confirm: 'Θέλετε να διαγράψετε το disk image',
    disk_image_deleted: 'Το Disk Image διαγράφηκε',
    edit_disk_image: 'Επεξεργασία Disk Image',
    cpu_tiers_updated: 'Οι βαθμίδες CPU ενημερώθηκαν επιτυχώς.',
    no_available_cpu_tiers:
      'Δεν υπάρχουν διαθέσιμες βαθμίδες CPU για προσθήκη.',
    no_available_group_options:
      'Δεν υπάρχουν διαθέσιμα group options για αυτό το vCenter.',
    settings_updated_successfully: 'Οι ρυθμίσεις ενημερώθηκαν επιτυχώς.',
    cpu_tier_created_successfully: 'Η βαθμίδα CPU δημιουργήθηκε επιτυχώς.',
    cpu_tier_updated_successfully: 'Η βαθμίδα CPU ενημερώθηκε επιτυχώς.',
    disk_type_created_successfully: 'Ο Τύπος Δίσκου δημιουργήθηκε επιτυχώς.',
    disk_type_updated_successfully: 'Ο Τύπος Δίσκου ενημερώθηκε επιτυχώς.',
    disk_types_sorted_successfully: 'Οι Τύποι Δίσκων ταξινομήθηκαν επιτυχώς',
    delete_disk_type: 'Διαγραφή Τύπου Δίσκου',
    delete_disk_type_confirm: 'Θέλετε να διαγράψετε τον Τύπο Δίσκου ',
    disk_type_deleted_successfully: 'Ο Τύπος Δίσκου διαγράφηκε επιτυχώς.',
    export_customer_instances: 'Εξαγωγή πληροφοριών πελάτη (.xlsx)',
    empty_notes: 'Δεν υπάρχουν σημειώσεις.',
    empty_name_servers: 'Δεν υπάρχουν name servers.',
    server_no_licenses: 'Δεν υπάρχουν ενεργά licenses.',
    server_no_addon_services: 'Δεν υπάρχουν ενεργά addon services.',
    customer_flags_updated: 'Τα Flags του πελάτη ενημερώθηκαν επιτυχώς.',
    feature_flags_updated: 'Τα Feature Flags ενημερώθηκαν επιτυχώς.',
    server_no_ips: 'Δεν υπάρχουν συνδεδεμένες ip.',
    clone_server_confirm: 'Θέλετε να κλωνοποιήσετε τον server',
    are_you_sure_clone_server: 'Are you sure you want to clone server',
    server_cloned: 'Server Cloning Dispatched',
    add_dns: 'Προσθήκη DNS',
    add_record: 'Προσθήκη Record',
    create_dns_zone: 'Δημιουργία DNS Zone',
    create_dns_record: 'Δημιουργία DNS Zone Record',
    delete_dns_zone: 'Διαγραφή DNS Zone',
    delete_dns_zone_confirm: 'Θέλετε να διαγράψετε το DNS Zone',
    dns_zone_deletion_dispatched: 'Εκκίνηση διαγραφής DNS Zone',
    dns_zone_deleted: 'Το DNS Zone διαγράφτηκε',
    confirm_dns_zone_name: 'Παρακαλούμε επιβεβαιώστε το όνομα του DNS Zone',
    records_overview: 'Επισκόπηση Records',
    records_imported_successfully: 'Όλες οι εγγραφές εισήχθησαν με επιτυχία!',
    import_dns_record: 'Εισαγωγή DNS Record(s)',
    create_with_file: 'Δημιουργία με εισαγωγή αρχείου',
    delete_dns_zone_record: 'Διαγραφή DNS Zone Record',
    delete_dns_zone_record_confirm: 'Θέλετε να διαγράψετε το Record',
    dns_zone_record_deleted: 'To Zone Record διαγράφτηκε',
    user_notifications_updated: 'Οι ειδοποιήσεις του χρήστη ενημερώθηκαν επιτυχώς.',
    router_deleted: 'Ο Router διαγράφτηκε',
    vpnc_deleted: 'Ο VPN Concentrator διαγράφτηκε',
    add_dns_cluster: 'Προσθήκη DNS Cluster',
    dns_cluster_created: 'Το DNS Cluster δημιουργήθηκε επιτυχώς',
    dns_cluster_updated: 'Το DNS Cluster ενημερώθηκε επιτυχώς',
    delete_dns_cluster: 'Διαγραφή DNS Cluster',
    delete_dns_cluster_confirm: 'Θέλετε να διαγράψετε τον DNS Cluster ',
    dns_cluster_deleted_successfully: 'Ο DNS Cluster διαγράφηκε επιτυχώς.',
    add_dns_server: 'Προσθήκη DNS Server',
    dns_server_created: 'Ο DNS Server δημιουργήθηκε επιτυχώς',
    dns_server_updated: 'Ο DNS Server ενημερώθηκε επιτυχώς',
    delete_dns_server: 'Διαγραφή DNS Server',
    delete_dns_server_confirm: 'Θέλετε να διαγράψετε τον DNS Server ',
    dns_server_deleted_successfully: 'Ο DNS Server διαγράφηκε επιτυχώς.',
    add_users: 'Προσθήκη χρηστών',
    vcenter_updated: 'Το vCenter ενημερώθηκε επιτυχώς',
    dns_zone_fatal_error_message: `Το DNS Zone
    απενεργοποιήθηκε λόγω σφάλματος κατά την τελευταία διεργασία. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {brandSupportLink} για διευθέτηση του ζητήματος.`,
    dns_zone_disabled_error_message: `
    Το DNS Zone είναι προσωρινά μη διαθέσιμο. Παρακαλούμε επικοινωνήστε μαζί μας στη διεύθυνση {brandSupportLink} για διευθέτηση του ζητήματος.`,
    generated_names: 'Αυτόματη δημιουργία ονομάτων (π.χ. "Όνομα" for Όνομα1, Όνομα2..)',
    user_count: 'Αριθμός χρηστών',
    create_single: 'Δημιουργία ενός χρήστη',
    create_multiple: 'Δημιουργία πολλαπλών χρηστών',
    add_bucket: 'Προσθήκη Bucket',
    buckets_not_enough: 'Δεν επαρκούν οι διαθέσιμοι πόροι bucket',
    domain_is_missing: 'Λείπει το domain',
    application_aware: 'Application-Aware Processing',
    restored_successfully: 'Τo στοιχείo αποκαταστάθηκαν με επιτυχία.',
    restore_item: 'Επαναφορά στοιχείου',
    confirm_restore_item: 'Θέλετε σίγουρα να επαναφέρετε αυτό το στοιχείο; Τα δεδομένα σας θα αντικατασταθούν και οι αλλαγές ενδέχεται να χαθούν μετά τις',
    delete_object_bucket: 'Διαγραφή Object Bucket',
    delete_object_bucket_confirm: 'Θέλετε να διαγραφεί το Object Bucket',
    object_bucket_deletion_dispatched: 'Εκκίνηση διαγραφής Object Bucket',
    object_bucket_deleted: 'Το Object Bucket διαγράφηκε',
    confirm_object_bucket_name: 'Παρακαλούμε επιβεβαιώστε το όνομα του Bucket name',
    verification_name_does_not_match_object_bucket:
    'Το όνομα επιβεβαίωσης δεν ταιριάζει με το όνομα του Object Bucket',
    object_bucket_created: 'Εκκίνηση Δημιουργίας Object Bucket',
    create_folder: 'Δημιουργία φακέλου',
    privacy_updated: 'Η Ιδιωτικότητα ενημερώθηκε επιτυχώς',
    billing_unavailable: 'Η χρέωση δεν είναι διαθέσιμη.',
    pdf_preview: 'Προεπισκόπηση PDF',
    pdf_download: 'Λήψη PDF',
    expire_date: 'Ημερομηνία Λήξης',
    no_payment_methods: 'Δεν υπάρχουν τρόποι πληρωμής.',
    restore_to_new_vm: 'Επαναφορά σε νέο VM instance',
    subnet_overview: 'Επισκόπηση Subnet',
    gateway_is_missing: 'Λείπει το Gateway',
    type_is_missing: 'Λείπει ο τύπος',
    datacenter_is_missing: 'Λείπει το Datacenter',
    dns_primary_is_missing: 'Λείπει το Primary DNS',
    dns_secondary_is_missing: 'Λείπει το Secondary DNS',
    datacenter_overview: 'Επισκόπηση Datacenter',
    resource_created: 'Το Resource δημιουργήθηκε επιτυχώς',
    resource_updated: 'Το Resource ενημερώθηκε επιτυχώς',
    resources_order_updated: 'Η σειρά ενημερώθηκε επιτυχώς',
    addon_service_created: 'Το Addon Service δημιουργήθηκε επιτυχώς',
    addon_service_updated: 'Το Addon Service ενημερώθηκε επιτυχώς',
    addon_service_deleted: 'Το Addon Service διαγράφηκε επιτυχώς',
    driver_is_missing: 'Λείπει ο Driver',
    host_is_missing: 'Λείπει ο Host',
    port_is_missing: 'Λείπει το Port',
    username_is_missing: 'Λείπει το Username',
    password_is_missing: 'Λείπει το Password',
    database_is_missing: 'Λείπει το Database',
    schema_is_missing: 'Λείπει το Schema',
    cluster_is_missing: 'Λείπει το Cluster',
    folder_is_missing: 'Λείπει ο Folder',
    recycle_pool_name_is_missing: 'Λείπει το Recycle Pool Name',
    version_is_missing: 'Λείπει το Version',
    sdn_type_is_missing: 'Λείπει το SDN Type',
    select_boot_type: 'Επιλέξτε Boot Type',
    boot_type_update: 'Εκκίνηση ενημέρωσης Boot Type',
    key_downloaded: 'Αυτό το κλειδί έχει ήδη ληφθεί',
    public_bucket: 'Objects are publicly readable by anonymous users.',
    private_bucket: 'Objects require key credentials to be accessed.',
    backup_servers: 'Backup Servers',
    add_backup_server: 'Προσθήκη Backup Server',
    edit_backup_server: 'Επεξεργασία Backup Server',
    delete_backup_server_confirm: 'Επιβεβαίωση Διαγραφής Backup Server',
    backup_server_created: 'Ο Backup Server δημιουργήθηκε',
    backup_server_updated: 'Ο Backup Server ενημερώθηκε',
    delete_backup_server: 'Διαγραφή Backup Server',
    backup_server_deleted: 'Ο Backup Server διαγράφηκε',
    disk_image_updated: 'Το Disk Image ενημερώθηκε',
    disk_image_created: 'Το Disk Image δημιουργήθηκε',
    location_type: 'Τύπος Τοποθεσίας',
    last_transaction_date: 'Ημερομηνία τελευταίας συναλλαγής',
    customer_transactions: 'Καρτέλα πελάτη',
    export_pdf: 'Εξαγωγή PDF',
    export_excel: 'Εξαγωγή Excel',
    reinvite: 'Εκ νέου πρόσκληση',
    resend: 'Επαναποστολή πρόσκλησης',
    reinvite_successfully: 'Η πρόσκληση στάλθηκε εκ νέου επιτυχώς.',
    resend_successfully: 'Η επαναποστολή πρόσκλησης έγινε επιτυχώς.'
  },
  graphs: {
    cpuGraph: 'Χρήση vCPU (%)',
    ramGraph: 'Χρήση RAM (GB)',
    networkGraph: 'Χρήση Δικτύου (kbps)',
    networkPackets: 'Πακέτα Δικτύου',
    diskIO: 'I/O Δίσκου (IOPS)',
    network_throughput: 'Troughput Δικτύου',
    networkTraffic: 'Κυκλοφορία Δικτύου (KB)',
    latency: 'Latency (ms)',
    dnsTotalGraph: 'Συνολικά Αιτήματα',
    dnsDomainGraph: 'Αιτήματα ανά Subdomain',
    dnsTypeGraph: 'Αιτήματα ανά τύπο'
  },
  enums: {
    tasks,
    userType: {
      USER_UPDATE: 'Ενημέρωση χρήστη',
      USER_LOGIN_SUCCESS: 'Επιτυχής είσοδος χρήστη',
      USER_LOGIN_FAILED: 'Η είσοδος χρήστη απέτυχε',
      USER_MOBILE_VERIFY: 'Επιβεβαίωση κινητού τηλεφώνου χρήστη',
      USER_SAFE_DEVICE_DELETE: 'Διαγραφή safe device χρήστη',
      TWOFA_ENABLE: 'Ενεργοποίηση Two Factor',
      TWOFA_DISABLE: 'Απενεργοποίηση Two Factor',
      TWOFA_VERIFY: 'Επιβεβαίωση Two Factor',
      USER_PHONE_RESEND_TOKEN: 'Επαναποστολή token για κινητό τηλέφωνο χρήστη',
      USER_ACTIVATED: 'Ο χρήστης ενεργοποιήθηκε',
      USER_SESSION_DISCONNECT: 'Το session του χρήστη αποσυνδέθηκε',
      USER_SAFE_DEVICE_CREATED: 'Δημιουργήθηκε συσκευή χρήστη',
      USER_PASSWORD_RESET_REQUESTED:
        'Αίτημα επαναφοράς κωδικού πρόσβασης χρήστη',
      USER_EMAIL_VERIFY: 'Επιβεβαίωση e-mail χρήστη'
    }
  },
  transactions: {
    'Είσπραξη από Πελάτη': 'Είσπραξη από Πελάτη',
    'Τιμολόγιο Παροχής Υπηρεσιών': 'Τιμολόγιο Παροχής Υπηρεσιών',
    Τιμολόγιο: 'Τιμολόγιο',
    'Πιστωτικό Τιμολόγιο Παροχής Υπηρεσιών': 'Πιστωτικό Τιμολόγιο Παροχής Υπηρεσιών',
    'Απόδειξη Παροχής Υπηρεσιών': 'Απόδειξη Παροχής Υπηρεσιών',
    'Πιστωτικό Τιμολόγιο': 'Πιστωτικό Τιμολόγιο',
    'Απόδειξη Πληρωμής': 'Απόδειξη Πληρωμής',
    'Συμψηφισμός Πελάτη-Προμηθευτών': 'Συμψηφισμός Πελάτη-Προμηθευτών',
    'Απογραφή Χρέωσης Πελάτη': 'Απογραφή Χρέωσης Πελάτη',
    'Απογραφή Πίστωσης Πελάτη': 'Απογραφή Πίστωσης Πελάτη',
    'ΔΕΛΤΙΟ ΕΠΙΣΤΡΟΦΗΣ': 'Δελτίο Επιστροφής',
    'Τιμολόγιο - Δελτίο Αποστολής': 'Τιμολόγιο - Δελτίο Αποστολής',
    Invoice: 'Τιμολόγιο',
    'Απόδειξη Επιστροφής Λιανικής Πώλησης': 'Απόδειξη Επιστροφής Λιανικής Πώλησης',
    'Credit Invoice': 'Πιστωτικό Τιμολόγιο',
    'Επιστροφή Αξιογράφων': 'Επιστροφή Αξιογράφων',
    'Πίστωση Πελάτη': 'Πίστωση Πελάτη',
    'Χρέωση Πελάτη': 'Χρέωση Πελάτη',
    'Λογαριασμός Τηλεπικοινωνιακών Τελών': 'Λογαριασμός Τηλεπικοινωνιακών Τελών',
    'Τιμολόγιο Παροχής Υπηρεσιών (Αυτοτιμολόγηση)': 'Τιμολόγιο Παροχής Υπηρεσιών (Αυτοτιμολόγηση)',
    'Απογραφή Χρέωσης Πελάτη Αντιστοιχίσεων': 'Απογραφή Χρέωσης Πελάτη Αντιστοιχίσεων',
    'Απογραφή Πίστωσης Πελάτη Αντιστοιχίσεων': 'Απογραφή Πίστωσης Πελάτη Αντιστοιχίσεων',
    'Μεταφορά Χρεωστικών Υπολοίπων Πελατών': 'Μεταφορά Χρεωστικών Υπολοίπων Πελατών',
    'Τιμολόγιο Εσόδων - Παροχής Υπηρεσιών': 'Τιμολόγιο Εσόδων - Παροχής Υπηρεσιών',
    'Συμψηφισμός Πελάτη-Χρεωστών(Πιστ.Πελ-Χρ.Χρ.)': 'Συμψηφισμός Πελάτη-Χρεωστών (Πίστωση Πελάτη - Χρέωση Χρεωστών)',
    'ΒΕΒ ΠΑΡΑΚΡ ΦΟΡΟΥ': 'Βεβαίωση Παρακράτησης Φόρου',
    'Συμψηφισμός Προμηθευτή (Χρ.Προμ -Πιστ.Πελ.)': 'Συμψηφισμός Προμηθευτή (Χρέωση Προμηθευτή - Πίστωση Πελάτη)',
    'Συμψηφισμός Πελάτη-Πιστωτών(Πιστ.Πελ-Χρ.Πιστ.)': 'Συμψηφισμός Πελάτη-Πιστωτών (Πίστωση Πελάτη - Χρέωση Πιστωτών)',
    Proforma: 'Προτιμολόγιο'
  }

}
