import {
  path,
  indexOf,
  slice,
  pipe,
  map,
  includes as inc,
  reduce,
  or,
  Path,
  filter
} from 'ramda'
import {
  CloudServerTaskType,
  TasksStatusEnum,
  // NetworkTaskType,
  FirewallTaskType,
  RouterTaskType,
  Models,
  NetworkTaskType,
  CloudDiskTaskType,
  StorageAccountTaskType,
  SystemOperationEnum
} from 'src/commons/Enums'
import moment from 'moment'
import 'moment-timezone'
import { copyToClipboard, Notify, Dark, QSelect, useQuasar } from 'quasar'
import { i18n } from 'src/boot/i18n'
import { userStore } from 'src/store/user'
import { appStore } from 'src/store/app'
import { CustomerRole, GlobalRole, Usage } from 'src/models/Types'
import { Permissions } from 'src/commons/Permissions'
import { computed } from 'vue'
import { branding } from './branding'

export const includesOperations = {
  all: Symbol('*')
}

/**
 * Used to check if @item contains @search in ANY of it's fields
 * @param {Object} item the item to look inside its @fields if they contain @search
 * @param {Array<String>} fields the fields of @item to check if they contain @search
 * @param {String} search the needle
 * @return {Boolean} if the @item contains @search in unknown of it's @fields
 */
export function includes (
  item: unknown,
  fields: Array<readonly unknown[]>,
  search: string
): boolean | unknown {
  if (!search) {
    return true
  }
  for (let index = 0; index < fields?.length; index++) {
    const field = fields[index]
    if (inc(includesOperations.all, field)) {
      const operationIndex = indexOf(includesOperations.all, field)
      const mapPath = slice(0, operationIndex, field)
      const restPath = slice(operationIndex + 1, Infinity, field)
      return pipe(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        path(mapPath),
        map(mapItem => {
          return includes(mapItem, [restPath], search)
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reduce(or, false)
      )(item)
    } else {
      const term = path((field as unknown) as Path, item)
      if (!term) {
        continue
      }
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore eslint-disable-next-line @typescript-eslint/no-unsafe-call
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        term // eslint-disable-line @typescript-eslint/no-unsafe-call
          .toString() // eslint-disable-line @typescript-eslint/no-unsafe-call
          .toLowerCase() // eslint-disable-line @typescript-eslint/no-unsafe-call
          .includes(search.toString().toLowerCase())
      ) {
        return true
      }
    }
  }
  return false
}

export function mapStatusIcon (status: string | boolean) {
  switch (status) {
    case TasksStatusEnum.COMPLETE:
      return 'mdi-check-circle'
    case TasksStatusEnum.PENDING:
      return 'mdi-clock-outline'
    case TasksStatusEnum.FAILED:
      return 'mdi-alert'
    case TasksStatusEnum.RUNNING:
      return 'mdi-loading mdi-spin'
    case TasksStatusEnum.CANCELLED:
      return 'mdi-cancel'
    case 'DONE':
      return 'mdi-arrow-down'
    case 'PENDING':
      return 'mdi-progress-clock'
    case 'TO_BE_DELETED':
      return 'mdi-delete-sweep'
    case 'FAILED':
      return 'mdi-alert'
    case true:
      return 'mdi-check'
    case false:
      return 'mdi-close'
    case 'INSTALLED':
      return 'mdi-arrow-down'
    case 'NOT INSTALLED':
      return 'mdi-alert'
    case 'up':
      return 'mdi-arrow-up-bold-circle'
    case 'down':
      return 'mdi-arrow-down-bold-circle'
    default:
      return false
  }
}

export function mapStatusColor (status: string | boolean) {
  switch (status) {
    case TasksStatusEnum.COMPLETE:
      return 'lc-green'
    case TasksStatusEnum.PENDING:
      return Dark.isActive ? 'grey-4' : 'lc-grey'
    case TasksStatusEnum.FAILED:
      return 'negative'
    case TasksStatusEnum.RUNNING:
      return Dark.isActive ? 'grey-4' : 'lc-grey'
    case TasksStatusEnum.CANCELLED:
      return Dark.isActive ? 'grey-4' : 'lc-grey'
    case 'PENDING':
      return 'warning'
    case 'TO_BE_DELETED':
      return Dark.isActive ? 'grey-4' : 'lc-grey'
    case 'FAILED':
      return 'negative'
    case false:
      return 'negative'
    case 'up':
      return 'primary'
    case 'down':
      return 'negative'
    case 'NOT INSTALLED':
      return 'negative'
    case 'INSTALLED':
      return 'primary'
    default:
      return ''
  }
}

export function mapStatusText (status: string | boolean) {
  switch (status) {
    case 'DONE':
      return i18n.global.t('words.installed') // "Installed";
    case 'PENDING':
      return i18n.global.t('words.pending_apply_activate') // "Pending Apply (Activate)";
    case 'TO_BE_DELETED':
      return i18n.global.t('words.pending_apply_delete') // "Pending Apply (Delete)";
    case 'FAILED':
      return i18n.global.t('words.failed') // "Failed";
    case true:
      return i18n.global.t('words.site_enabled') // "Site Enabled";
    case false:
      return i18n.global.t('words.site_disabled') // "Site Disabled";
    case 'up':
      return 'UP'
    case 'down':
      return 'DOWN'
    case 'NOT INSTALLED':
      return i18n.global.t('words.not_installed') // "Not Installed";
    case 'INSTALLED':
      return i18n.global.t('words.installed') // "Installed";
  }
}

export function mapTypeIcon (type: string) {
  switch (type) {
    // CloudServerTaskType
    case CloudServerTaskType.CLOUD_SERVER_CREATE:
      return 'lci-create-server'
    // case CloudServerTaskType.CLOUD_SERVER_DELETE:
    //   return "lci-delete-server"; no icon yet. to be added
    case CloudServerTaskType.CLOUD_SERVER_POWER:
      return 'lci-power-server'
    case CloudServerTaskType.CLOUD_SERVER_REINSTALL:
      return 'lci-reinstall-server'
    case CloudServerTaskType.CLOUD_SERVER_UNMOUNT:
      return 'lci-cloud-server-unmount'
    case CloudServerTaskType.CLOUD_SERVER_MOUNT:
      return 'lci-cloud-server-mount'
    case CloudServerTaskType.CLOUD_SERVER_RESCALE:
      return 'lci-rescale-server'
    case CloudServerTaskType.CLOUD_SERVER_ENABLE_VNC:
      return 'lci-cloud-server-enable-vnc'
    case CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_CREATE:
      return 'lci-create-snapshot'
    case CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_DELETE:
      return 'lci-delete-snapshot'
    case CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_RESTORE:
      return 'lci-revert-to-snapshot'
    case CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CHANGE:
      return 'lci-change-network'
    case CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CREATE:
      return 'lci-create-network-card'
    case CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_DELETE:
      return 'lci-delete-network-card'
    case CloudServerTaskType.CLOUD_SERVER_BACKUP_DISABLE:
      return 'lci-disable-server-backup'
    case CloudServerTaskType.CLOUD_SERVER_BACKUP_ENABLE:
      return 'lci-enable-backup'
    case CloudServerTaskType.CLOUD_SERVER_BACKUP_RESTORE:
      return 'lci-restore-server-backup'
    case CloudServerTaskType.CLOUD_SERVER_BACKUP_RESTORE_DISKS:
      return 'mdi-backup-restore'
    case CloudServerTaskType.CLOUD_SERVER_BACKUP_UPDATE:
      return 'mdi-update'
    case CloudServerTaskType.CLOUD_SERVER_FIREWALL_RULES_APPLY:
      return 'lci-apply-firewall-rules'
    case CloudServerTaskType.CLOUD_SERVER_FIREWALL_RULES_UNDO:
      return 'lci-undo-firewall-rules'
    case CloudServerTaskType.CLOUD_SERVER_IMPORT:
      return 'lci-import'
    // FirewallTaskType
    case FirewallTaskType.APPLY_CLOUDSERVER_FIREWALL_RULES:
      return 'lci-apply-firewall-rules'
    case FirewallTaskType.UNDO_CLOUDSERVER_FIREWALL_RULES:
      return 'lci-undo-firewall-rules'
    // RouterTaskType
    case RouterTaskType.ROUTER_CREATE:
      return 'lci-create-router'
    case RouterTaskType.ROUTER_DELETE:
      return 'lci-delete-router'
    case RouterTaskType.ROUTER_FIREWALL_APPLY:
      return 'lci-apply-firewall-rules'
    case RouterTaskType.ROUTER_FIREWALL_UNDO:
      return 'lci-undo-firewall-rules'
    case RouterTaskType.ROUTER_IPSEC_APPLY:
      return 'lci-router-ipsec-apply'
    case RouterTaskType.ROUTER_IPSEC_UNDO:
      return 'lci-router-ipsec-undo'
    case RouterTaskType.ROUTER_LOAD_BALANCER_CREATE:
      return 'lci-create-load-balancer'
    case RouterTaskType.ROUTER_LOAD_BALANCER_DELETE:
      return 'lci-delete-load-balancer'
    case RouterTaskType.ROUTER_LOAD_BALANCER_EDIT:
      return 'lci-edit-load-balancer'
    case RouterTaskType.ROUTER_LOAD_BALANCER_EDIT_MEMBERS:
      return 'lci-edit-load-balancer-members'
    case RouterTaskType.ROUTER_ADD_NETWORK_CARD:
      return 'lci-create-network-card'
    case RouterTaskType.ROUTER_NETWORK_CARD_DELETE:
      return 'lci-delete-network-card'
    case RouterTaskType.ROUTER_NETWORK_CARD_UPDATE:
      return 'lci-change-network'
    case RouterTaskType.ROUTER_STATIC_ROUTE_APPLY:
      return 'lci-router-static-route-apply'
    case RouterTaskType.ROUTER_STATIC_ROUTE_UNDO:
      return 'lci-router-static-route-undo'
    case RouterTaskType.ROUTER_ADD_CERTIFICATE:
      return 'lci-add-certificate'
    case RouterTaskType.ROUTER_DELETE_CERTIFICATE:
      return 'lci-delete-certificate'
    case CloudServerTaskType.CLOUD_SERVER_ENABLE_RESCUE_MODE:
      return 'mdi-lifebuoy'
    case CloudServerTaskType.CLOUD_SERVER_DISABLE_RESCUE_MODE:
      return 'mdi-lifebuoy'
    case NetworkTaskType.PRIVATE_NETWORK_CREATE:
      return 'mdi-plus-network-outline'
    case CloudServerTaskType.CLOUD_SERVER_DELETE:
      return 'mdi-server-off'
    case CloudDiskTaskType.CLOUD_SERVER_DISK_EXTEND:
      return 'mdi-expand-all-outline'
    case 'CUSTOMER_RESOURCES_SYNC':
      return 'mdi-sync'
    case 'CUSTOMER_ORDERS_SYNC':
      return 'mdi-sync'
    case StorageAccountTaskType.FTP_ACCOUNT_DELETE:
      return 'mdi-account-remove-outline'
    case StorageAccountTaskType.FTP_ACCOUNT_DISABLE:
      return 'mdi-account-cancel-outline'
    case StorageAccountTaskType.FTP_ACCOUNT_EDIT_PASSWORD:
      return 'mdi-account-edit-outline'
    default:
      return false
  }
}

export function formatTime (timestamp: string) {
  const user = userStore()
  return moment
    .utc(timestamp)
    .tz(user.timezone)
    .format('HH:mm')
}

export function formatDateTime (timestamp: string) {
  const user = userStore()
  return moment
    .utc(timestamp)
    .tz(user.timezone)
    .format('DD/MM/YYYY HH:mm (UTCZ)')
}

export function formatDate (timestamp: string) {
  if (!timestamp) return ''
  const user = userStore()
  return moment
    .utc(timestamp)
    .tz(user.timezone)
    .format('DD/MM/YYYY')
}

export function formatFromNow (timestamp: string) {
  const user = userStore()
  return moment
    .utc(timestamp)
    .tz(user.timezone)
    .fromNow()
}

export function addTimeInDate (
  formatedDateTime: string | boolean,
  timeToAdd: string
) {
  const user = userStore()
  return moment(formatedDateTime as string)
    .add(timeToAdd, 'hours')
    .tz(user.timezone)
    .utc(formatedDateTime as boolean)
    .format('DD/MM/YYYY HH:mm')
}

export function chartTooltipFormatter (
  params: Array<{
    marker: string;
    seriesName: string;
    value: number;
    axisValueLabel: string;
  }>,
  dynamicUnitCalculation?: boolean
) {
  let html = `
    <strong>${formatDateTime(params[0].axisValueLabel)}</strong>
  `

  const formatKiloBytes = (kiloBytes: number) => {
    if (!+kiloBytes || kiloBytes === 0) return '0'
    const k = 1024
    const sizes = ['KB', 'MB', 'GB']
    const i = Math.min(Math.floor(Math.log(kiloBytes) / Math.log(k)), sizes.length - 1)
    const formattedValue = parseFloat((kiloBytes / Math.pow(k, i)).toFixed(2))
    return kiloBytes === 0 ? `${formattedValue}` : `${formattedValue} ${sizes[i]}`
  }

  const formatValue = (value: number) => {
    if (dynamicUnitCalculation) {
      const formatedValue = formatKiloBytes(value)
      return formatedValue
    } else {
      return value.toFixed(2)
    }
  }

  params.forEach(param => {
    html += `
    <div>
      ${param.marker}
      <strong>${param.seriesName}</strong>:
      ${formatValue(param.value)}
    </div>
    `
  })
  return html
}

export function mapModelIcon (model: string) {
  switch (model) {
    case Models.CLOUD_SERVER:
      return 'lci-cloud-server'
    case Models.DISK:
      return 'lci-cloud-disk'
    case Models.NETWORK:
      return 'lci-private-network'
    case Models.IP_ADDRESS:
      return 'lci-ip-address'
    case Models.ROUTER:
      return 'lci-router'
    case Models.VMWARE_VM:
      return 'lci-import'
    case Models.VPN_CONCENTRATOR:
      return 'lci-vpn'
    case Models.CUSTOMER:
      return 'mdi-domain'
    case Models.USER:
      return 'mdi-account'
    // case Models.SYSTEM_OPERATIONS:
    //   return "mdi-sync";
    default:
      return ''
  }
}

export function mapModelName (model: string) {
  switch (model) {
    case Models.CLOUD_SERVER:
      return 'Cloud Server'
    case Models.DEDICATED_SERVER:
      return 'Dedicated Server'
    case Models.DISK:
      return 'Disk Storage'
    case Models.NETWORK:
      return 'Private Network'
    case Models.IP_ADDRESS:
      return 'IP Address'
    case Models.ROUTER:
      return 'Router'
    case Models.VMWARE_VM:
      return 'VMWare'
    case Models.VPN_CONCENTRATOR:
      return 'VPN Concentrator'
    case Models.STORAGE_ACCOUNT:
      return 'Storage Account'
    case Models.DNS:
      return 'DNS Zone'
    case Models.OBJECT_BUCKET:
      return 'Object Bucket'
    default:
      return ''
  }
}

export function mapModelPath (model: string) {
  switch (model) {
    case 'App\\Models\\CloudServer':
      return 'Cloud Server'
    case 'App\\Models\\Network':
      return 'Private Network'
    case 'App\\Models\\Router':
      return 'Router'
    case 'App\\Models\\VpnConcentrator':
      return 'VPN Concentrator'
    case 'App\\Models\\StorageAccount':
      return 'Storage Account'
    default:
      return ''
  }
}

export function validateArray (validator: unknown, array: Array<unknown>) {
  return pipe(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    map(validator),
    reduce(
      (acc: unknown, validationResult) => {
        if (typeof validationResult === 'string') {
          const tempText = typeof acc === 'string' ? acc + ', ' : ''
          return tempText + ' ' + validationResult
        }
        return acc
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    )
  )(array) as boolean
}

export function dynamicResourcesCalculate (resources: Usage[]) {
  const instances = [] as Usage[]
  const totals = [] as Usage[]
  // const returnValue = null;

  resources.forEach(resource => {
    if (resource && resource.limit != null) {
      if (resource.instance_limit) {
        instances.push(resource)
      } else {
        totals.push(resource)
      }
    }
  })

  if (!instances?.length && !totals?.length) {
    return null
  }

  // returnValue =
  //   (totals?.length &&
  //     totals.reduce(function(prev, curr) {
  //       return prev.remaining < curr.remaining ? prev : curr;
  //     })) ||
  //   null;

  let totalMin = totals[0]
  for (const total of totals) {
    if (total.remaining < totalMin.remaining) totalMin = total
  }
  // returnValue = totalMin;

  // returnValue =
  //   instances.reduce(function(prev, curr) {
  //     return prev.remaining < curr.remaining ? prev : curr;
  //   }) || null;

  let instanceMin = instances[0]
  for (const instance of instances) {
    if (instance.remaining < instanceMin.remaining) instanceMin = instance
  }
  // returnValue = instanceMin;

  // if (!returnValue) {
  //   return null;
  // }

  if (!totalMin) {
    return instanceMin
  } else if (!instanceMin) {
    return totalMin
  } else if (totalMin.remaining < 0) {
    return 0
  } else {
    return totalMin.remaining < instanceMin.remaining ? totalMin : instanceMin
  }
}

export function calculateResources (resources: Usage[], current: number, fallbackLimit?: number) {
  // In case an object is null/undefined, we remove it and keep the actual data
  const filteredResources = resources.filter(resource => resource !== null && resource !== undefined && typeof resource === 'object')
  // We also remove the objects that has null remaining
  const existingResources = filteredResources.filter(resource => resource?.remaining !== null)
  let minLimit: Usage | undefined
  // If after these modifications there are still resources, we find the minimum limit
  if (existingResources.length > 0) {
    minLimit = existingResources.reduce((min, currentResource) =>
      currentResource?.remaining < min?.remaining ? currentResource : min
    )
    // If current > 0 means we are rescaling the resource
    if (current > 0) {
      if (minLimit?.instance_limit) {
        return minLimit?.remaining
      } else {
        if (minLimit?.remaining < 0) {
          return current
        } else {
          return minLimit?.remaining + current
        }
      }
    }
  }
  // if minimum limit exists, return remaining, if not, return fallback limit
  return minLimit ? minLimit.remaining : fallbackLimit
}

export function dynamicResourcesRescaleCalculateMaxValue (
  resources: Usage[],
  current: number,
  defaultValue: number
) {
  const calculated = dynamicResourcesCalculate(resources)
  if (calculated === 0) {
    return calculated
  }
  return calculated
    ? calculated.instance_limit
      ? calculated.remaining
      : calculated.remaining < 0
        ? current
        : calculated.remaining + current
    : defaultValue
}

export function calculateResourceAllowed (
  totalResource: Usage,
  instanceResource: Usage
) {
  // TODO REFACTOR
  if (totalResource && instanceResource) {
    if (
      (totalResource as { limit: number; remaining: number }).remaining ===
        null &&
      (instanceResource as { limit: number; remaining: number }).remaining !==
        null
    ) {
      return instanceResource
    } else if (
      (totalResource as { limit: number; remaining: number }).remaining !==
        null &&
      (instanceResource as { limit: number; remaining: number }).remaining ===
        null
    ) {
      return totalResource
    } else if (
      (totalResource as { limit: number; remaining: number }).remaining >
      (instanceResource as { limit: number; remaining: number }).remaining
    ) {
      return instanceResource
    } else {
      return totalResource
    }
  } else if (!!totalResource && !!instanceResource == undefined) {
    return totalResource
  } else if (!!totalResource === undefined && !!instanceResource) {
    return instanceResource
  }

  return undefined
}

export function mapResourcesName (
  resourceName: string,
  resources: Array<{
    name?: string;
    display_name?: string;
    type?: string;
  }>
) {
  // modify everywhere
  return resourceName && resources
    ? resources.find(val => val.name == resourceName)?.display_name
    : ''
}

export function mapResourcesNameForProducts (resourceName: string) {
  switch (resourceName) {
    case 'INSTANCE_DISK_SIZE':
      return 'DISK SIZE'
    case 'INSTANCE_RAM':
      return 'RAM'
    case 'INSTANCE_VCPU':
      return 'CPU'
    case 'INSTANCE_SNAPSHOTS':
      return 'SNAPSHOTS'
    case 'INSTANCE_SNAPSHOTS_HOURS':
      return 'SNAPSHOTS HOURS'
    case 'CLOUD_SERVER_INSTANCE_BACKUPS':
      return '7 DAYS BACKUPS'
    case 'CLOUD_SERVER_INSTANCE_BACKUPS_TWO_DAYS':
      return '2 DAYS BACKUPS'
    case 'CLOUD_SERVER_INSTANCE_BACKUPS_THIRTY_DAYS':
      return '30 DAYS BACKUPS'
    case 'CLOUD_SERVER_INSTANCE_FIREWALL_RULES':
      return 'INSTANCE FIREWALL'
    case 'STORAGE_ACCOUNT_INSTANCE_SIZE':
      return 'STORAGE ACCOUNT'
    case 'INSTANCE_DISK_TYPE_LCSS_SIZE':
      return 'DISK TYPE LCSS SIZE'
    case 'INSTANCE_DISK_TYPE_LCBS_SIZE':
      return 'DISK TYPE LCBS SIZE'
    case 'INSTANCE_DISK_TYPE_LCPS_SIZE':
      return 'DISK TYPE LCPS SIZE'
    case 'INSTANCE_DISK_TYPE_LCUS_SIZE':
      return 'DISK TYPE LCUS SIZE'
    case 'INSTANCE_DISK_TYPE_LCUSNVME_SIZE':
      return 'DISK TYPE LCUSNVME SIZE'
    case 'INSTANCE_DISK_TYPE_LCES_SIZE':
      return 'DISK TYPE LCES SIZE'
    case 'INSTANCE_DISK_TYPE_SSDT1_SIZE':
      return 'DISK TYPE SSDT1 SIZE'
    case 'INSTANCE_DISK_TYPE_SSDT2_SIZE':
      return 'DISK TYPE SSDT2 SIZE'
    case 'INSTANCE_IP_ADDRESS':
      return 'IP ADDRESS'
    case 'ROUTER_INSTANCE_APPLIANCE_COMPACT':
      return 'ROUTER APPLIANCE COMPACT'
    case 'ROUTER_INSTANCE_APPLIANCE_LARGE':
      return 'ROUTER APPLIANCE LARGE'
    case 'ROUTER_INSTANCE_APPLIANCE_XLARGE':
      return 'ROUTER APPLIANCE XLARGE'
    case 'ROUTER_INSTANCE_APPLIANCE_QUADLARGE':
      return 'ROUTER APPLIANCE QUADLARGE'
    case 'ROUTER_INSTANCE_IPSEC':
      return 'IP SEC'
    case 'ROUTER_INSTANCE_LOAD_BALANCER':
      return 'LOAD BALANCER'
    case 'INSTANCE_TRAFFIC_IN':
      return 'TRAFFIC IN TB'
    case 'INSTANCE_TRAFFIC_OUT':
      return 'TRAFFIC OUT TB'
    case 'VPN_CONCENTRATOR_INSTANCE_BASE':
      return 'VPN CONCENTRATOR SERVICE'
    case 'VPN_CONCENTRATOR_INSTANCE_USER':
      return 'VPN CONCENTRATOR USER'
    case 'LICENSE_INSTANCE_WINDOWS_2019':
      return 'WINDOWS SERVER 2019 LICENSE'
    case 'LICENSE_INSTANCE_WINDOWS_2016':
      return 'WINDOWS SERVER 2016 LICENSE'
    case 'LICENSE_INSTANCE_WINDOWS_2012':
      return 'WINDOWS SERVER 2012 LICENSE'
    case 'LICENSE_INSTANCE_WINDOWS_2022':
      return 'WINDOWS SERVER 2022 LICENSE'
    case 'INSTANCE_WINDOWS_LICENSE':
      return 'WINDOWS LICENSE'
    default:
      return ''
  }
}

export const resourcesOptionsForProducts = [
  'INSTANCE_VCPU',
  'INSTANCE_RAM',
  'INSTANCE_DISK_SIZE',
  'INSTANCE_SNAPSHOTS',
  'INSTANCE_SNAPSHOTS_HOURS',
  'CLOUD_SERVER_INSTANCE_BACKUPS',
  'CLOUD_SERVER_INSTANCE_BACKUPS_TWO_DAYS',
  'CLOUD_SERVER_INSTANCE_BACKUPS_THIRTY_DAYS',
  'CLOUD_SERVER_INSTANCE_FIREWALL_RULES',
  'STORAGE_ACCOUNT_INSTANCE_SIZE',
  'INSTANCE_DISK_TYPE_LCSS_SIZE',
  'INSTANCE_DISK_TYPE_LCUS_SIZE',
  'INSTANCE_DISK_TYPE_LCPS_SIZE',
  'INSTANCE_DISK_TYPE_LCBS_SIZE',
  'INSTANCE_DISK_TYPE_LCUSNVME_SIZE',
  'INSTANCE_DISK_TYPE_SSDT1_SIZE',
  'INSTANCE_DISK_TYPE_SSDT2_SIZE',
  'INSTANCE_DISK_TYPE_LCES_SIZE',
  'INSTANCE_IP_ADDRESS',
  'INSTANCE_TRAFFIC_IN',
  'INSTANCE_TRAFFIC_OUT',
  'INSTANCE_WINDOWS_LICENSE',
  'VPN_CONCENTRATOR_INSTANCE_BASE',
  'VPN_CONCENTRATOR_INSTANCE_USER',
  'LICENSE_INSTANCE_WINDOWS_2019',
  'LICENSE_INSTANCE_WINDOWS_2016',
  'LICENSE_INSTANCE_WINDOWS_2012',
  'LICENSE_INSTANCE_WINDOWS_2022',
  'ROUTER_INSTANCE_IPSEC',
  'ROUTER_INSTANCE_LOAD_BALANCER'
]

export const resourcesOptions = [
  'VCPU_TOTAL',
  'INSTANCE_VCPU',
  'RAM_TOTAL',
  'INSTANCE_RAM',
  'DISK_TOTAL_SIZE',
  'INSTANCE_DISK_SIZE',
  'DISK_TYPE_LCSS_TOTAL',
  'DISK_TYPE_LCPS_TOTAL',
  'DISK_TYPE_LCBS_TOTAL',
  'DISK_TYPE_LCUS_TOTAL',
  'DISK_TYPE_LCES_TOTAL',
  'DISK_TYPE_LCUSNVME_TOTAL',
  'DISK_TYPE_SSDT1_TOTAL',
  'DISK_TYPE_SSDT2_TOTAL',
  'SNAPSHOTS_TOTAL',
  'INSTANCE_SNAPSHOTS',
  'INSTANCE_SNAPSHOTS_HOURS',
  'ROUTER_TOTAL',
  'IP_ADDRESS_TOTAL',
  'PRIVATE_NETWORKS_TOTAL',
  'CLOUD_SERVER_TOTAL',
  'CLOUD_SERVER_DISKS_TOTAL',
  'CLOUD_SERVER_BACKUPS_TOTAL',
  'CLOUD_SERVER_INSTANCE_BACKUPS',
  'CLOUD_SERVER_FIREWALL_RULES_TOTAL',
  'CLOUD_SERVER_INSTANCE_FIREWALL_RULES',
  'STORAGE_ACCOUNT_INSTANCE_SIZE',
  'STORAGE_ACCOUNT_SIZE_TOTAL',
  'STORAGE_ACCOUNT_TOTAL',
  'INSTANCE_DISK_TYPE_LCSS_SIZE',
  'INSTANCE_DISK_TYPE_LCUS_SIZE',
  'INSTANCE_DISK_TYPE_LCPS_SIZE',
  'INSTANCE_DISK_TYPE_LCBS_SIZE',
  'INSTANCE_DISK_TYPE_LCUSNVME_SIZE',
  'INSTANCE_DISK_TYPE_SSDT1_SIZE',
  'INSTANCE_DISK_TYPE_SSDT2_SIZE',
  'INSTANCE_DISK_TYPE_LCES_SIZE',
  'INSTANCE_IP_ADDRESS',
  'ROUTER_INSTANCE_APPLIANCE_COMPACT',
  'ROUTER_INSTANCE_APPLIANCE_LARGE',
  'ROUTER_INSTANCE_APPLIANCE_XLARGE',
  'ROUTER_INSTANCE_APPLIANCE_QUADLARGE',
  'ROUTER_INSTANCE_IPSEC',
  'ROUTER_INSTANCE_LOAD_BALANCER',
  'VPN_CONCENTRATOR_TOTAL',
  'VPN_CONCENTRATOR_USERS_TOTAL',
  'VPN_CONCENTRATOR_INSTANCE_BASE',
  'VPN_CONCENTRATOR_INSTANCE_USER',
  'LICENSE_INSTANCE_WINDOWS_2019',
  'LICENSE_INSTANCE_WINDOWS_2016',
  'LICENSE_INSTANCE_WINDOWS_2012',
  'LICENSE_INSTANCE_WINDOWS_2022'
]

export const formatBytes = (bytes: number, decimals?: number) => {
  if (bytes == 0) return '0 Bytes'

  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toString() + ' ' + sizes[i]
  )
}

export const copyToClipBoard = (text: string) => {
  copyToClipboard(text)
    .then(() => {
      Notify.create({
        message: `${i18n.global.t('texts.copied_to_clipboard')}`
      })
    })
    .catch(() => {
      Notify.create({
        type: 'negative',
        message: `${i18n.global.t('texts.not_copied_to_clipboard')}`
      })
    })
}

export const mapTypeToPage = (type: string) => {
  switch (type) {
    case Models.CLOUD_SERVER:
      return 'servers'
    case Models.ROUTER:
      return 'routers'
    case Models.VPN_CONCENTRATOR:
      return 'vpn-concentrator'
    case Models.STORAGE_ACCOUNT:
      return 'storage-account'
  }
}

/**
 * This function will check if the given roles exists in the current user object and if so returns false denying access
 * @param blockedRoles All the roles that we have to block.
 * @returns *false* if there are matching given roles with the user's roles. *true* otherwise
 */
// export const checkAccess = (
//   blockedRoles: (CustomerRole | GlobalRole)[],
//   currCustomer?: string
// ) => {
//   const User = userStore();
//   const user = computed(() => User.getUser);

//   if (user.value.role != 'USER') {
//     //if the role is not USER we check for global role
//     return !blockedRoles.includes(user.value.role);
//   } else {
//     let role;
//     if (currCustomer) {
//       role = user.value.customers.find(customer => customer.id == currCustomer)
//         ?.role;
//     } else {
//       //if the role is USER we check for customer role
//       const customerId = User.getCustomerId;
//       role = user.value.customers.find(customer => customer.id == customerId)
//         ?.role;
//     }

//     return (role && !blockedRoles.includes(role)) || false;
//   }
// };

export const mapRoles = (val: GlobalRole | CustomerRole | string) => {
  switch (val) {
    case 'CUSTOMER_FINANCIAL':
      return 'Customer Financial'
    case 'CUSTOMER_MANAGER':
      return 'Customer Manager'
    case 'CUSTOMER_READONLY':
      return 'Customer Readonly'
    case 'CUSTOMER_TECHNICAL':
      return 'Customer Technical'
    case 'FINANCIAL_ADMIN':
      return 'Financial Admin'
    case 'SALES_ADMIN':
      return 'Sales Admin'
    case 'SUPER_ADMIN':
      return 'Super Admin'
    case 'TECHNICAL_ADMIN':
      return 'Technical Admin'
    case 'TECHNICAL_NETWORK_SUPPORT':
      return 'Technical Network Support'
    case 'TECHNICAL_SUPPORT':
      return 'Technical Support'
    case 'TECHNICAL_TRAINEE':
      return 'Technical Trainee'
    case 'USER':
      return 'User'
    case 'INVALID_ROLE':
      return 'Invalid Role'
    default:
      return val
  }
}

export function filterFn (
  val: string,
  update: (callbackFn: () => void, afterFn?: (ref: QSelect) => void) => void,
  abortFn: () => void,
  model?: Array<unknown>
) {
  let filteredModel
  if (val === '' || val === undefined) {
    update(() => {
      filteredModel = model
    })
    return filteredModel
  }

  update(() => {
    const needle = val?.toLowerCase()
    filteredModel =
      model &&
      filter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        m => m.name?.toLowerCase().indexOf(needle) > -1,

        model
      )
  })
  return filteredModel
}

export const specialCharCodesForVNC = [
  41,
  33,
  64,
  35,
  36,
  37,
  94,
  38,
  42,
  40,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  58,
  43,
  60,
  95,
  62,
  63,
  126,
  123,
  124,
  125,
  34
]

// Match at least one of the permissions specified, using selected customer + global permissions
export const checkPermissions = (
  keys: (keyof typeof Permissions)[],
  currCustomer?: string
) => {
  const User = userStore()
  const user = computed(() => User.getUser)
  const customerId = computed(() => User.getCustomerId)

  const globalPermissions = user.value.global_permissions
  if (globalPermissions && keys.some(key => globalPermissions[key])) {
    return true
  }

  const permissions = currCustomer ? user.value.permissions[Number(currCustomer)] : user.value.permissions[Number(customerId.value)]
  if (permissions) {
    return keys.some(key => permissions[key])
  }
  return false
}

export const checkUserPermissions = (
  managementKey: (keyof typeof Permissions),
  userKey: (keyof typeof Permissions),
  currUser?: string
) => {
  const User = userStore()
  const user = computed(() => User.getUser)
  const userId = user.value.id
  const globalPermissions = user.value.global_permissions

  const managerAccess = globalPermissions[managementKey]
  const userAccess = globalPermissions[userKey]

  let hasAccess
  if (!managerAccess && currUser) {
    if (currUser == userId) {
      hasAccess = userAccess
    } else {
      hasAccess = false
    }
  } else {
    hasAccess = userAccess || managerAccess
  }

  return hasAccess
}

const symbols = '!@#$%^&*()-_+=[]{}|;:,.<>?~`\\\''
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const lowercase = 'abcdefghijklmnopqrstuvwxyz'
const numbers = '0123456789'

const getRandomCharacter = (characters: string) => {
  return characters[Math.floor(Math.random() * characters.length)]
}

export const passwordGenerator = (options: {length?: number, uppercase?: boolean, lowercase?: boolean, numbers?: boolean, symbols?: boolean}) => {
  const allCharacters = symbols + uppercase + lowercase + numbers
  let password = ''
  const length = options.length || 10
  const hasLowercase = options.lowercase || true

  options.symbols && (password += getRandomCharacter(symbols))
  options.uppercase && (password += getRandomCharacter(uppercase))
  hasLowercase && (password += getRandomCharacter(lowercase))
  options.numbers && (password += getRandomCharacter(numbers))

  for (let i = password.length; i < length; i++) {
    password += getRandomCharacter(allCharacters)
  }

  password = password.split('').sort(() => 0.5 - Math.random()).join('')

  return password
}

export const setBrowserTabTitle = (title?: string) => {
  const brandName = branding.value?.BRAND_NAME || 'Panel'
  document.title = title ? `${brandName} - ${title}` : brandName
}

export const highlightedLog = (message: string | number, styles: string[]) => {
  const style = styles.join(';')

  // 3. Using the styles and message variable
  console.log('%c%s', style, message)
}

export const pixelsToTrim = () => {
  const q = useQuasar()
  const app = appStore()
  const alerts = app.alerts
  if (alerts && alerts.length > 0) {
    return q.screen.lt.md ? 133 : 0
  } else {
    return q.screen.lt.md ? 79 : 0
  }
}

export const setElementTheme = () => {
  const q = useQuasar()
  return q.dark.isActive ? 'bg-dark-element' : 'bg-white text-[#424242]'
}

export const parseUrl = (url: string) => {
  const [path, queryString] = url.split('?')

  let tab = null
  if (queryString) {
    const params = new URLSearchParams(queryString)
    tab = params.get('tab')
  }

  return { path, tab }
}

export const humanReadable = (input: string) => {
  return input
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/, char => char.toUpperCase())
}

export const mapSystemOperationFn = (operationType: (keyof typeof SystemOperationEnum)) => {
  switch (operationType) {
    case SystemOperationEnum.CUSTOMER_ORDERS_SYNC:
      return 'ordersSync'
    case SystemOperationEnum.CUSTOMER_RESOURCES_SYNC:
      return 'resourcesSync'
    case SystemOperationEnum.VMWARE_IMPORT_REPORT:
      return 'importReport'
    case SystemOperationEnum.VMWARE_CROSS_VCENTER_MIGRATE:
      return 'crossMigrate'
    case SystemOperationEnum.VMWARE_DVPORT_GROUP:
      return 'createDvPortGroup'
    case SystemOperationEnum.VMWARE_DVPORT_GROUP_NSXT:
      return 'createDvPortGroup'
    case SystemOperationEnum.VMWARE_NETWORK_SYNC:
      return 'networkSync'
    case SystemOperationEnum.VMWARE_MIGRATE_HOST:
      return 'hostMigrate'
    case SystemOperationEnum.VMWARE_RESOURCE_POOL_CREATE:
      return 'createResourcePool'
  }
}

const status_options = [
  {
    value: 'ACTIVE',
    label: i18n.global.t('words.active'),
    style: 'background: rgba(91, 193, 159, 0.2) !important; color: rgba(91, 193, 159) !important;'
  },
  {
    value: 'INVITED',
    label: i18n.global.t('words.invited'),
    style: 'background: rgb(59, 130, 246, 0.2) !important; color: rgb(59, 130, 246) !important;'
  },
  {
    value: 'DEACTIVATED',
    label: i18n.global.t('words.deactivated'),
    style: 'background: rgb(200,67,97, 0.2) !important; color: rgb(200,67,97) !important;'
  },
  {
    value: 'EXPIRED',
    label: i18n.global.t('words.expired'),
    style: 'background: rgb(200,67,97, 0.2) !important; color: rgb(200,67,97) !important;'
  }
]

export const mapUserStatus = (val: string) => {
  return status_options.find(status => status.value === val)
}
