import Pusher from 'pusher-js'
// we include credentials to XHR because
// the api is CORS in development

Pusher.Runtime.createXHR = function (): Ajax {
  const xhr = new XMLHttpRequest()
  xhr.withCredentials = true
  return xhr as Ajax
}
import Echo from 'laravel-echo'
import Ajax from 'pusher-js/types/src/core/http/ajax'
const echoConfig = JSON.parse(process.env.echoConfig || '')
export default new Echo(echoConfig)
