import { defineStore } from 'pinia'
import { UserProfile } from 'src/store/storeModels'
import { User } from 'src/models/Types'
import { api } from 'src/boot/axios'
import { AxiosResponse } from 'axios'

// import * as Sentry from '@sentry/browser'

export const userStore = defineStore('user', {
  state: () => ({
    user: {} as User,
    loading: 0,
    customerId: '' as string | undefined,
    initialized: false,
    userProfile: {} as UserProfile,
    users: [] as User[]
  }),
  actions: {
    setUser (user: User) {
      // Sentry.setUser(user)
      this.user = user
      this.initialized = true
    },
    setCustomerId (customerId: string) {
      localStorage.setItem(`${this.user.id}_selected_customer`, customerId)
      this.customerId = customerId
        ? customerId == 'undefined'
          ? undefined
          : customerId?.toString()
        : undefined
    },
    setUserProfile (userProfile: UserProfile) {
      this.userProfile = userProfile
    },
    setLoading () {
      this.loading += 1
    },
    stopLoading () {
      if (this.loading === 0) {
        return
      }
      this.loading -= 1
    },
    setAllUsers (users: User[]) {
      this.users = users
    },

    async loadProfile () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.get('/auth/status') as AxiosResponse<User>
        const user = response.data
        this.setUser(user)
        let lastId =
          localStorage.getItem(`${user.id}_selected_customer`) ||
          response.data.customers[0]?.id
        lastId =
          user.role === 'USER'
            ? response.data.customers
              .map(c => c.id.toString())
              .includes(lastId)
              ? lastId
              : response.data.customers[0]?.id
            : lastId
        this.setCustomerId(lastId?.toString())
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async loadUserProfile () {
      try {
        this.setLoading()
        const response = await api.get('/user/profile')
        this.setUserProfile(response.data)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async otherUserProfile (userId: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.get(`/user/${userId}`)
        this.setUserProfile(response.data)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async login (login: string, password: string, token: string, captchaType: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/auth/login', {
          login,
          password,
          token,
          captchaType
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async logout () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/auth/logout')
        this.setUser({} as User)
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async register (
      name: string,
      username: string,
      password: string,
      invite_token: string,
      password_confirmation: string,
      mobile_number: string,
      mobile_country: string
    ) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/activate', {
          name,
          username,
          password,
          invite_token,
          password_confirmation,
          mobile_number,
          mobile_country
        })
        this.setUser(response.data)
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async forgotPassword (email: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/password/request', { email })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async resetPassword (
      password: string,
      password_confirmation: string,
      reset_token: string
    ) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/password/reset', {
          password,
          password_confirmation,
          reset_token
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async changePassword (
      old_password: string,
      password: string,
      password_confirmation: string
    ) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/password/change', {
          old_password,
          password,
          password_confirmation
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    checkStatus () {
      return api.get('auth/check')
    },
    async enableTwoFactorAuth () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/2fa/enable')
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadUserProfile()
      }
    },
    async verifyTwoFactorToken (twofa_token: string, from_required?: boolean) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/2fa/verify', {
          twofa_token
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        if (!from_required) {
          this.loadUserProfile()
        }
      }
    },
    async disableTwoFactorAuth (twofa_token: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/2fa/disable', {
          twofa_token
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadUserProfile()
      }
    },
    async changePasswordInProfile (
      old_password: string,
      password: string,
      password_confirmation: string,
      disconnect_other_sessions: boolean,
      twofa_token: string
    ) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/password/change', {
          old_password,
          password,
          password_confirmation,
          disconnect_other_sessions,
          twofa_token
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async verifyTwoFactorTokenInLogin (twofa_token: string, remember_device: boolean) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/auth/2fa/validate', {
          twofa_token,
          remember_device
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async sendPhoneVerification () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/mobile/send')
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadProfile()
      }
    },
    async validatePhoneVerification (mobile_verification_code: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('/user/mobile/validate', {
          mobile_verification_code
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        try {
          await this.loadProfile()
        } catch (error) {
          console.error(error)
        }
      }
    },
    async deleteSession (sessionId: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.delete(`/session/${sessionId}`)
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadUserProfile()
      }
    },
    async deleteSafeDevice (safeDeviceId: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.delete(`user/safe-devices/${safeDeviceId}`)
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadUserProfile()
      }
    },
    async deleteOtherUserSafeDevice (userId: string, safeDeviceId: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.delete(`user/${userId}/safe-devices/${safeDeviceId}`)
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.otherUserProfile(userId)
      }
    },
    async saveNotifications (userId: string, notification_options: {[key: string]: boolean | null}) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post(`/user/${userId}/notifications`, { notification_options })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadProfile()
      }
    },
    async dispatchAllUsers () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.get('management/users')
        this.setAllUsers(response.data.users)
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async saveIPs (network: Array<string>, id: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post(`user/${id}/allowed-access`, { network })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.loadUserProfile()
      }
    },
    async updateInformations (
      user_id: string,
      name: string,
      email: string,
      username: string,
      mobile_country: string,
      mobile_number: string,
      language: string
    ) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post(`user/${user_id}`, {
          name,
          email,
          username,
          mobile_country,
          mobile_number,
          language
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
        this.otherUserProfile(user_id)
      }
    },
    async loadUserLogs (user_id: string, page: string) {
      try {
        this.setLoading()
        const response = await api.get(`user/${user_id}/user-logs?page=${page}`)
        return response.data
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async sendVerifyMail () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.get('user/email/send')
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async emailVerification (token: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('user/email/validate', {
          token
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async authenticatorToken () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('user/authenticator/token')
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async sendSMSCode () {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('user/mobile/send')
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    },
    async verifyMobile (mobile_verification_code: string) {
      if (this.loading) {
        return
      }
      try {
        this.setLoading()
        const response = await api.post('user/mobile/validate', {
          mobile_verification_code
        })
        return response
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.stopLoading()
      }
    }
  },
  getters: {
    isLoading (state) {
      return state.loading > 0
    },
    getUser (state) {
      return state.user
    },
    getInitialized (state) {
      return state.initialized
    },
    getCustomerId (state) {
      return state.customerId ? state.customerId.toString() : undefined
    },
    timezone (state) {
      return state.user &&
        state.user.customers &&
        state.user.customers[0] &&
        state.user.customers[0].timezone
        ? state.user.customers[0].timezone
        : 'UTC'
    },
    getUserProfile (state) {
      return state.userProfile
    },
    getAllUsers (state) {
      return state.users
    },

    hasMultipleCustomers (state) {
      return state.user.customers?.length > 1
    },
    getCustomer (state) {
      return state.user.customers?.find((val: {id: string}) => val.id == state.customerId + '')
    }
  }
})
