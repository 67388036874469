import { ref } from 'vue'
import { Branding } from 'src/models/Types'

const branding = ref<Branding>({
  BRAND_COLOR: '',
  BRAND_DOMAIN: '',
  BRAND_FAVICON: '',
  BRAND_LOGO: '',
  BRAND_LOGO_DARK: '',
  BRAND_LOGO_EMAIL: '',
  BRAND_NAME: '',
  BRAND_SALES_EMAIL: '',
  BRAND_STATUS_LINK: '',
  BRAND_SUPPORT_EMAIL: '',
  BRAND_SUPPORT_LINK: '',
  BRAND_URL: ''
})

const getFallbackFavicon = (color: string) => `
      <svg width="607" height="573" viewBox="0 0 607 573" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="47" y="31" width="512" height="129" rx="30" fill="${color}"/>
        <path d="M303 31H529C545.569 31 559 44.4315 559 61V130C559 146.569 545.569 160 529 160H303V31Z" fill="white" fill-opacity="0.2"/>
        <rect x="47" y="201" width="233" height="342" rx="30" fill="#B4BCC0"/>
        <path d="M163 201H250C266.569 201 280 214.431 280 231V513C280 529.569 266.569 543 250 543H163V201Z" fill="#CFD8DC"/>
        <rect x="326" y="201" width="233" height="147" rx="30" fill="#7D8F97"/>
        <path d="M442 201H529C545.569 201 559 214.431 559 231V318C559 334.569 545.569 348 529 348H442V201Z" fill="#90A4AE"/>
        <rect x="326" y="396" width="233" height="147" rx="30" fill="#7D8F97"/>
        <path d="M442 396H529C545.569 396 559 409.431 559 426V513C559 529.569 545.569 543 529 543H442V396Z" fill="#90A4AE"/>
      </svg>
    `

export { branding, getFallbackFavicon }
