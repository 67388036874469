import { Permissions } from 'src/commons/Permissions'
import { appStore } from 'src/store/app'
import { notificationsStore } from 'src/store/notifications'
import { userStore } from 'src/store/user'
import { AxiosError } from 'axios'
import { computed } from 'vue'
import { NavigationGuardWithThis } from 'vue-router'
import { highlightedLog } from 'src/commons/Utilities'

export const permissionGuard = (
  keys: (keyof typeof Permissions)[]
): NavigationGuardWithThis<undefined> => {
  return async (to, from, next) => {
    const User = userStore()
    const user = computed(() => User.getUser)
    const customerId = computed(() => User.getCustomerId)

    const globalPermissions = user.value.global_permissions
    if (globalPermissions && keys.some(key => globalPermissions[key])) {
      next()
    }

    const customerPermissions = user.value.permissions[Number(customerId.value)]
    if (customerPermissions && keys.some(key => customerPermissions[key])) {
      next()
    } else {
      next('/no-access')
    }
  }
}

export const appInitializationGuard: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  if (process.env.DEBUG_LOGS === 'TRUE') highlightedLog('Kanw Koumanta 🗿', ['background: #222', 'color: #bada55'])
  const User = userStore()
  await User.loadProfile()
    .then(() => {
      const notifications = notificationsStore()
      User.getCustomerId && notifications.subscribe(User.getCustomerId)
      const app = appStore()
      app.closeLoginModal()

      User.user.customers.map(customer => {
        if (
          (customer.requires_twofa || app.config.USER_REQUIRE_TWOFA) &&
          User.user.twofa_status !== 'ENABLED'
        ) {
          next('/two-factor-enable')
        }
      })
      return next()
    })
    .catch((error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        localStorage.setItem('redirectPath', to.fullPath)
        next({ path: '/login' })
      } else {
        next({ path: '/Error' })
      }
    })
}

export const customerGuard: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const User = userStore()
  const user = computed(() => User.getUser)
  const globalPermissions = user.value.global_permissions
  const customerPermissions = user.value.permissions

  const globalAccess = globalPermissions ? globalPermissions[Permissions.MANAGEMENT_CUSTOMER_VIEW] : false
  if (globalAccess) {
    next()
  }
  const customerId = to.params.id
  let customerAccess
  if (customerId && !Array.isArray(customerId)) {
    customerAccess = customerPermissions[Number(customerId)] ? customerPermissions[Number(customerId)][Permissions.CUSTOMER_VIEW] : false
  }
  if (customerAccess) {
    next()
  } else {
    next('/no-access')
  }
}

export const userGuard: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const User = userStore()
  const user = computed(() => User.getUser)
  const globalPermissions = user.value.global_permissions
  if (!globalPermissions) {
    next('/no-access')
  }
  const managerAccess = globalPermissions[Permissions.MANAGEMENT_USER_PROFILE_VIEW]
  const userAccess = globalPermissions[Permissions.USER_PROFILE_VIEW]
  if (managerAccess) {
    next()
  } else if (userAccess && user.value.id == to.params.id) {
    next()
  } else {
    next('/no-access')
  }
}
