// This is just an example,
// so you can safely delete all default props below

import tasks from './tasks'

import actions from './actions'

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  q_mark: '?',
  errorDialogs: {
    AUTH_ERROR: 'Invalid username or password'
  },
  warnings: {
    cannot_find_your_server: "Can't find your server?",
    no_servers_found_head: "You don't have any servers yet.",
    no_servers_found_desc:
      'No servers found, create one to utilize our cloud services.',
    no_disks_found_head: "You don't have any cloud disks yet.",
    no_disks_found_desc:
      'Cloud disks increase the store size of your server. Add one to your servers now!',
    no_private_networks_found_head: "You don't have any private networks yet.",
    no_public_networks_found_head: 'No public networks available.',
    no_private_networks_found_desc: `
    Create a new private network, working through a dedicated link,\n
    without public internet connectivity.
    `,
    no_ips_found_head: "You don't have any IP addresses yet.",
    no_ips_found_desc:
      'You can assign more than one IP address to the same server, allowing for more connections points.',
    no_servers_for_disk_found: 'No servers found. Please create a server.',
    no_ssh_keys_found_head: "You don't have any SSH keys yet.",
    no_ssh_keys_found_desc:
      'Using SSH Keys allows for a faster, more convenient and more secure way to log in to your server.',
    rescale_restriction_create:
      'The amount of RAM you have selected is equal or lower than 3GB. This will require shutting down your server before rescaling to more than 4GB RAM.',
    rescale_restriction_rescale:
      'Current RAM is equal or less than 3GB. Shutting down the machine  is required before increasing the RAM more than 3GB.',
    disk_resize_irreversible:
      'Extending your cloud disk is irreversible, you will not be able to shrink it back in the future.',
    unmount_before_move_disk:
      'Make sure this disk is unmounted before attempting to move it.',
    downscale:
      'Decreasing the amount of vCPU/RAM on your machine requires that it is turned off. Please, turn it off before requesting a Downscale',
    no_vpn_found_head: "You don't have any VPN yet.",
    no_vpn_found_desc: 'Create a new VPN',
    no_servers_for_load_balancer_found:
      'No servers found, create one to utilize load balancers.',
    no_load_balancer_found_head: 'No load balancer found yet.',
    no_load_balancer_found_desc:
      'Create a new load balancer to distribute your incoming traffic.',
    no_certificates_found_head: 'No certificate found yet',
    no_certificates_found_desc: 'Create a new certificate',
    no_servers_for_router_found:
      'No servers found, create one to utilize routers.',
    no_router_found_head: 'No router found yet',
    no_router_found_desc: 'Create a new router',
    default_firewall_policy: 'Default Policy: Allowed',
    no_network_found_head: 'No network found yet.',
    no_network_found_desc: 'Create a new network',
    no_backup_found_head: 'No backup found yet',
    no_backup_found_desc: 'Backups not enabled',
    no_firewall_found_head: 'No firewall found yet.',
    no_firewall_found_desc: 'Create a new firewall',
    no_snapshot_found_head: 'No snapshot found.',
    no_snapshot_found_desc: 'Create a snapshot',
    no_imports_found_head: 'No imports found',
    no_site_found_head: 'No site found yet.',
    no_site_found_desc: 'Create an site',
    no_static_route_found_head: 'No static route found yet.',
    no_static_route_found_desc: 'Create a static route',
    no_pendings_registration_found: 'No Pending Registration Found',
    no_resolved_registration_found: 'No Resolved Registration Found',
    no_storage_accounts_found_head: 'No Storage Account Found',
    no_storage_accounts_found_desc: 'Add a storage account',
    server_power_on_to_rescale: 'Server must be powered on to rescale ',
    server_power_off_to_rescale: 'Server must be powered off to rescale ',
    no_nat_found_head: 'No NAT found yet',
    no_nat_found_desc: 'Create a new NAT',
    no_statistics_found_head: 'No Statistics Found',
    no_users_found_head: 'No Users Found',
    no_users_found_desc: 'Create a user',
    no_product_pricing_found_head: 'No Product Pricing Found',
    no_pricing_override_found_head: 'No Pricing Override Found',
    no_active_order_found_head: 'No Active Order Found',
    no_inactive_order_found_head: 'No Inactive Order Found',
    no_safe_devices_found_head: 'No Safe Devices Found',
    no_logs_found_head: 'No User Logs Found',
    no_sessions_found_head: 'No Sessions Found',
    folder_is_empty: 'This folder is empty',
    no_vpn_concentrators_found_head: "You don't have any VPN concentrator yet.",
    no_vpn_concentrators_found_desc:
      'No VPN concentrator found, create one to utilize our VPN concentrator services.',
    no_profile_found_head: "You don't have any profile yet.",
    no_profile_found_desc:
      'No profile found, create one to utilize our profile services.',
    no_user_found_head: "You don't have any user yet.",
    no_user_found_desc:
      'No user found, create one to utilize our user services.',
    no_ip_pool_found_head: "You don't have any IP pools yet.",
    no_ip_pool_found_desc:
      'No IP pool found, create one to utilize our IP pool services.',
    cannot_be_deleted: 'It can not de deleted.',
    no_dedicated_server_found_head: "You don't have any Dedicated Servers yet.",
    no_dedicated_server_found_desc:
      'No Dedicated Servers found, create one to utilize our Dedicated Server services.',

    no_customer_found_head: 'You are not a member of a company.',
    no_customer_found_desc:
      "Please contact your company's administrator to invite you.",
    server_power_off_to_resize_disk:
      'Server must be Powered Off before rescaling over 2000 GB',
    no_cpu_tier_options: 'There are no CPU Tiers available for selection.',
    something_went_wrong: 'Something went wrong.',
    no_resources_group_found: 'No Resources Group Found',
    router_has_only_one_ip: 'You cannot remove all IP addresses from router.',
    no_customer_flags: 'No Customer Flags Found',
    no_feature_flags: 'No Feature Flags Found',
    no_dns_zones_found: 'No DNS Zones Found',
    no_dns_records_found: 'No DNS Zone Records Found',
    changes_on_file_content: 'There are some changes on the file content. Hit apply data to update.',
    no_records: 'There are no records yet on this zone.',
    no_notification_options: 'There are no notification settings to edit.',
    no_licenses_found_head: 'No licenses found.',
    no_licenses_found_desc:
      'Create a license',
    no_data_available: 'No data available',
    last_task_failed: 'Last task failed',
    icon_warning: 'This image may also exist in another license. Delete anyways?',
    no_buckets_found_head: 'You don\'t have any buckets yet.',
    no_buckets_found_desc: 'Object Buckets are a scalable, S3-compatible solution designed for storing large volumes of data. It ensures easy integration with applications, providing high availability, durability, and flexibility for both structured and unstructured data. Ideal for backups, content storage, and data archives.',
    no_datacenters_found_head: 'No Datacenters found',
    no_subnets_found_head: 'No Subnets found',
    no_feedback_issues_found: 'No Feedback/Issues found',
    no_help_topics_found: 'No Help Topics found',
    no_customers_found_head: 'No Customers found',
    no_resources_found_head: 'No Resources found',
    no_addon_services_found_head: 'No Addon Services found',
    no_vcenters_found: 'No vCenters found',
    no_datastores_found: 'No Datastores found',
    no_hosts_found: 'No Hosts found',
    no_imports_found: 'No Imports found',
    no_backups_found: 'No Backups found',
    no_disk_images_found: 'No Disk Images found',
    no_cpu_tiers_found: 'No CPU Tiers found',
    no_disk_types_found: 'No Disk Types found',
    validation_error: 'Incorrect input value',
    billing_payment: 'ATTENTION: You have made a payment after your balance renewal date, it may not have been accounted for yet.',
    boot_type_warning: 'Server must be Powered Off before updating Boot Type',
    key_download_warning: 'You can only download this key once',
    file_management_disabled: 'File management is not available during tasks.'
  },
  rules: {
    confirm_does_not_match: 'Password confirmation does not match password',
    password_length_short: 'Password length should be 6 or more characters',
    stronger_password: 'Password is not secure enough',
    field_is_required: 'Field is required',
    _is_not_valid_FQDN: 'is not a valid FQDN',
    _is_valid_email: 'is not a valid email',
    _is_not_valid_ip_subnet: 'is not a valid IP/Subnet',
    _is_not_valid_subnet: 'is not a valid Subnet',
    _is_not_valid_subnets: 'are not valid Subnets',
    _is_not_valid_ipv4: 'is not a valid IPV4',
    _is_not_valid_ipv6: 'is not a valid IPV6',
    _is_not_valid_domain: 'is not valid domain',
    _is_not_valid_fqdn: 'is not a valid FQDN',
    _is_not_valid_port: 'is not a valid port',
    _are_not_valid_ports: 'are not valid ports',
    _is_not_valid_url: 'is not a valid URL',
    _is_required: 'is required',
    password_at_least_twenty_chars: 'Password must be at least 20 characters',
    not_valid_subnet: 'is not a valid subnet',
    overlapping_ip: 'IP is overlapping',
    next_hop_range_uplink: "Next hop can't be in the range of network uplink",
    next_hop_same_internal: "Next hop can't be the same as an internal network",
    next_hop_same_internal_start:
      "Next hop can't be the same as an internal network first ip",
    next_hop_same_internal_end:
      "Next hop can't be the same as an internal network last ip",
    next_hop_range_internal:
      'Next hop must be in the same range as an internal network',
    _is_valid_contact_number: 'is not a valid contact number',
    invalid_confirm_pass: 'Password confirmation does not match password',
    wrong_iso_format: 'You need to mount ISO with format Universal or ',
    not_match_password: 'Password must match',
    password_must_be_stronger: 'Password must be stronger',
    _is_not_valid_ipv4_or_cidr: 'is not a valid IPV4 or IP/Subnet',
    ip_must_be_in_the_same_network: 'must be in the same network',
    _is_not_valid_subnet_mask: 'is not a valid Subnet Mask address',
    end_address_out_of_range: 'End Address is out of range',
    _is_reserved_address: 'is a reserved address',
    start_address_out_of_range: 'Start address is overlapping end address',
    ip_pool_overllaping_network_card: 'IP Pool is overlapping network card',
    ip_pool_overllaping_pool: 'IP Pool is overlapping existing pool',
    network_card_overllaping_pool: 'Network card is overlapping existing pool',
    cannot_be_same_as_tunnel: 'Next Hop can not be the same as a tunnel IP',
    network_overlaps_tunnel: 'Network card is overlapping tunnel',
    tunnel_overalps_network: 'Tunnel is overlapping network card',
    _is_not_valid_name: 'is not a valid name',
    same_port: 'This port is already being used by this Router.',
    _not_valid_mask: 'is not a valid network mask.',
    invalid_network_values: 'Invalid network values.',
    _is_not_valid_type:
      "is not a valid type. Please use only 'A-Z', '0-9' and '_'",
    same_internal_uplink_network:
      "You can't use the same network on the Internal Network and Uplink Network fields.",
    same_vcenter_uplink: "Stand By uplink can't be the same as Active uplink.",
    caa_type_flag_validation: 'Flag value must be between 0 and 255',
    caa_type_value_double_quotes: 'Value must be wrapped between double quotes',
    caa_type_value_slash: 'Inner double quotes must be escaped with \\" character sequence'
  },
  menu: {
    home: 'Home',
    CLOUD: 'CLOUD',
    servers: 'Cloud Servers',
    disks: 'Cloud Disks',
    ssh_keys: 'SSH Keys',
    ip_addresses: 'IP Addresses',
    private_networks: 'Private Networks',
    MANAGEMENT: 'MANAGEMENT',
    datacenters: 'Datacenters',
    subnets: 'Subnets',
    customers: 'Customers',
    resource_pools: 'Resource Pools',
    volumes: 'Volumes',
    SUPPORT: 'SUPPORT',
    tickets: 'Tickets',
    tasks: 'Tasks',
    vpn: 'VPN',
    feedback_issues: 'Feedback/Issues',
    load_balancer: 'Load Balancer',
    certificates: 'Certificates',
    routers: 'Routers',
    imports: 'Imports',
    customer_overrides: 'Customer Overrides',
    service_overrides: 'Service Overrides',
    products: 'Products',
    status: 'Status',
    registrations: 'Registrations',
    backups: 'Backups',
    storage_accounts: 'Storage Accounts',
    object_buckets: 'Object Buckets',
    resource_groups: 'Resources Groups',
    product_categories: 'Product Categories',
    users: 'Users',
    vpn_concentrators: 'VPN Concentrators',
    APPEARANCE: 'APPEARANCE',
    resources_management: 'Resources Management',
    DEDICATED: 'DEDICATED',
    dedicated_servers: 'Dedicated Servers',
    cpu_tiers: 'CPU Tiers',
    hosts: 'Hosts',
    datastores: 'Datastores',
    disk_types: 'Disk Types',
    settings: 'Settings'
  },
  tabs: {
    dashboard: 'Dashboard',
    servers: 'Cloud Servers',
    create_server: 'Create Server',
    disks: 'Cloud Disks',
    create_disk: 'Create Disk',
    routers: 'Routers',
    create_router: 'Create Router',
    vpn_concentrators: 'VPN Concentrators',
    create_vpn_concentrator: 'Create VPN Concentrator',
    dns_zones: 'DNS Zones',
    create_dns_zone: 'Create DNS Zone',
    ssh_keys: 'SSH Keys',
    ip_addresses: 'IP Adresses',
    add_ip: 'Add IP',
    private_networks: 'Private Networks',
    create_private_network: 'Create Private Network',
    storage_accounts: 'Storage Accounts',
    object_buckets: 'Object Buckets',
    create_object_bucket: 'Create Object Bucket',
    create_storage_account: 'Create Storage Account',
    tasks: 'Tasks',
    datacenters: 'Datacenters',
    create_datacenter: 'Create Datacenter',
    subnets: 'Subnets',
    create_subnet: 'Create Subnet',
    products: 'Products',
    product_categories: 'Product Categories',
    service_overrides: 'Service Overrides',
    customers: 'Customers',
    users: 'Users',
    feedback_issues: 'Feedback/Issues',
    help_topics: 'Help Topics',
    vcenters: 'vCenters',
    create_vcenter: 'Create vCenter',
    resource_pools: 'Resource Pools',
    hosts: 'Hosts',
    datastores: 'Datastores',
    imports: 'Imports',
    backups: 'Backups',
    backup_servers: 'Backup Servers',
    disk_image_manager: 'Disk Image Manager',
    cpu_tiers: 'CPU Tiers',
    disk_types: 'Disk Types',
    registrations: 'Registrations',
    resources_groups: 'Resources Groups',
    resources_management: 'Resources Management',
    licenses: 'Licenses',
    addon_services: 'Addon Services',
    dns_clusters: 'DNS Clusters',
    settings: 'Settings',
    changelog: 'Changelog',
    billing: 'Billing',
    ip_bandwidth: 'IP Bandwidth',
    login: 'Login',
    register: 'Register',
    reset_password: 'Reset Password',
    forgot_password: 'Forgot Password',
    demo_application: 'Demo application',
    mobile_verification_send: 'Mobile verification send',
    mobile_verification_validation: 'Mobile verification validation',
    two_factor_authentication: 'Two factor authentication',
    email_validation: 'Email validation',
    two_factor_enable: 'Two factor enable'
  },
  words: {
    options: 'Options',
    id: 'ID',
    state: 'state',
    PRIVATE: 'PRIVATE',
    PUBLIC: 'PUBLIC',
    account: 'Account',
    accounts: 'Accounts',
    associate: 'Associate',
    disassociate: 'Disassociate',
    action: 'Action',
    active: 'Active',
    deactivated: 'Deactivated',
    expired: 'Expired',
    active_tasks: 'Active Tasks',
    address: 'Address',
    advanced_settings: 'Advanced Settings',
    segments: 'Segments',
    segment: 'Segment',
    algorithm: 'Algorithm',
    algorithms: 'Algorithms',
    allow: 'Allow',
    any: 'Any',
    apply: 'Apply',
    attached_to: 'Attached to',
    backup: 'Backup',
    backups: 'Backups',
    brand: 'Brand',
    cancel: 'Cancel',
    capacity: 'Capacity',
    certificate: 'Certificate',
    certificate_contents: 'Certificate Contents',
    certificates: 'Certificates',
    clear: 'Clear',
    close: 'Close',
    cloud: 'Cloud',
    cluster: 'Cluster',
    company: 'Company',
    confirmation: 'Confirmation',
    connect: 'Connect',
    console: 'Console',
    create: 'Create',
    created_at: 'Created At',
    created_at_utc: 'Created At (UTC)',
    currency: '€',
    code: 'Code',
    customer: 'Customer',
    customers: 'Customers',
    dashboard: 'Dashboard',
    data: 'Data',
    datacenter: 'Datacenter',
    datacenters: 'Datacenters',
    datastores: 'Datastores',
    default: 'Default',
    delete: 'Delete',
    deny: 'Deny',
    description: 'Description',
    destination_port: 'Destination Port',
    disconnect: 'Disconnect',
    disk: 'Disk',
    disk_size: 'Disk Size',
    disks: 'Disks',
    domain: 'Domain',
    edit: 'Edit',
    email: 'Email',
    exit: 'Exit',
    end_port: 'End Port',
    extend: 'Extend',
    feedback: 'Feedback',
    firewall_rules: 'Firewall Rules',
    folder: 'Folder',
    free_space: 'Free Space',
    fullscreen: 'Fullscreen',
    gateway: 'Gateway',
    graphs: 'Graphs',
    healthCheck: 'Health Check',
    health_check: 'Health Check',
    help: 'Help',
    history: 'History',
    host: 'Host',
    hosts: 'Hosts',
    hour_short: 'ho',
    information: 'Information',
    internal_ip_config: 'Internal IP Config',
    interval_time: 'Interval Time',
    internal_name: 'Internal Name',
    ip: 'IP',
    ip_firewall_rules: 'IP Firewall Rules',
    ip_range: 'IP Range',
    ips: 'IPs',
    label: 'Label',
    last: 'Last',
    load_balancer: 'Load Balancer',
    loading: 'Loading',
    location: 'Location',
    login: 'Login',
    back_to_login: 'Back to Login',
    logout: 'Logout',
    memory: 'Memory',
    message: 'Message',
    meta: 'Metadata',
    month_short: 'mo',
    move: 'Move',
    name: 'Name',
    netmask: 'Netmask',
    network: 'Network',
    network_cards: 'Network Cards',
    networks: 'Networks',
    no: 'No',
    notes: 'Notes',
    old: 'Old',
    operating: 'Operating',
    order: 'Order',
    overview: 'Overview',
    password: 'Password',
    password_confirm: 'Password Confirm',
    paste: 'Paste',
    path: 'Path',
    port_group: 'Port Group',
    ports: 'Ports',
    primary_dns: 'Primary DNS',
    private: 'Private',
    private_key: 'Private Key',
    private_network: 'Private Network',
    private_network_name: 'Private Network Name',
    protocol: 'Protocol',
    provisioned: 'Provisioned',
    public: 'Public',
    public_key: 'Public Key',
    random: 'Random',
    regenerate: 'Regenerate',
    regex: 'Regex',
    release: 'Release',
    remove: 'Remove',
    report: 'Report Issue',
    rescale: 'Rescale',
    server_rescale: 'Rescale',
    reset: 'Reset',
    resource_pools: 'Resource Pools',
    response_timeout: 'Response Timeout',
    restore: 'Restore',
    result: 'Result',
    retries: 'Retries',
    retype_password: 'Retype Password',
    reverse_dns: 'Reverse DNS',
    reverse_record: 'Reverse Record',
    role: 'Role',
    global_role: 'Global Role',
    customer_role: 'Customer Role',
    router: 'Router',
    routes: 'Routes',
    save: 'Save',
    screenshot: 'Screenshot',
    search: 'Search',
    secondary_ips: 'Secondary IPs',
    secondary_dns: 'Secondary DNS',
    select_network: 'Select Network',
    semi_managed: 'Semi-Managed',
    send: 'Send',
    server: 'Server',
    server_cost: 'Server Cost',
    router_cost: 'Router Cost',
    vpnConcentrator_cost: 'Vpn Concentrator Cost',
    servers: 'Servers',
    servers_info: 'Servers Info',
    service: 'Service',
    snapshot: 'Snapshot',
    snapshots: 'Snapshots',
    source_list: 'Source List',
    source_port: 'Source Port',
    start_port: 'Start Port',
    status: 'Status',
    submit: 'Submit',
    subnets: 'Subnets',
    task: 'Task',
    tasks: 'Tasks',
    type: 'Type',
    uncommitted: 'Uncommitted',
    undo: 'Undo',
    update: 'Update',
    updated_at: 'Updated At',
    updated_at_utc: 'Updated At (UTC)',
    user: 'User',
    username: 'Username',
    users: 'Users',
    users_email: 'Users Email',
    vat: 'VAT',
    vcpu: 'vCPUs',
    version: 'Version',
    view: 'View',
    vpn: 'VPN',
    vpn_user: 'VPN Users',
    yes: 'Yes',
    changelog: 'Changelog',
    enable_backup: 'Enable Daily Backup',
    no_backups: 'No backup created yet.',
    private_network_overview: 'Private Network Overview',
    create_snapshot: 'Create Snapshot',
    issued_to: 'Issued To',
    issued_by: 'Issued By',
    expires_on: 'Expires On',
    member: 'Member',
    members: 'Members',
    port: 'Port',
    condition: 'Condition',
    server_name: 'Server Name',
    vcenter_name: 'vCenter Name',
    vm_internal_name: 'VM Internal Name',
    date: 'Date',
    import: 'Import',
    sync: 'Sync',
    field: 'Field',
    reinstall: 'Reinstall',
    download_audit: 'Download Audit',
    kind_of_ip: 'Kind of IP',
    ipsec_sites: 'IPSEC Sites',
    local: 'Local',
    remote: 'Remote',
    ike_version: 'IKE Version',
    local_endpoint: 'Local Endpoint',
    remote_endpoint: 'Remote Endpoint',
    digest_algorithm: 'Digest Algorithm',
    encryption_algorithm: 'Encryption Algorithm',
    preshared_key: 'Pre-Shared Key',
    hellman_group: 'Hellman Group',
    lifetime: 'Lifetime',
    global: 'Global',
    unassigned: 'Unassigned',
    pylon_id: 'Pylon Id',
    phase_one: 'Phase 1',
    phase_two: 'Phase 2',
    authentication: 'Authentication',
    pylon_synced_at: 'Synced At',
    encryption: 'Encryption',
    perfect_forward_secrecy: 'Perfect Forward Secrecy (PFS)',
    local_subnets: 'Local Subnets',
    remote_subnets: 'Remote Subnets',
    ipsec_site: 'IPSEC Site',
    ip_type: 'Ip Type',
    size: 'Size',
    os: 'Operating System',
    cpu: 'CPU',
    backup_specification: 'Backup Specification',
    monitoring_type: 'Monitor Protocol Type',
    health_check_status: 'Check Status',
    x_forward_for: 'X Forward For',
    monitor_port: 'Monitor Port',
    upload: 'Upload',
    disable_backup: 'Disable Backup',
    price: 'Price',
    upgrade: 'Upgrade',
    gui_element: 'GUI Element',
    gui_type: 'GUI Type',
    products: 'Products',
    product: 'Product',
    customer_overrides: 'Customer Overrides',
    customer_id: 'Customer ID',
    customer_name: 'Customer Name',
    customer_product: 'Customer Product',
    service_overrides: 'Service Overrides',
    customer_override: 'Customer Override',
    hourly: 'Hourly',
    monthly: 'Monthly',
    special_rates: 'Special Rates',
    service_type: 'Service Type',
    service_name: 'Service Name',
    product_name: 'Product Name',
    service_override: 'Service Override',
    static_routing: 'Static Route',
    next_hop: 'Next Hop',
    source_port_list: 'Source Port List',
    destination_port_list: 'Destination Port List',
    destination_list: 'Destination List',
    accept: 'Accept',
    mount: 'Mount',
    unmount: 'Unmount',
    admin_action: 'Admin Action',
    payload: 'Payload',
    firstName: 'First Name',
    lastName: 'Last Name',
    companyName: 'Company Name',
    contactNumber: 'Contact Number',
    full_name: 'Full Name',
    validation_number: 'Validation Number',
    two_factor_auth: 'Two Factor Authentication',
    enable: 'Enable',
    disable: 'Disable',
    change_password: 'Change Password',
    profile: 'Profile',
    old_password: 'Old Password',
    new_password: 'New Password',
    new_password_confirm: 'New Password Confirm',
    update_password: 'Update Password',
    verification_code: '2FA Verification Code',
    verify_code: 'Verify Code',
    logout_all_sessions: 'Log out from all sessions',
    registrations: 'Registrations',
    registration_action: 'Registration Action',
    pending_registrations: 'Pending Registrations',
    resolved_registrations: 'Resolved Registrations',
    submit_date: 'Submit Date',
    resolved_date: 'Resolved Date',
    country: 'Country',
    mobile_number: 'Mobile Number',
    continue: 'Continue',
    resend_code: 'Resend Code',
    newsletter_email: 'Newsletter Email',
    newsletter_sms: 'Newsletter Sms',
    welcome: 'Welcome,',
    import_differences: 'Import Differences',
    current: 'Current',
    real: 'Real',
    differences: 'Differences',
    public_cloud: 'Public Cloud',
    private_cloud: 'Private Cloud',
    routers: 'Routers',
    resources: 'Resources',
    site_enabled: 'Site Enabled',
    site_disabled: 'Site Disabled',
    ip_address: 'IP Address',
    reset_password: 'Reset Password',
    file_manager: 'File Manager',
    file_browser: 'File Browser',
    create_folder: 'Create Folder',
    delete_folder: 'Delete Folder',
    create_file: 'Create File',
    delete_file: 'Delete File',
    upload_file: 'Upload File',
    download_file: 'Download File',
    nat: 'NAT',
    nat_rules: 'NAT Rules',
    applied_on: 'Applied On',
    nat_type: 'Nat Type',
    source_ip: 'Source IP/Range',
    original_destination_ip: 'Original Destination IP/Range',
    original_destination_port: 'Original Destination Port/Range',
    translated_ip: 'Translated IP/Range',
    translated_port: 'Translated Port/Range',
    original_source_ip: 'Original Source IP/Range',
    original_source_port: 'Original Source Port/Range',
    destination_ip: 'Destination IP/Range',
    translated: 'Translated',
    storage_accounts: 'Storage Accounts',
    storage_accounts_size: 'Storage Accounts size',
    rename_folder: 'Rename Folder',
    rename_file: 'Rename File',
    refresh: 'Refresh',
    rename: 'Rename',
    cut: 'Cut',
    paste_here: 'Paste Here',
    cancel_cut: 'Cancel Cut',
    last_modified: 'Last Modified',
    permissions: 'Permissions',
    up: 'Up',
    down: 'Down',
    time_frame: 'Time Frame',
    live_server: 'Auto Refresh',
    live: 'On',
    offline: 'Off',
    resource_group: 'Resource Group',
    resource_group_name: 'Resource Group Name',
    value: 'Value',
    rules: 'Rules',
    billing_period_policy: 'Billing Period Policy',
    billing_type: 'Billing Type',
    resource_name: 'Resource Name',
    product_category: 'Product Category',
    division: 'Division',
    orders: 'Orders',
    product_pricing: 'Product Pricing',
    edit_resources: 'Edit Resources',
    update_resources: 'Update Resources',
    pricing_overrides: 'Pricing Overrides',
    period_start: 'Period Start',
    period_end: 'Period End',
    pricing_override: 'Pricing Override',
    network_type: 'Network Type',
    internal_network: 'Internal Network',
    statistics: 'Statistics',
    local_subnet: 'Local Subnet',
    peer_subnet: 'Peer Subnet',
    tunnel_state: 'Tunnel State',
    tunnel_status: 'Tunnel Status',
    statistic_details: 'Statistic Details',
    serial: 'Serial',
    billing_period: 'Billing Period',
    order_details: 'Order Details',
    task_details: 'Task Details',
    details: 'Details',
    quantity: 'Quantity',
    portgroup_name: 'Portgroup Name',
    mac_address: 'Mac Address',
    is_connected: 'Is Connected',
    admin_info: 'Admin Info',
    disk_info: 'Disk Info',
    vmware_info: 'VMware Info',
    uplink_network: 'Uplink Network',
    network_card: 'Network Card',
    seconday_ips: 'Secondary Ips',
    history_tasks: 'Tasks History',
    general: 'General',
    sessions: 'Sessions',
    last_activity: 'Last Activity',
    safe_devices: 'Safe Devices',
    trust_device: 'Trust Device',
    platform: 'Platform',
    platform_version: 'Platform Version',
    device_type: 'Device Type',
    browser: 'Browser',
    browser_version: 'Browser Version',
    saved_date: 'Saved Date',
    notifications: 'Notifications',
    telephone: 'Telephone',
    save_informations: 'Save Information',
    verified_email: 'Verified Email',
    not_verified_email: 'Not Verified Email',
    verified_mobile: 'Verified Mobile',
    not_verified_mobile: 'Not Verified Mobile',
    ip_list: 'Ip List',
    save_ips: 'Save Ips',
    traffic: 'Traffic',
    outgoing_traffic: 'Outgoing Traffic',
    incoming_traffic: 'Incoming Traffic',
    security: 'Security',
    user_id: 'User ID',
    user_logs: 'User Logs',
    bytes_in: 'Bytes In',
    bytes_out: 'Bytes Out',
    cur_sessions: 'Current Sessions',
    max_sessions: 'Max Sessions',
    total_sessions: 'Total Sessions',
    enable_rescue_mode: 'Enable Rescue Mode',
    disable_rescue_mode: 'Disable Rescue Mode',
    cpu_per_socket: 'CPU per socket',
    vpn_concentrator: 'VPN Concentrator',
    local_address: 'Local Address',
    ip_pools: 'IP Pools',
    profiles: 'Profiles',
    dns: 'DNS',
    start_address: 'Start Address',
    end_address: 'End Address',
    subnet_mask: 'Subnet Mask',
    remote_id: 'Remote Id',
    resolved_by: 'Resolved By',
    ip_pool: 'IP Pool',
    route_list: 'Route List',
    dns_list: 'DNS List',
    download_certificate: 'Download Certificate',
    persistence_mode: 'Persistence Mode',
    cookie_name: 'Cookie Name',
    cookie_mode: 'Cookie Mode',
    expires_in: 'Expires In',
    authenticator: 'Authenticator',
    authenticator_enabled: 'Authenticator Enabled',
    connected_from: 'Connected From',
    uptime: 'Uptime',
    migrate: 'Migrate',
    datastore: 'Datastore',
    admin_options: 'Admin Options',
    enable_strict: 'Enable Strict',
    enable_loose: 'Enable Loose',
    instance: 'Instance',
    available_ips: 'Available IPs',
    unassigned_ip: 'Unassigned IP address',
    cidr: 'CIDR',
    available: 'Available',
    reserved: 'Reserved',
    storage_account: 'Storage Account',
    cloud_server: 'Cloud Server',
    cloud_disk: 'Cloud Disk',
    vm_name: 'VM Name',
    vpn_concentrator_user: 'VPN Concentrator User',
    vcenter: 'vCenter',
    vcenters: 'vCenters',
    backup_policy: 'Backup Policy',
    destination_ports: 'Destination Ports',
    source_ports: 'Source Ports',
    companyVAT: "Company's VAT number",
    disabled: 'Disabled',
    invoices: 'Invoices',
    transactions: 'Transactions',
    reference: 'Reference',
    invoice_type: 'Invoice Type',
    branch: 'Branch',
    net_value: 'Net Value',
    tax_value: 'Tax Value',
    total_value: 'Total Value',
    billing: 'Billing',
    balance: 'Balance',
    expired_balance: 'Expired Balance',
    irs: 'IRS',
    invoice: 'Invoice',
    language: 'Language',
    amount: 'Amount',
    completed_at: 'Completed At',
    failed_at: 'Failed At',
    payment_with: 'Payment With',
    payment_methods: 'Payment Methods',
    invoice_referennce: 'Invoice Reference',
    server_status: 'Server Status',
    mode: 'Mode',
    warning: 'Warning',
    enabled: 'Enabled',
    verify: 'Verify',
    hours: 'Hours',
    _hours: 'hours',
    failure_cause: 'Failure Cause',
    tunnel_cidr: 'Tunnel CIDR',
    download_authenticator: 'Download {brandname} Authenticator',
    lights_on: 'Lights on',
    lights_off: 'Lights off',
    dynamic_mode: 'Dynamic Mode',
    psk: 'Pre-Shared Key',
    update_psk: 'Update Pre-Shared Key',
    delete_psk: 'Delete Pre-Shared Key',
    create_psk: 'Create Pre-Shared Key',
    dynamic_mode_preshared_key: 'Dynamic Mode Pre-Shared Key',
    installed: 'Installed',
    pending_apply_activate: 'Pending Apply (Activate)',
    pending_apply_delete: 'Pending Apply (Delete)',
    failed: 'Failed',
    pylon_product: 'Pylon Product',
    pylon_customer: 'Pylon Customer',
    sdn_type: 'SDN Type',
    vdn_scope: 'VDN Scope',
    nsx_user: 'NSX Username',
    nsx_password: 'NSX Password',
    instance_name: 'Instance Name',
    instance_type: 'Instance Type',
    resource: 'Resource',
    licenses: 'Licenses',
    select: 'Select',
    since: 'Since',
    license_quantity: 'License Quantity',
    system_operations: 'System Operations',
    system_operation_type: 'System Operations Type',
    dispatch: 'Dispatch',
    license: 'License',
    redirect_url: 'Redirect URL',
    redirect_mode: 'Redirect Mode',
    activate: 'Activate',
    deactivate: 'Deactivate',
    instances: 'Instances',
    connected: 'Connected',
    icon: 'Icon',
    internal_note: 'Internal Note',
    dedicated_server: 'Dedicated Server',
    open: 'Open',
    add: 'Add',
    approve: 'Approve',
    area: 'Area',
    zip: 'ZIP',
    contact_number: 'Contact Number',
    timezone: 'Timezone',
    duplicate: 'Duplicate',
    hostname: 'Hostname',
    software: 'Software',
    hardware: 'Hardware',
    billing_overview: 'Billing Overview',
    password_confirmation: 'Password Confirmation',
    repository_name: 'Repository Name',
    notification_email: 'Notifications Email',
    script_location: 'Script Location',
    unassign: 'Unassign',
    default_gateway: 'Default Gateway',
    manage_customer: 'Manage Customer',
    parent_company: 'Parent Company',
    customer_flags: 'Customer Flags',
    feature_flags: 'Feature Flags',
    switch: 'Switch',
    dns_zone: 'DNS Zone',
    dns_zones: 'DNS Zones',
    dns_record: 'DNS Record',
    dns_zone_records: 'Zone Records',
    ttl: 'TTL',
    priority: 'Priority',
    weight: 'Weight',
    flag: 'Flag',
    tag: 'Tag',
    content: 'Content',
    records: 'Records',
    back: 'Back',
    storage_policies: 'Storage Policies',
    dns_cluster: 'DNS Cluster',
    name_server: 'Name Server',
    name_servers: 'Name Servers',
    base_path: 'Base Path',
    key: 'Key',
    availability: 'Availability',
    backing: 'Backing',
    credentials: 'Credentials',
    help_center: 'Help Center',
    pfs_group: 'PFS Group',
    dpd_settings: 'DPD Settings',
    download: 'Download',
    object_bucket: 'Object Bucket',
    privacy: 'Privacy',
    keys: 'Keys',
    account_number: 'Account Number',
    payments: 'Payments',
    credit: 'Credit',
    debit: 'Debit',
    payment_status: 'Payment Status',
    paid: 'Paid',
    partly_paid: 'Partly Paid',
    unpaid: 'Unpaid',
    driver: 'Driver',
    database: 'Database',
    schema: 'Schema',
    boot_type: 'Boot Type',
    more: 'more',
    backup_server: 'Backup Server',
    invited: 'Invited'
  },
  texts: {
    lorem: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    disk_options: 'Disk Options',
    full_vm_list_option:
      'By selecting Full VM List Backup option means backup includes all disks.',
    update_settings: 'Update Settings',
    custom_default_gateway: 'Custom Default Gateway',
    required_twofa: 'Required two factor authentication',
    create_disk: 'Create Cloud Disk',
    delete_disk: 'Delete Disk',
    moving_disk: 'Moving Disk',
    to_server: 'to server: ',
    delete_disk_confirm: 'Do you want to delete the disk',
    _for_server: 'for Server',
    disk_overview: 'Disk Overview',
    name_is_missing: 'Name is missing',
    server_is_missing: 'Server is missing',
    servers_are_missing: 'Servers are missing',
    service_config_is_missing: 'Service config is missing',
    gb_disk: 'GB Disk',
    disk_name_is_invalid: 'Disk name is invalid',
    server_is_not_selected: 'Server is not selected',
    disk_created: 'Disk Creation Dispatched',
    show_primary_disks: 'Show primary disks',
    add_disk: 'Add Disk',
    network_protection_enabled: 'Network Protection Enabled',
    network_protection_disabled: 'Network Protection Disabled',
    add_ip: 'Add IP',
    ip_overview: 'IP Overview',
    id_address_is_missing: 'IP Address is missing',
    id_address_added: 'IP Address added',
    ip_address: 'IP Address',
    ip_addresses: 'IP Addresses',
    id_address_fatal_error_message: `
    IP was disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    set_reverse_record: 'Set Reverse Record',
    release_ip: 'Release IP',
    release_ip_confirm: 'Do you want to release ip',
    ip_released: 'IP released',
    _for_ip: 'for IP',
    field_cannot_be_empty: 'Field cannot be empty',
    reverse_record_updated: 'Reverse Record Updated',

    add_network: 'Create Network',
    add_network_card: 'Add Network Card',
    disconnect_network_card: 'Disconnect Network Card',
    connect_network_card: 'Connect Network Card',
    network_card_disconnected: 'Network Card Disconnected',
    network_card_connected: 'Network Card Connected',
    from_network: 'from Network',
    to_network: 'to Network',

    private_networks: 'Private Networks',
    network_fatal_error_message: `
    Network was disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    delete_network: 'Delete Network',
    delete_network_confirm: 'Do you want to delete network',
    delete_network_card: 'Delete Network Card',
    delete_network_card_confirm: 'Do you want to delete Network Card',
    network_deleted: 'Network Deleted',
    network_card_deleted: 'Network Card Deleted',

    add_private_network: 'Add Private Network',
    location_is_not_set: 'Location is not set',
    location_is_missing: 'Location is missing',
    network_created: 'Network Creation Dispatched',
    backup_servers: 'Backup Servers',
    add_backup_server: 'Add Backup Server',
    edit_backup_server: 'Edit Backup Server',
    delete_backup_server_confirm: 'Confim Backup Server Delete',
    backup_server_created: 'Backup Server Created',
    backup_server_updated: 'Backup Server Updated',
    delete_backup_server: 'Delete Backup Server',
    backup_server_deleted: 'Backup Server Deleted',
    repository_name: 'Repository Name',
    notification_email: 'Notification Email',
    script_location: 'Script Location',
    add_server: 'Add Server',
    create_server: 'Create Cloud Server',
    server_overview: 'Server Overview',
    delete_server: 'Delete Server',
    delete_server_confirm: 'Do you want to delete server',
    clone_server: 'Clone Server',
    server_deleted: 'Server Deleted',
    server_created: 'Server Creation Dispatched',
    vnc_for_server: 'VNC for Server',
    confirm_server_name: 'Please confirm the server name',
    in_order_to_delete_id: 'in order to delete it',
    server_deletion_dispatched: 'Server deletion dispatched',
    verification_name_does_not_match_server:
      'Verification name does not match with servers name',
    verification_name_does_not_match_dns_zone:
      'Verification name does not match with DNS Zone name',
    server_fatal_error_message: `
    Server management is locked because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    server_disabled_error_message: `
    Server is temporary unavailable.
     Please contact us at {email} to assist you with the issue.
    `,

    disk_deleted: 'Disk Deleted',
    disk_deletion_dispatched: 'Disk deletion dispatched',
    disk_server_name: 'Please confirm the disk name',
    verification_name_does_not_match_disk:
      "Verification name doesn't match the disk name",
    schedule_hardware_upgrade: 'Schedule Hardware Upgrade',
    ssh_key: 'SSH Key',
    ssh_keys: 'SSH Keys',
    add_key: 'Add Key',
    edit_notes: 'Edit Notes',
    change_type: 'Change Type',
    delete_ssh_key: 'Delete SSH Key',
    delete_ssh_key_confirm: 'Do you want to delete SSH Key',

    datacenter_location: 'Datacenter Location',
    version: 'Version',
    disk_type: 'Disk Type',
    disk_types: 'Disk Types',
    disk_type_is_missing: 'Disk Type is missing',

    select_datacenter: 'Select Datacenter',
    select_driver: 'Select Driver',
    select_server: 'Select Server',

    forgot_password: 'Forgot Password',
    send_reminder: 'Send Reminder',
    reset_password: 'Reset Password',
    username_und_email: 'Username / Email',
    sign_in: 'Sign In',
    token_is_missing: 'Token is missing',

    create_subnet: 'Create Subnet',
    subnet_created: 'Subnet Creation Dispatched',
    add_subnet: 'Add Subnet',
    edit_subnet: 'Edit Subnet',
    delete_subnet: 'Delete Subnet',
    delete_subnet_confirm: 'Do you want to delete subnet',
    subnet_deleted: 'Subnet Deleted',
    subnet_updated: 'Subnet Updated',

    edit_datacenter: 'Edit Datacenter',
    delete_datacenter: 'Delete Datacenter',
    delete_datacenter_confirm: 'Do you want to delete datacenter',
    datacenter_deleted: 'Datacenter Deleted',
    create_datacenter: 'Create Datacenter',
    datacenter_created: 'Datacenter Creation Dispatched',
    datacenter_updated: 'Datacenter Updated',

    edit_notes_for: 'Edit notes for',

    disk_fatal_error: `
    Disk is disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    server_includes_ip_address: 'Server already includes 1 IPv4 address.',
    router_includes_ip_address: 'Router already includes 1 IPv4 address.',
    includes_ip_address: 'already includes 1 IPv4 address.',
    need_ip_address: 'Need more IP addresses ?',
    need_cpu: 'Need more vCPUs ?',
    need_ram: 'Need more RAM ?',
    need_disk: 'Need more disk space ?',
    ip_future: 'You can request an upgrade anytime in the future',

    copied_to_clipboard: 'Copied to clipboard',
    not_copied_to_clipboard: 'Could not copy to clipboard',

    select_parent_company: 'Select Parent Company',
    customer_type: 'Customer Type',
    customer_status: 'Customer Status',
    select_private_vcenter: 'Select Private vCenters',
    customer_emails: 'Customer Emails',
    invitation_links_to_emails:
      'We will send invitation links to customer accounts',

    edit_network: 'Edit Network',
    network_updated: 'Network Updated',
    invite_users: 'Invite Users',
    enter_emails: 'Enter emails',
    remove_user: 'Remove User',
    remove_user_confirm: 'Do you want to remove user',

    move_disk: 'Move Disk',
    disk_size: 'Disk Size',
    change_disk_size: 'Change Disk Size',
    resize_disk: 'Resize Disk',
    resize_disk_confirm: 'Do you want to resize the disk',
    change_disk_type: 'Change Disk Type',
    change_disk_type_warning:
      'You have to select a disk type other than the current one.',

    manage_ssh_keys: 'Manage Server SSH Keys',

    server_network_card: 'Server Network Card',
    network_type: 'Network Type',
    network_card_updated: 'Network card updated',
    network_card_created: 'Network card creation dispatched',

    no_history_record_found: 'No history record found',
    session_expired: 'Session Expired',
    refresh_session: 'Refresh Session',

    public_key: 'Public Key',
    public_network: 'Public Network',
    public_networks: 'Public Networks',
    invitations_results: 'Invitations Results',

    ip_address_blocks: 'Ip Address Blocks',
    ip_and_subnet: 'IP / Prefix',
    select_datacenter_location: 'Please select a datacenter location',

    esxi_profile: 'ESXI Profile',
    esxi_settings: 'ESXI Settings',
    nsx_settings: 'NSX Settings',
    nsx_host: 'NSX Host',
    policy_group: 'Policy Group',
    policy_group_missing: 'No policies found for current settings.',

    power_on: 'Power On',
    power_off: 'Power Off',
    restart: 'Reset',
    shutdown_guest: 'Shutdown Guest',
    restart_guest: 'Restart Guest',

    rescale_server: 'Rescale Server',
    rescale_server_confirm: 'Do you want to rescale',
    current_snapshot: 'Current Snapshot',
    no_snapshots_for_server: 'There are not any snapshots for this server.',
    restore_snapshot: 'Restore Snapshot',
    restore_snapshot_confirm: 'Do you want to restore snapshot',
    delete_snapshot: 'Delete Snapshot',
    delete_snapshot_confirm: 'Do you want to delete Snapshot',
    disk_images: 'Disk Images',
    add_disk_image: 'Add Disk Image',
    customer_private_disk_images: 'Customer Private Disk Images',
    console_info: 'Click connect to open a console session',
    console_warning: 'Server has to be powered-on to use Console',
    _is_required: 'is required',
    select_ip: 'Select IP',
    no_results: 'No results',
    operating_system: 'Operating System',
    cpu_tier: 'CPU Tier',
    no_private_datacenters: 'You do not have private datacenters!',
    select_vcenter: 'Select vCenter',

    change_user_password: 'Change user password',
    old_password: 'Old Password',
    password_confirmation: 'Password Confirmation',

    no_active_tasks_found: 'No Active Tasks found',
    active_tasks_loading: 'Active Tasks loading',
    no_tasks_found: 'No Tasks found',
    tasks_loading: 'Tasks loading',
    task_status: 'Task Status',
    requestedBy: 'Requested By',

    recycle_pool_name: 'Recycle Pool Name',
    machine_password: 'Machine Password',
    is_nsx_managed: 'NSX Managed',
    root_password: 'New Password',
    administrator_password: 'Administrator Password',
    repeat_root_password: 'Repeat New Password',
    repeat_administrator_password: 'Repeat Administrator Password',
    generate_password: 'Generated password with reminder email',
    manual_password: 'Manual password with reminder email',
    manual_password_no_email: 'Manual password without reminder email',
    password_mode: 'Password Mode',

    feedback_sent: 'Feedback Sent',
    issue_sent: 'Issue Sent',
    maintenance_queue_message:
      'Queue is in maintenance, tasks will be delayed.',
    maintenance_mode_message: 'Service is in maintenance, we will be back soon',
    delete_vpn: 'Delete VPN',
    delete_vpn_confirm: 'Do you want to delete VPN',
    regenerate_config: 'Regenerate Vpn User Config',
    config_updated: 'Config Updated',
    create_vpn: 'Create VPN',
    vpn_type: 'VPN Type',
    add_vpn: 'Add VPN',
    routes_is_missing: 'Routes is missing',
    users_email_is_missing: 'Users email is missing',
    internal_ip_config_is_missing: 'Internal IP config is missing',
    vpn_overview: 'VPN Overview',

    delete_this_issue: 'Delete Issue',
    delete_this_issue_confirm: 'Do you want to delete the issue',
    issue_deleted: 'Issue deleted',
    issue_view: 'Issue view',
    manage_firewall_rules: 'Manage Firewall Rules',

    delete_firewall_rule: 'Delete Firewall Rule',
    delete_firewall_rule_confirm: 'Do you want to delete firewall rule',
    firewall_rule_deleted: 'Firewall Rule Deleted',

    snapshot_is_active: 'Server has active snapshot.',
    read_only_is_active: 'Server is Read-Only.',
    actions_disabled: 'Some actions have been disabled.',
    pw_strength: 'Password Strength',

    add_load_balancer: 'Add Load Balancer',
    load_balancer_fatal_error: `
      Load balancer is disabled because the last task was not successful.
      Please contact us at {email} to assist you with the issue.`,
    create_load_balancer: 'Create Load Balancer',
    add_certificate: 'Add Certificate',
    load_balancer_overview: 'Load Balancer Overview',
    network_is_missing: 'Network is missing',
    algorithm_is_missing: 'Algorithm is missing',
    delete_load_balancer: 'Delete Load Balancer',
    delete_load_balancer_confirm: 'Do you want to delete the load balancer',
    server_unavailable:
      'Servers in read-only mode or with an active snapshot are unavailable',
    choose_private_network_to_enable:
      'Choose private network to enable the servers',
    no_servers_found: 'No servers found',

    delete_certificate: 'Delete certificate',
    delete_certificate_confirm: 'Do you want to delete certificate',

    add_router: 'Add Router',
    router_fatal_error: `Router is disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.`,
    create_router: 'Create Router',
    router_overview: 'Router Overview',
    dns_overview: 'DNS Zone Overview',
    object_bucket_overview: 'Object Bucket Overview',
    vcenter_overview: 'vCenter Overview',
    ip_and_subnet_is_missing: 'IP/Subnet is missing',
    rescalePackage: 'Rescale Package',
    rescalePackage_is_missing: 'Rescale Package is missing',
    delete_router_confirm: 'Do you want to delete router',
    delete_router: 'Delete router',
    confirm_router_name: 'Please confirm the router name',
    router_network_card: 'Router network card',
    services_is_missing: 'Services is missing',
    select_disk_type: 'Select Disk Type',
    add_customer: 'Add Customer',
    add_vcenter: 'Add vCenter',

    select_customer: 'Select Customer',
    graphs_empty_when_server_closed:
      'Graphs might be empty when the cloud server is powered off',

    router_created: 'Router Creation Dispatched',
    router_deletion_dispatched: 'Router delete dispatched',
    certificate_card_deleted: 'Certificate Card Deleted',
    edit_backups: 'Edit Backups',
    delete_backups: 'Delete Backups',
    delete_backup_confirm: 'Do you want to delete the current backups',
    backup_card_disable: 'Backup Card Disable',
    certificated_created: 'Certificate Creation Dispatched',
    certificated_edited: 'Certificate Edited',
    add_certicate: 'Add Certificate',
    wait_for_backups:
      'Please wait until the initial backup of the server is created, check back again tomorrow.',

    create_firewall: 'Create Firewall',
    create_snapshot: 'Create Snapshot',
    server_is_active: 'Server is ON.',
    delete_disabled: 'Delete has been disabled until actions are made',
    load_balancer_created: 'Load Balancer Creation Dispatched',
    load_balancer_updated: 'Load Balancer Updated',
    delete_member: 'Delete Member',
    delete_member_confirm: 'Do you want to delete member',
    add_member: 'Add Member',
    select_condition: 'Select Condition',
    imports: 'Imports',
    sync_import: 'Sync import',
    sync_import_confirm: 'Do you want to sync import',
    cant_sync_not_exists:
      "You can't sync the rows with report issue 'Not Exist'",
    cant_import_with_differences:
      "You can't import the rows with report issue 'Differences'",
    select_row: 'Please select a row',
    reinstall_server_confirm: 'Do you want to reinstall server',
    reinstallation_info:
      'Reinstalling offers the option to revert back to the original installation of the instance you ordered.',
    reinstall_warnings: {
      backup_data: 'Make sure you backup all data before you reinstall.',
      shutdown:
        'You need to shutdown your server before starting the installation.'
    },
    delete_warnings: {
      shutdown: 'You need to shutdown your server before deleting it.',
      files_exist: 'Bucket must be empty before deleting it.'
    },
    restore_backup: 'Restore Backup',
    restore_backup_confirm:
      'Do you really want to restore this backup? Doing so will be irreversible for your server, meaning that all data will be lost after ',
    restoring_backup: 'Restoring Backup',
    in_order_to_restore_backup:
      'In order to restore backup confirm the server name ',
    verification_name_does_not_match_restore_name:
      'Verification name does not match the server name',
    backup_running: 'Backup is running',
    are_you_sure_reinstall:
      'Are you sure you want to initiate an OS reinstall? This will delete all your current data and configurations on the server. To continue, please type ',
    verification_name_does_not_match_server_name:
      'Verification name does not match the server name',
    reinstalling_os: 'Reinstall OS',
    audit_dialog_required: 'Software Audit is required!',
    audit_dialog_description: 'Please run the audit service.',
    audit: 'Audit',
    select_kind: 'Select Kind',
    select_router: 'Select Router',
    different_version_from_server:
      'Server has a different operating system from this backup',
    choose_server_or_router: 'You need to choose a server or router first.',
    load_balancer_deletion_dispatched: 'Load Balancer deletion dispatched.',
    create_new_site: 'Create new site',
    creation_time: 'Creation Time',
    enable_disable_site: 'Enable/Disable site',
    delete_ipsec_site: 'Delete IPSEC Site',
    delete_ipsec_site_confirm: 'Do you want to delete IPSEC Site',
    ipsec_site_deletion_dispatched: 'IPSEC Site deletion dispatched',
    ipsec_created: 'IPSEC Site Creation Dispatched',
    ipsec_edited: 'IPSEC Site Edited',
    ipsec_site_fatal_error: 'IPSEC Site was disabled because the last task was not successful. Please contact us at {email} to assist you with the issue.',
    invalid_same_remote_local_endpoint:
      'IP cant be the same in local and remote endpoint.',
    enable_console_task: 'Enable console',
    add_product: 'Add Product',
    product_rates: 'Product Rates',
    add_customer_override: 'Add Customer Override',
    delete_pricing_override: 'Delete Pricing Override',
    delete_pricing_override_confirm: 'Do you want to delete pricing override',
    delete_service_override: 'Delete Service Override',
    delete_service_override_confirm: 'Do you want to delete service override',
    add_service_override: 'Add Services Override',
    customer_name: 'Customer Name',
    customer_product: 'Customer Product',
    type: 'Type',
    rate_per_month: 'Rate Per Month',
    rate_per_hour: 'Rate Per Hour',
    service_type: 'Service Type',
    server_off_to_enable_console: 'Server must be turned off to enable console',
    phase_one_is_missing: 'Phase one is missing',
    phase_two_is_missing: 'Phase two is missing',
    create_static_route: 'Create static route',
    static_route: 'Static Route',
    delete_static_route_card: 'Delete Static Route',
    delete_static_route_card_confirm: 'Do you want to delete static route',
    static_route_card_deleted: 'Static Route Deleted',
    static_route_edited: 'Static Route Edited',
    static_route_created: 'Static Route Creation Dispatched',
    firewall_fatal_error: `
    Firewall rules were disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    static_route_fatal_error: `
    Static route has been disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    virtual_media: 'Virtual Media',
    select_iso: 'Select ISO',
    mount_on_server: 'Mount on server',
    unmount_from_server: 'Unmount from server',
    eject_before_unmount: 'You need to eject the iso before unmounting.',
    form_has_submitted: 'Your application has been submitted!',
    verification_has_sent: 'A verification message has been sent!',
    mail_confirmation:
      'You will receive a confirmation mail with an invitation for the panel when the application will be approved.',
    submit_to_send_message:
      'Continue to send a verification in the mobile number you registered.',
    message_validation_number:
      'A message with a validation number has sent to your mobile, please insert it in the field and submit for verification.',
    server_mount_dispatched: 'Mount ISO dispatched',
    server_unmount_dispatched: 'Unmount ISO dispatched',
    enable_two_fac_auth_dispatched:
      'Enable Two Factor Authentication Dispatched',
    verification_token_dispatched: 'Verify Token Dispatched',
    disable_two_fac_auth_dispatched:
      'Disable Two Factor Authentication Dispatched',
    change_password_dispatched: 'Change Password Dispatched',
    forgot_password_dispatched:
      'Reset Password email sent, please check your email.',
    router_is_missing: 'Router is missing',
    unmount_iso: 'Unmount Iso',
    unmount_iso_confirm: 'Do you want to unmount the current image',
    eject_from_console:
      "Before unmount you must first go to console and type 'eject'",
    approve_registration_confirm: 'Do you want to approve registration of ',
    reject_registration_confirm: 'Do you want to reject registration of ',
    duplicate_registration_confirm: 'Is this registration duplicate of ',
    registration_action: 'Registration Action',
    panel_welcome_message:
      'Please, fill in your information to get access to {brandname} Panel.',
    auto_eject_in: 'ISO will be ejected automatically at ',
    network_card_in_use_for_load_balancer:
      'Network card is in use at load balancer ',
    network_card_in_use_for_static_route_next_hop:
      'Network card is in use at static route in next hop ',
    network_card_in_use_for_ipsec_edge_internal:
      'Network card is in use at edge internal of IPSEC Site ',
    network_in_use_for_ipsec_edge_internal:
      'Network is in use at edge internal of IPSEC Site ',
    ipsec_fatal_error: `
      IPSEC Site was disabled because the last task was not successful.
      Please contact us at {email} to assist you with the issue.
      `,
    register_send: {
      title: 'Mobile Verification Required',
      description:
        'Click below to send a verification code to the submitted phone number',
      button: 'Send Code'
    },
    register_verify: {
      title: 'Verification Code Sent!',
      description: 'A Verification Code has been sent to your mobile.',
      input_label: 'Verification Code'
    },
    select_type: 'Select Type',
    certificate_in_use_for_load_balancer:
      'Certificate is in use at load balancer ',
    import_dispatched: 'Import Dispatched',
    extend_disk: 'Extend Disk',
    cpu_resource_not_enough: 'CPU resources are not enough',
    ram_resource_not_enough: 'RAM resources are not enough',
    disk_resource_not_enough: 'DISK resources are not enough',
    select_disk_type_first: 'You must select disk type first',
    select_location_first: 'You must select location first.',
    see_resources: 'See Resources',
    resources_not_enough: 'resources are not enough',
    add_storage_account: 'Add Storage Account',
    delete_storage_account: 'Delete Storage Account',
    delete_storage_account_confirm: 'Do you want to delete storage account ',
    storage_account_deleted: 'Storage Account Deleted',
    storage_account_fatal_error:
      'Storage Account was disabled because the last task was not successful.' +
      'Please contact us at {email} to assist you with the issue.',
    storage_account_name: 'Storage Account Name',
    create_storage_account: 'Create Storage Account',
    service_type_is_missing: 'Service Type is missing',
    storage_account_overview: 'Storage Account Overview',
    storage_account_created: 'Storage Account Creation Dispatched',
    rescale_storage_account: 'Rescale Storage Account',
    create_nat: 'Create NAT',
    enable_disable_rule: 'Enable/Disable Rule',
    must_be_subset_of_internal_ip_or_static_route:
      'Must be a subset of internal ip or static route',
    must_be_subset_of_internal_ip_or_static_route_or_remote_subnet_ipsec:
      'Must be a subset of internal ip or static route or ipsec remote subnet',
    must_be_subset_of_internal_ip_or_static_route_not_applied_on:
      "Must not be subnet of 'applied on' interface",
    must_not_be_router_ip: 'Must not be a router used ip',
    must_be_subset_of_internal_ip_or_static_route_not_applied_on_static:
      "Must not be subnet of static route with next hop in 'applied on' interface",
    must_not_be_subset_of_internal_ip_or_static_route:
      'Must not be a subset of internal ip or static route',
    must_belong_in_subnets: 'IP must belong in subnets',
    nat_fatal_error: `
      NAT rules were disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.
    `,
    must_not_be_network_card_ip: ' Source IP must not be network card IP',
    destination_ip_must_not_be_subset_of_original_source_ip:
      'Destination IP must not be a subset of source IP',
    destination_ip_no_static_next_hop_source_ip:
      "Destination IP must not be a subnet of a static route with next hop in 'source ip' network",
    delete_nat_rule: 'Delete NAT rule',
    delete_nat_rule_confirm: 'Do you want to delete NAT rule ',
    nat_rule_deleted: 'NAT rule deleted',
    source_ip_must_be_subset_of_applied_on:
      'Source IP must be a subset or equal of applied on network',
    source_address_and_destination_not_same_network:
      'Source address and destination cannot belong in the same network',
    must_not_be_same_as_original_source_ip:
      'Destination IP must not be the same as source IP',
    please_put_valid_ports: 'Please enter valid port range',
    please_put_valid_port: 'Please enter valid port',
    must_be_greater_ports: 'Ending port number must be greater than starting',
    you_cant_downsize_disk_after:
      "If you exceed the 100GB you won't be able to scale down the disk in the future",
    disksize_must_be_greater_than_usage:
      "Disk-size can't be smaller than usage",
    you_cant_downsize_disk_now: "You can't scale down the disk",
    add_resource_group: 'Add Resource Group',
    resource_group_deletion_dispatched: 'Resource Group deletion dispatched',
    at_least_one_resource: 'You must have at least one resource',
    delete_product: 'Delete Product',
    delete_product_confirm: 'Do you want to delete product',
    product_created: 'Product created',
    product_updated: 'Product updated',
    product_deleted: 'Product deleted',
    add_product_category: 'Add Product Category',
    product_category_created: 'Product Category created',
    product_category_updated: 'Product Category updated',
    delete_product_category: 'Delete Product Category',
    delete_product_category_confirm: 'Do you want to delete product category',
    product_category_deleted: 'Product Category Deleted',
    customer_overview: 'Customer Overview',
    update_customer: 'Update Customer',
    network_must_not_exceed_ten: "You can't exceed ten networks",
    file_with_that_name_already_exists:
      'A file with that name already exist in the current folder',
    folder_with_that_name_already_exists:
      'A folder with that name already exist in the current folder',
    select_folder: 'Please select a folder to upload the file',
    ipsec_statistics: 'IPSEC Site Statistics',
    statistic_details: 'Statistic Details',
    must_be_valid_range: 'Port must be between 1 and 65535',
    'power_shutdown-guest_server': 'Shutdown guest Server',
    'power_shutdown-guest_server_confirm':
      'Are you sure you want to shutdown the guest server?',
    'power_powered-off_server': 'Power Off Server',
    'power_powered-off_server_confirm':
      'Are you sure you want to power off the server?',
    power_restarted_server: 'Reset Server',
    power_restarted_server_confirm:
      'Are you sure you want to reset the server?',
    'power_reboot-guest_server': 'Restart guest Server',
    'power_reboot-guest_server_confirm':
      'Are you sure you want to restart the guest server?',
    show_password: 'Show Password',
    loading_new_graph: 'Loading new graph',
    all_ips: 'All Ips',
    delete_node: 'Delete Node',
    delete_node_confirm: 'Are you sure you want to delete ',
    delete_key: 'Delete Key',
    delete_key_confirm: 'Are you sure you want to delete ',
    file_cant_exceed_1GB: "File can't exceed the 1 GB",
    network_card_doesnt_have_secondary_ip:
      "Network Card doesn't have a secondary IP",
    delete_session: 'Delete Session',
    delete_session_confirm: 'Are you sure you want to delete this session?',
    session_deleted: 'Session Deleted',
    delete_safe_device: 'Delete Safe Device',
    delete_safe_device_confirm: 'Are you sure you want to delete this device?',
    safe_device_deleted: 'Device Deleted',
    company_profile: 'Company Profile',
    edit_access_list: 'Edit access list',
    allow_login_from: '(allow login from)',
    security_user_message:
      'Specify individual IP addresses or IP subnets to access {brandDomain} for your account. When no IP is set, connection is allowed from everywhere.',
    security_user_caution_message: 'Caution! Only public IPs allowed. Incorrect configuration will deny user access to the panel.',
    verify_email: 'Verify',
    load_balancer_statistics: 'Load Balancer Statistics',
    rescue_mode: 'Rescue Mode',
    change_rescue_mode: 'Change Rescue Mode',
    rescue_mode_is_enabled: 'Rescue Mode is enabled',
    sure_disable_rescue_mode: 'Are you sure you want to disable rescue mode?',
    sure_enable_rescue_mode: 'Are you sure you want to enable rescue mode?',
    valid_cpu_per_socket:
      'Cpu per socket must be les than CPUs and their remainder must be 0',
    orders_details: 'Order Details',
    disable_protection: 'Disable Protection',
    enable_protection: 'Enable Protection',
    protection_is_enabled: 'Protection is enabled.',
    add_vpn_concentrator: 'Add VPN concentrator',
    create_vpn_concentrator: 'Create VPN concentrator',
    vpnConcentrator_fatal_error: `VPN concentrator is disabled because the last task was not successful.
    Please contact us at {email} to assist you with the issue.`,
    vpn_concentrator_overview: 'VPN Concentrator Overview',
    private_network_is_missing: 'Private Network is missing',
    add_profile: 'Add Profile',
    delete_vpn_concentrator_confirm: 'Do you want to delete VPN concentrator',
    delete_vpn_concentrator: 'Delete VPN concentrator',
    confirm_vpn_concentrator_name: 'Please confirm the VPN concentrator name',
    add_user: 'Add User',
    manual_assign: 'Manual IP Assign',
    add_ip_pool: 'Add IP Pool',
    delete_ip_pool: 'Delete IP Pool',
    delete_ip_pool_confirm: 'Do you want to delete the IP pool',
    network_must_not_be_overlaping_ipsec_remote:
      'Network is overlaping ipsec remote',
    vpn_concentrator_created: 'VPN Concentrator Creation Dispatched',
    vpn_concentrator_network_card: 'VPN Concentrator network card',
    delete_profile: 'Delete Profile',
    delete_profile_confirm: 'Do you want to delete the profile',
    profile_deleted: 'Profile deleted',
    delete_user: 'Delete User',
    delete_user_confirm: 'Do you want to delete the user',
    vpn_concentrator_deletion_dispatched: 'VPN Concentrator delete dispatched',
    verification_name_does_not_match_vpn_concentrator:
      'Verification name does not match with VPN concentrator name',
    user_deleted: 'User Deleted',
    profile_used_on_user: 'A user is using this profile',
    ip_pool_created: 'IP Pool creation dispatched',
    ip_pool_updated: 'IP Pool updated',
    ip_pool_deleted: 'IP Pool deleted',
    _ip_pool_can_not_be_deleted:
      'IP pool can not be deleted. It is used by a profile.',
    generate_password_simple: 'Generate password',
    manual_password_simple: 'Manual password',
    user_created: 'User creation dispatched',
    user_updated: 'User updated',
    _network_card_can_not_be_deleted:
      'network card can not be deleted. It is used by a user.',
    ip_pool_used_on_profile: 'A profile is using this IP pool',
    network_card_used_on_user: 'A user is using this network card',
    scan_qrcode:
      'Scan this QR code with the Lancom Authenticator to authenticate your device.',
    vpn_user_statistics: 'Vpn User Statistics',
    _is_not_connected: 'is not connected',
    migrate_disk: 'Migrate Disk',
    no_other_datastores: 'There are no other datastores for migration.',
    migration_successful: 'Migration successful',
    reverse_path_forwarding: 'Reverse Path Forwarding',
    select_type_reverse_path: 'Select a type for the network card with id',
    select_types_reverse_path: 'Select a type for the network cards with ids',
    network_cards_selected: 'Network cards selected',
    network_card_selected: 'Network card selected',
    not_set_yet: 'Not set yet',
    select_backup_option: 'Select Backup option',
    backup_enabled: 'Backup enabled',
    backup_updated: 'Backup updated',
    admin_rule: 'Admin Rule',
    move_resource_pool: 'Move Resource Pool',
    no_other_resource_pools: 'There are no other resource pools for movement.',
    movement_successful: 'Movement successful',
    days_7_backups: '7 days Backup',
    days_2_backups: '2 days Backup',
    demo_expiration_date: 'Demo Expiration Date',
    customer_updated: 'Customer Updated',
    customer_created: 'Customer Creation Dispatched',
    no_subnet_selected: 'No subnet selected',
    showing_ips_subnet: 'Showing IPs of subnet',
    subnet_selected: 'Subnet selected',
    select_subnet: 'Select a subnet',
    reserve_ip: 'Reserve IP',
    admin_rules: 'Admin Rules',
    user_rules: 'User Rules',
    no_user_rules: 'No User Rules',
    no_admin_rules: 'No Admin Rules',
    edit_name: 'Edit name',
    rename_successful: 'Rename successful',
    edit_info: 'Edit Info',
    edit_subnets: 'Edit Subnets',
    edit_successful: 'Edit successful',
    not_same_os: 'Not same Operating System',
    current_pool: 'Current Pool',
    not_same_vcenter: 'Not same Vcenter',
    maximum_pools_reached: 'Maximum Pools reached',
    only_system_pool: 'System Resource Pool',
    not_for_cloud_servers: 'Not for Cloud Servers',
    not_same_customer: 'Not same customer',
    maintance_mode: 'Maintenance Mode',
    active_customers: 'Show Active Customers Only',
    show_invoice_pdf: 'Show Invoice PDF',
    download_invoice_pdf: 'Download Invoice PDF',
    prepayment_invoice: 'Prepayment Invoice',
    final_invoice: 'Final Invoice',
    billing_feautures: 'Billing Features',
    billing_prices: 'Billing Prices',
    lancom_pay: 'Pay with LancomPay',
    last_update: 'Last Update',
    billing_overview: 'Billing Overview',
    can_not_delete_ipsec_site:
      'Can not delete IPSEC Site because its tunnel is being used by a Static Route.',
    tunnel_used_warning: "A Static Route is using this IPSEC site's tunnel.",
    ipsec_manually_select:
      'Either manually delete any Static Route associated with this IPSEC site.',
    ipsec_automatic_select: 'Or select Automatic Edit below:',
    automatic_edit_desc:
      'If you choose Automatic Edit your Static Routes using the previous tunnel will be automatically changed to the new one.',
    automatic_edit: 'Automatic Edit',
    network_enabled: 'Network Enabled',
    network_disabled: 'Network Disabled',
    enable_network: 'Enable Network',
    disable_network: 'Disable Network',
    verify_contact: 'Verify Contact Number',
    contact_verified: 'Contact Verified',
    verification_code_sent:
      'A verification code is sent to the phone number you provided.',
    fill_code_below: 'Please fill the code below.',
    verification_mail_send: 'Check your e-mail. Verification mail send.',
    information_saved: 'Information saved',
    records_per_page: 'Records per page:',
    delete_snap_auto_at: 'Your snapshot will be automatically deleted at',
    delete_snap_auto_after: 'Your snapshot will be automatically deleted after',
    network_disabled_error_message: 'Network is temporary unavailable. Please contact us at {email} to assist you with the issue.',
    apply_auto_ipsec:
      'Applying now will also apply the related automatic Static Route changes',
    undo_auto_ipsec:
      'Undo changes will also undo the automatic Static Route changes',
    member_statistics: 'Member Statistics',
    download_user_config:
      'If you are using any User configuration files related to the update you have to download them again.',
    download_openvpn: 'Download OpenVPN configuration files',
    days_30_backups: '30 days Backup',
    delete_help_topic: 'Delete Help Topic',
    edit_help_topic: 'Edit Help Topic',
    help_topic_deleted: 'Help Topic Deleted',
    enter_psk_text: 'Enter new Pre-Shared Key value',
    delete_psk: 'Delete Pre-Shared Key',
    delete_psk_confirm:
      'Deleting this Pre-Shared Key will affect all Policy Based Ipsecs in Dynamic Mode',
    edit_psk: 'Edit Pre-Shared Key',
    edit_psk_confirm:
      'This edit will also update the Pre-Shared Key for every Policy Based Ipsecs in Dynamic Mode',
    create_psk: 'Create Pre-Shared Key',
    cannot_delete_psk_confirm:
      'An Ipsec Site is using the Pre-Shared Key so it can not be deleted.',
    key_created: 'Pre-Shared Key Creation Dispatched',
    key_updated: 'Pre-Shared Key Updated',
    key_deleted: 'Pre-Shared Key Deleted',
    apply_before_delete_psk:
      'Apply your changes before deleting the Pre-Shared Key',
    approve_registration_dispatched: 'Approve Registration Dispatched',
    reject_registration_dispatched: 'Reject Registration Dispatched',
    duplicate_registration_dispatched: 'Duplicate Registration Dispatched',
    disabled_firewall: 'Disabled Firewall. Actions are not allowed.',
    get_scopes: 'Get Scopes',
    scopes_fetched: 'Scopes Fetched',
    no_data: 'No Data',
    active_orders: 'Show Active Orders Only',
    inactive_orders: 'Show Inactive Orders',

    cannot_delete_last_private_network_card:
      'Last private network card can not be deleted',
    valid_ipv4_or_fqdn: 'Must be a valid FQDN or IPv4',
    create_resource: 'Create Resource',
    resources_management: 'Resources Management',
    delete_resource: 'Delete Resource',
    delete_resource_confirm: 'Do you want to delete Resource',
    resource_deleted: 'Resource Deleted',
    manage_licenses: 'Manage Licenses',
    associated_licenses: 'Associated Licenses',
    addon_services: 'Addon Services',
    manage_addon_services: 'Manage Addon Services',
    associated_addon_services: 'Associated Addon Services',
    resource_icon: 'Resource Icon',
    default_limit: 'Default Limit',
    license_disassociated: 'License Disassociated',
    license_associated: 'License Associated',
    addon_disassociated: 'Addon Disassociated',
    addon_associated: 'Addon Associated',
    create_license: 'Create License',
    create_addon_service: 'Create Addon Service',
    addon_service: 'Addon Service',
    delete_addon_service: 'Delete Addon Service',
    delete_addon_service_confirm: 'Do you want to delete Addon Service: ',
    delete_license: 'Delete License',
    delete_license_confirm: 'Do you want to delete License: ',
    select_all: 'Select All',
    system_operation_dispatched: 'System Operation Dispatched',
    required_actions: 'Required Actions',
    no_instances_found: 'No Instances found',
    sort_resources: 'Sort Resources',
    edit_internal_note: 'Edit Internal Note',
    user_activated: 'User Activated',
    user_deactivated: 'User Deactivated',
    select_preference: 'Select your preference',
    continue_vat: 'Continue with VAT',
    continue_pylon: 'Continue with Pylon ID',
    continue_custom: 'continue with custom',
    fill_in_info: 'Fill in information',
    commercial_title: 'Commercial Title',
    irs_name: 'IRS Name',
    order_dedicated: 'Order Dedicated Server',
    server_info: 'Server Info',
    not_installed_yet: 'Software not installed yet.',
    installing_software: 'Installing software.',
    package_type: 'Package Type',
    package_type_is_missing: 'Package type is missing',

    overview_financial: 'Financial Overview',
    overview_technical: 'Technical Overview',
    overview_sales: 'Sales Overview',
    audit_txt: 'Select your audit file.',
    help_topics: 'Help Topics',

    enter_emails_and_roles: 'Enter the email and the applied role.',
    edit_role: 'Edit role',
    edit_global_role: 'Edit global role',
    remove_from_customer: 'Remove from customer',
    customer_role: 'Customer Role',
    global_role: 'Global Role',

    delete_resource_group: 'Delete Resource Group',
    delete_resource_group_confirm: 'Do you want to delete Resource Group',
    server_not_ready: 'Server not ready.',
    backups_unavailable:
      'Backups are not currently available at this location.',
    operating_system_availability: 'Operating System Availability',
    disk_image_manager: 'Disk Image Manager',
    delete_disk_image: 'Delete Disk Image',
    delete_disk_image_confirm: 'Do you want to delete disk image',
    disk_image_deleted: 'Disk Image Deleted',
    edit_disk_image: 'Edit Disk Image',
    cpu_tiers_updated: 'CPU Tiers updated successfully.',
    no_available_cpu_tiers: 'There are no CPU tier available to add.',
    no_available_group_options: 'No available group options for this vCenter.',
    settings_updated_successfully: 'Settings Updated Successfully',
    cpu_tier_created_successfully: 'CPU Tier created successfully.',
    cpu_tier_updated_successfully: 'CPU Tier updated successfully.',
    disk_type_created_successfully: 'Disk Type created successfully.',
    disk_type_updated_successfully: 'Disk Type updated successfully.',
    disk_types_sorted_successfully: 'Disk types sorted successfully.',
    delete_disk_type: 'Delete Disk Type',
    delete_disk_type_confirm: 'Do you want to delete the disk type ',
    disk_type_deleted_successfully: 'Disk Type Deleted Successfully.',
    export_customer_instances: 'Export Customer Instances (.xlsx)',
    empty_notes: 'There are no notes.',
    empty_name_servers: 'There are no name servers.',
    server_no_licenses: 'There are no active licenses.',
    server_no_addon_services: 'There are no active addon services.',
    customer_flags_updated: 'Customer Flags updated successfully.',
    feature_flags_updated: 'Feature Flags updated successfully.',
    server_no_ips: 'There are no attached ips.',
    clone_server_confirm: 'Do you want to clone server',
    are_you_sure_clone_server: 'Are you sure you want to clone server',
    server_cloned: 'Server Cloning Dispatched',
    add_dns: 'Add DNS',
    add_record: 'Add Record',
    create_dns_zone: 'Create a new DNS Zone',
    create_dns_record: 'Create a new DNS Zone Record',
    delete_dns_zone: 'Delete DNS Zone',
    delete_dns_zone_confirm: 'Do you want to delete DNS Zone',
    dns_zone_deletion_dispatched: 'DNS Zone delete dispatched',
    dns_zone_deleted: 'DNS Zone deleted',
    confirm_dns_zone_name: 'Please confirm the DNS Zone name',
    records_overview: 'Records Overview',
    records_imported_successfully: 'All records imported successfully!',
    import_dns_record: 'Import DNS Record(s)',
    create_with_file: 'Create with file import',
    delete_dns_zone_record: 'Delete DNS Zone Record',
    delete_dns_zone_record_confirm: 'Do you want to delete Record',
    dns_zone_record_deleted: 'Zone Record Deleted',
    user_notifications_updated: 'User Notifications updated successfully.',
    router_deleted: 'Router Deleted',
    vpnc_deleted: 'VPN Concentrator Deleted',
    add_dns_cluster: 'Add DNS Cluster',
    dns_cluster_created: 'DNS Cluster created successfully',
    dns_cluster_updated: 'DNS Cluster updated successfully',
    delete_dns_cluster: 'Delete DNS Cluster',
    delete_dns_cluster_confirm: 'Do you want to delete the DNS Cluster ',
    dns_cluster_deleted_successfully: 'DNS Cluster Deleted Successfully.',
    add_dns_server: 'Add DNS Server',
    dns_server_updated: 'DNS Server updated successfully',
    delete_dns_server: 'Delete DNS Server',
    delete_dns_server_confirm: 'Do you want to delete the DNS Server ',
    dns_server_deleted_successfully: 'DNS Server Deleted Successfully.',
    add_users: 'Add users',
    vcenter_updated: 'vCenter updated successfully',
    dns_zone_fatal_error_message: `
    DNS Zone is disabled because the last task was not successful.
    Please contact us at {brandSupportLink} to assist you with the issue.
    `,
    dns_zone_disabled_error_message: `
    DNS Zone is temporary unavailable.
     Please contact us at {brandSupportLink} to assist you with the issue.
    `,
    generated_names: 'Auto generate names (e.g. "Name" for Name1, Name2..)',
    user_count: 'User Count',
    create_single: 'Create single user',
    create_multiple: 'Create multiple users',
    add_bucket: 'Add Bucket',
    buckets_not_enough: 'Bucket resources are not enough',
    domain_is_missing: 'Domain is missing',
    application_aware: 'Application-Aware Processing',
    restored_successfully: 'Item restored successfully.',
    restore_item: 'Restore Item',
    confirm_restore_item: 'Do you really want to restore this item? Your data will be overwritten and changes might be lost after',
    delete_object_bucket: 'Delete Object Bucket',
    delete_object_bucket_confirm: 'Do you want to delete Object Bucket',
    object_bucket_deletion_dispatched: 'Object Bucket delete dispatched',
    object_bucket_deleted: 'Object Bucket deleted',
    confirm_object_bucket_name: 'Please confirm the Object Bucket name',
    verification_name_does_not_match_object_bucket:
    'Verification name does not match with Object Bucket name',
    object_bucket_created: 'Object Bucket Creation dispatched',
    create_folder: 'Create Folder',
    privacy_updated: 'Privacy update successfully',
    billing_unavailable: 'Billing Unavailable',
    pdf_preview: 'PDF Preview',
    pdf_download: 'PDF Download',
    expire_date: 'Expire Date',
    no_payment_methods: 'There are no payment methods.',
    restore_to_new_vm: 'Restore to new VM instance',
    subnet_overview: 'Subnet Overview',
    gateway_is_missing: 'Gateway is missing',
    type_is_missing: 'Type is missing',
    datacenter_is_missing: 'Datacenter is missing',
    dns_primary_is_missing: 'Primary DNS is missing',
    dns_secondary_is_missing: 'Secondary DNS is missing',
    datacenter_overview: 'Datacenter Overview',
    resource_created: 'Resource created successfully',
    resource_updated: 'Resource updated successfully',
    resources_order_updated: 'Order updated successfully',
    addon_service_created: 'Addon Service created successfully',
    addon_service_updated: 'Addon Service updated successfully',
    addon_service_deleted: 'Addon Service deleted successfully',
    driver_is_missing: 'Driver is missing',
    host_is_missing: 'Host is missing',
    port_is_missing: 'Port is missing',
    username_is_missing: 'Username is missing',
    password_is_missing: 'Password is missing',
    database_is_missing: 'Database is missing',
    schema_is_missing: 'Schema is missing',
    cluster_is_missing: 'Cluster is missing',
    folder_is_missing: 'Folder is missing',
    recycle_pool_name_is_missing: 'Recycle Pool Name is missing',
    version_is_missing: 'Version is missing',
    sdn_type_is_missing: 'SDN Type is missing',
    select_boot_type: 'Select Boot Type',
    boot_type_update: 'Boot Type update Dispatched',
    key_downloaded: 'This key has already been downloaded',
    public_bucket: 'Objects are publicly readable by anonymous users.',
    private_bucket: 'Objects require key credentials to be accessed.',
    disk_image_updated: 'Disk Image updated',
    disk_image_created: 'Disk Image created',
    location_type: 'Location Type',
    last_transaction_date: 'Last Transaction Date',
    customer_transactions: 'Customer Transactions',
    export_pdf: 'Export PDF',
    export_excel: 'Export Excel',
    reinvite: 'Re-Invite',
    resend: 'Resend Invitation',
    reinvite_successfully: 'The re-invitation was completed successfully.',
    resend_successfully: 'The invitation was successfully resent.'
  },
  graphs: {
    cpuGraph: 'vCPU Usage (%)',
    ramGraph: 'Ram Usage (GB)',
    networkGraph: 'Network Usage (kbps)',
    networkPackets: 'Network Packets',
    diskIO: 'Disk IO (IOPS)',
    network_throughput: 'Network Throughput',
    networkTraffic: 'Network Traffic (KB)',
    latency: 'Latency (ms)',
    dnsTotalGraph: 'Total Requests',
    dnsDomainGraph: 'Requests per Subdomain',
    dnsTypeGraph: 'Requests per Type'
  },
  enums: {
    tasks,
    actions,
    userType: {
      USER_UPDATE: 'User update',
      USER_LOGIN_SUCCESS: 'User login success',
      USER_LOGIN_FAILED: 'User login failed',
      USER_MOBILE_VERIFY: 'User mobile verify',
      USER_SAFE_DEVICE_DELETE: 'User safe device delete',
      TWOFA_ENABLE: 'Two factor enable',
      TWOFA_DISABLE: 'Two factor disable',
      TWOFA_VERIFY: 'Two factor verify',
      USER_PHONE_RESEND_TOKEN: 'User phone resend token',
      USER_ACTIVATED: 'User activated',
      USER_SESSION_DISCONNECT: 'User session disconnect',
      USER_SAFE_DEVICE_CREATED: 'User device created',
      USER_PASSWORD_RESET_REQUESTED: 'User password reset requested',
      USER_EMAIL_VERIFY: 'User e-mail verify'
    }
  },
  transactions: {
    'Είσπραξη από Πελάτη': 'Customer Payment',
    'Τιμολόγιο Παροχής Υπηρεσιών': 'Invoice',
    Τιμολόγιο: 'Invoice',
    'Πιστωτικό Τιμολόγιο Παροχής Υπηρεσιών': 'Credit Invoice',
    'Απόδειξη Παροχής Υπηρεσιών': 'Receipt',
    'Πιστωτικό Τιμολόγιο': 'Credit Invoice',
    'Απόδειξη Πληρωμής': 'Payment Receipt',
    'Συμψηφισμός Πελάτη-Προμηθευτών': 'Customer-Supplier Offset',
    'Απογραφή Χρέωσης Πελάτη': 'Customer Debit Inventory',
    'Απογραφή Πίστωσης Πελάτη': 'Customer Credit Inventory',
    'ΔΕΛΤΙΟ ΕΠΙΣΤΡΟΦΗΣ': 'Return Note',
    'Τιμολόγιο - Δελτίο Αποστολής': 'Invoice - Delivery Note',
    Invoice: 'Invoice',
    'Απόδειξη Επιστροφής Λιανικής Πώλησης': 'Retail Return Receipt',
    'Credit Invoice': 'Credit Invoice',
    'Επιστροφή Αξιογράφων': 'Securities Return',
    'Πίστωση Πελάτη': 'Customer Credit',
    'Χρέωση Πελάτη': 'Customer Debit',
    'Λογαριασμός Τηλεπικοινωνιακών Τελών': 'Telecommunications Fees Invoice',
    'Τιμολόγιο Παροχής Υπηρεσιών (Αυτοτιμολόγηση)': 'Self-Billed Invoice',
    'Απογραφή Χρέωσης Πελάτη Αντιστοιχίσεων': 'Customer Debit Inventory (Reconciliations)',
    'Απογραφή Πίστωσης Πελάτη Αντιστοιχίσεων': 'Customer Credit Inventory (Reconciliations)',
    'Μεταφορά Χρεωστικών Υπολοίπων Πελατών': 'Transfer of Customer Debit Balances',
    'Τιμολόγιο Εσόδων - Παροχής Υπηρεσιών': 'Invoice',
    'Συμψηφισμός Πελάτη-Χρεωστών(Πιστ.Πελ-Χρ.Χρ.)': 'Customer-Debtor Offset (Customer Credit - Debtor Debit)',
    'ΒΕΒ ΠΑΡΑΚΡ ΦΟΡΟΥ': 'Tax Withholding Certificate',
    'Συμψηφισμός Προμηθευτή (Χρ.Προμ -Πιστ.Πελ.)': 'Supplier Offset (Supplier Debit - Customer Credit)',
    'Συμψηφισμός Πελάτη-Πιστωτών(Πιστ.Πελ-Χρ.Πιστ.)': 'Customer-Creditor Offset (Customer Credit - Creditor Debit)',
    Proforma: 'Proforma'
  }
}
