export const UserInviteResponsesEnum = {
  ALREADY_INVITED: 'ALREADY_INVITED',
  OTHER_COMPANY: 'OTHER_COMPANY',
  UNVALID_MAIL: 'UNVALID_MAIL',
  ACTIVATED: 'ACTIVATED',
  INVITED: 'INVITED'
}

export const YesNoEnum = {
  YES: 'YES',
  NO: 'NO'
}

export const DiskTypeEnum = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY'
}

export const CloudServerStatusEnum = {
  PENDING: 'PENDING',
  DONE: 'DONE',
  FAILED: 'FAILED',
  PROCESSED: 'PROCESSED',
  CREATING: 'CREATING',
  PROVISIONING: 'PROVISIONING',
  PROCESSING: 'PROCESSING'
}

export const LocationTypeEnum = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
}

export const CustomerTypeEnum = {
  RESELLER: 'RESELLER',
  CUSTOMER: 'CUSTOMER'
}

export const CustomerStatusEnum = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
}

export const StorageAccountServiceTypeEnum = {
  FTP: 'FTP',
  SFTP: 'SFTP'
}

export const UserStatusEnum = {
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
  DEACTIVATED: 'DEACTIVATED',
  BANNED: 'BANNED'
}

export const VcenterTypeEnum = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
}

export const CpuTierTypeEnum = {
  PUBLIC: 'Public',
  PRIVATE: 'Private'
}

export const NetworkTypeEnum = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  UNASSIGNED: 'unassigned'
}

export const DNSRecordTypeEnum = {
  A: 'A',
  AAAA: 'AAAA',
  CAA: 'CAA',
  CNAME: 'CNAME',
  MX: 'MX',
  NS: 'NS',
  SRV: 'SRV',
  TXT: 'TXT'
}

export const InstanceTypeEnum = {
  SERVER: 'server',
  ROUTER: 'router',
  VPNC: 'vpnc'
}

export const NatTypeEnum = {
  DNAT: 'DNAT',
  SNAT: 'SNAT'
}

export const ProtocolEnum = {
  ANY: 'ANY',
  ICMP: 'ICMP',
  TCP: 'TCP',
  UDP: 'UDP',
  GRE: 'GRE'
}

// Task Types
export const CloudServerTaskType = {
  CLOUD_SERVER_CREATE: 'CLOUD_SERVER_CREATE',
  CLOUD_SERVER_DELETE: 'CLOUD_SERVER_DELETE',
  CLOUD_SERVER_POWER: 'CLOUD_SERVER_POWER',
  CLOUD_SERVER_REINSTALL: 'CLOUD_SERVER_REINSTALL',
  CLOUD_SERVER_UNMOUNT: 'CLOUD_SERVER_UNMOUNT',
  CLOUD_SERVER_MOUNT: 'CLOUD_SERVER_MOUNT',
  CLOUD_SERVER_RESCALE: 'CLOUD_SERVER_RESCALE',
  CLOUD_SERVER_ENABLE_VNC: 'CLOUD_SERVER_ENABLE_VNC',
  CLOUD_SERVER_SNAPSHOT_CREATE: 'CLOUD_SERVER_SNAPSHOT_CREATE',
  CLOUD_SERVER_SNAPSHOT_DELETE: 'CLOUD_SERVER_SNAPSHOT_DELETE',
  CLOUD_SERVER_SNAPSHOT_RESTORE: 'CLOUD_SERVER_SNAPSHOT_RESTORE',
  CLOUD_SERVER_NETWORK_CARD_CHANGE: 'CLOUD_SERVER_NETWORK_CARD_CHANGE',
  CLOUD_SERVER_NETWORK_CARD_CREATE: 'CLOUD_SERVER_NETWORK_CARD_CREATE',
  CLOUD_SERVER_NETWORK_CARD_DELETE: 'CLOUD_SERVER_NETWORK_CARD_DELETE',
  CLOUD_SERVER_NETWORK_CARD_CONNECT: 'CLOUD_SERVER_NETWORK_CARD_CONNECT',
  CLOUD_SERVER_NETWORK_CARD_DISCONNECT: 'CLOUD_SERVER_NETWORK_CARD_DISCONNECT',
  CLOUD_SERVER_BACKUP_DISABLE: 'CLOUD_SERVER_BACKUP_DISABLE',
  CLOUD_SERVER_BACKUP_ENABLE: 'CLOUD_SERVER_BACKUP_ENABLE',
  CLOUD_SERVER_BACKUP_RESTORE: 'CLOUD_SERVER_BACKUP_RESTORE',
  CLOUD_SERVER_BACKUP_UPDATE: 'CLOUD_SERVER_BACKUP_UPDATE',
  CLOUD_SERVER_BACKUP_RESTORE_DISKS: 'CLOUD_SERVER_BACKUP_RESTORE_DISKS',
  CLOUD_SERVER_FIREWALL_RULES_APPLY: 'CLOUD_SERVER_FIREWALL_RULES_APPLY',
  CLOUD_SERVER_FIREWALL_RULES_UNDO: 'CLOUD_SERVER_FIREWALL_RULES_UNDO',
  CLOUD_SERVER_IMPORT: 'CLOUD_SERVER_IMPORT',
  CLOUD_SERVER_ENABLE_RESCUE_MODE: 'CLOUD_SERVER_ENABLE_RESCUE_MODE',
  CLOUD_SERVER_DISABLE_RESCUE_MODE: 'CLOUD_SERVER_DISABLE_RESCUE_MODE',
  CLOUD_SERVER_DISK_MIGRATE: 'CLOUD_SERVER_DISK_MIGRATE',
  CLOUD_SERVER_RESOURCE_POOL_MOVE: 'CLOUD_SERVER_RESOURCE_POOL_MOVE',
  CLOUD_SERVER_CROSS_MIGRATE: 'CLOUD_SERVER_CROSS_MIGRATE',
  CLOUD_SERVER_CLONE: 'CLOUD_SERVER_CLONE',
  CLOUD_SERVER_ADVANCED_CONFIGURATION: 'CLOUD_SERVER_ADVANCED_CONFIGURATION',
  CLOUD_SERVER_BACKUP_FILE_RESTORE: 'CLOUD_SERVER_BACKUP_FILE_RESTORE',
  CLOUD_SERVER_BOOT_TYPE_CHANGE: 'CLOUD_SERVER_BOOT_TYPE_CHANGE'
}

export const CloudDiskTaskType = {
  CLOUD_SERVER_DISK_CREATE: 'CLOUD_SERVER_DISK_CREATE',
  CLOUD_SERVER_DISK_CHANGE_TYPE: 'CLOUD_SERVER_DISK_CHANGE_TYPE',
  CLOUD_SERVER_DISK_DELETE: 'CLOUD_SERVER_DISK_DELETE',
  CLOUD_SERVER_DISK_EXTEND: 'CLOUD_SERVER_DISK_EXTEND',
  CLOUD_SERVER_DISK_MOVE: 'CLOUD_SERVER_DISK_MOVE'
}

export const IpAddressTaskType = {
  IP_ADDRESS_ASSIGN: 'IP_ADDRESS_ASSIGN',
  IP_ADDRESS_MOVE: 'IP_ADDRESS_MOVE',
  IP_ADDRESS_RELEASE: 'IP_ADDRESS_RELEASE',
  IP_ADDRESS_SET_REVERSE: 'IP_ADDRESS_SET_REVERSE'
}

export const NetworkTaskType = {
  PRIVATE_NETWORK_CREATE: 'PRIVATE_NETWORK_CREATE',
  PRIVATE_NETWORK_DELETE: 'PRIVATE_NETWORK_DELETE'
}

export const FirewallTaskType = {
  UNDO_CLOUDSERVER_FIREWALL_RULES: 'UNDO_CLOUDSERVER_FIREWALL_RULES',
  APPLY_CLOUDSERVER_FIREWALL_RULES: 'APPLY_CLOUDSERVER_FIREWALL_RULES'
}

export const RouterTaskType = {
  ROUTER_CREATE: 'ROUTER_CREATE',
  ROUTER_DELETE: 'ROUTER_DELETE',
  ROUTER_FIREWALL_APPLY: 'ROUTER_FIREWALL_APPLY',
  ROUTER_FIREWALL_UNDO: 'ROUTER_FIREWALL_UNDO',
  ROUTER_IPSEC_APPLY: 'ROUTER_IPSEC_APPLY',
  ROUTER_IPSEC_UNDO: 'ROUTER_IPSEC_UNDO',
  ROUTER_LOAD_BALANCER_CREATE: 'ROUTER_LOAD_BALANCER_CREATE',
  ROUTER_LOAD_BALANCER_DELETE: 'ROUTER_LOAD_BALANCER_DELETE',
  ROUTER_LOAD_BALANCER_EDIT: 'ROUTER_LOAD_BALANCER_EDIT',
  ROUTER_LOAD_BALANCER_EDIT_MEMBERS: 'ROUTER_LOAD_BALANCER_EDIT_MEMBERS',
  ROUTER_ADD_NETWORK_CARD: 'ROUTER_ADD_NETWORK_CARD',
  ROUTER_NETWORK_CARD_DELETE: 'ROUTER_NETWORK_CARD_DELETE',
  ROUTER_NETWORK_CARD_UPDATE: 'ROUTER_NETWORK_CARD_UPDATE',
  ROUTER_RESCALE: 'ROUTER_RESCALE',
  ROUTER_STATIC_ROUTE_APPLY: 'ROUTER_STATIC_ROUTE_APPLY',
  ROUTER_STATIC_ROUTE_UNDO: 'ROUTER_STATIC_ROUTE_UNDO',
  ROUTER_ADD_CERTIFICATE: 'ROUTER_ADD_CERTIFICATE',
  ROUTER_DELETE_CERTIFICATE: 'ROUTER_DELETE_CERTIFICATE',
  ROUTER_NAT_APPLY: 'ROUTER_NAT_APPLY',
  ROUTER_NAT_UNDO: 'ROUTER_NAT_UNDO',
  ROUTER_REVERSE_PATH_UPDATE: 'ROUTER_REVERSE_PATH_UPDATE',
  ROUTER_GATEWAY_APPLY: 'ROUTER_GATEWAY_APPLY'
}

export const StorageAccountTaskType = {
  FTP_ACCOUNT_CREATE: 'FTP_ACCOUNT_CREATE',
  FTP_ACCOUNT_EDIT_PASSWORD: 'FTP_ACCOUNT_EDIT_PASSWORD',
  FTP_ACCOUNT_DELETE: 'FTP_ACCOUNT_DELETE',
  FTP_ACCOUNT_DISABLE: 'FTP_ACCOUNT_DISABLE',
  FTP_ACCOUNT_RESIZE: 'FTP_ACCOUNT_RESIZE'
}

export const VpnConcentratorTaskType = {
  VPN_CONCENTRATOR_CREATE: 'VPN_CONCENTRATOR_CREATE',
  VPN_CONCENTRATOR_DELETE: 'VPN_CONCENTRATOR_DELETE',
  VPN_CONCENTRATOR_POOL_CREATE: 'VPN_CONCENTRATOR_POOL_CREATE',
  VPN_CONCENTRATOR_POOL_EDIT: 'VPN_CONCENTRATOR_POOL_EDIT',
  VPN_CONCENTRATOR_POOL_DELETE: 'VPN_CONCENTRATOR_POOL_DELETE',
  VPN_CONCENTRATOR_PROFILE_CREATE: 'VPN_CONCENTRATOR_PROFILE_CREATE',
  VPN_CONCENTRATOR_PROFILE_DELETE: 'VPN_CONCENTRATOR_PROFILE_DELETE',
  VPN_CONCENTRATOR_USER_CREATE: 'VPN_CONCENTRATOR_USER_CREATE',
  VPN_CONCENTRATOR_USER_EDIT: 'VPN_CONCENTRATOR_USER_EDIT',
  VPN_CONCENTRATOR_USER_DELETE: 'VPN_CONCENTRATOR_USER_DELETE',
  VPN_CONCENTRATOR_NETWORK_CARD_CREATE: 'VPN_CONCENTRATOR_NETWORK_CARD_CREATE',
  VPN_CONCENTRATOR_NETWORK_CARD_DELETE: 'VPN_CONCENTRATOR_NETWORK_CARD_DELETE',
  VPN_CONCENTRATOR_NETWORK_CARD_EDIT: 'VPN_CONCENTRATOR_NETWORK_CARD_EDIT',
  VPN_CONCENTRATOR_PROFILE_EDIT: 'VPN_CONCENTRATOR_PROFILE_EDIT',
  VPN_CONCENTRATOR_STATIC_ROUTE_CREATE: 'VPN_CONCENTRATOR_STATIC_ROUTE_CREATE',
  VPN_CONCENTRATOR_STATIC_ROUTE_UPDATE: 'VPN_CONCENTRATOR_STATIC_ROUTE_UPDATE',
  VPN_CONCENTRATOR_STATIC_ROUTE_DELETE: 'VPN_CONCENTRATOR_STATIC_ROUTE_DELETE',
  VPN_CONCENTRATOR_REGENERATE_CONFIG: 'VPN_CONCENTRATOR_REGENERATE_CONFIG',
  VPN_CONCENTRATOR_NETWORK_CARD_CONNECT: 'VPN_CONCENTRATOR_NETWORK_CARD_CONNECT'
}

export const DnsTaskType = {
  DNS_ZONE_CREATE: 'DNS_ZONE_CREATE',
  DNS_ZONE_DELETE: 'DNS_ZONE_DELETE',
  DNS_ZONE_APPLY: 'DNS_ZONE_APPLY',
  DNS_ZONE_UNDO: 'DNS_ZONE_UNDO'
}

export const ObjectBucketTaskType = {
  STORAGE_OBJECT_CREATE: 'STORAGE_OBJECT_CREATE',
  STORAGE_OBJECT_DELETE: 'STORAGE_OBJECT_DELETE',
  STORAGE_OBJECT_KEY_CREATE: 'STORAGE_OBJECT_KEY_CREATE',
  STORAGE_OBJECT_KEY_DELETE: 'STORAGE_OBJECT_KEY_DELETE',
  STORAGE_OBJECT_EDIT_QUOTA: 'STORAGE_OBJECT_EDIT_QUOTA',
  STORAGE_OBJECT_EDIT_PRIVACY: 'STORAGE_OBJECT_EDIT_PRIVACY',
  OBJECT_BUCKET_FOLDER_DELETE: 'OBJECT_BUCKET_FOLDER_DELETE'
}
// Task Status

export const Models = {
  CLOUD_SERVER: 'App\\Models\\CloudServer',
  DEDICATED_SERVER: 'App\\Models\\DedicatedServer',
  NETWORK: 'App\\Models\\Network',
  DISK: 'App\\Models\\CloudServerDisk',
  IP_ADDRESS: 'App\\Models\\IpAddress',
  ROUTER: 'App\\Models\\Router',
  VMWARE_VM: 'App\\Models\\VmwareVm',
  VPN_CONCENTRATOR: 'App\\Models\\VpnConcentrator',
  STORAGE_ACCOUNT: 'App\\Models\\StorageAccount',
  SYSTEM_OPERATIONS: 'App\\Models\\SystemOperation',
  CUSTOMER: 'App\\Models\\Customer',
  USER: 'App\\Models\\User',
  DNS: 'App\\Models\\DnsZone',
  OBJECT_BUCKET: 'App\\Models\\ObjectBucket'
}

// task_status
// geniko status tou opoioudipote task
export const TasksStatusEnum = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  PROCESSING: 'PROCESSING',
  DISABLING: 'DISABLING'
}

export const DiskStatusEnum = {
  PENDING: 'PENDING',
  DETACHED: 'DETACHED',
  ATTACHED: 'ATTACHED',
  PROVISIONING: 'PROVISIONING',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING'
}

// Error Messages
export const ErrorMessages = {
  NO_LAST_NETWORK_CARD_DELETE: 'NO_LAST_NETWORK_CARD_DELETE',
  NO_PUBLIC_IP_AVAILABLE: 'NO_PUBLIC_IP_AVAILABLE',
  NO_AVAILABLE_VNC_PORT: 'NO_AVAILABLE_VNC_PORT',
  UNKNOWN_SPOOFGUARD_POLICY: 'UNKNOWN_SPOOFGUARD_POLICY',
  NO_PUBLIC_NETWORK: 'NO_PUBLIC_NETWORK',
  UNKNOWN_STATE: 'UNKNOWN_STATE',
  NO_ACCESS_SSH_KEY: 'NO_ACCESS_SSH_KEY',
  NO_ACCESS_TARGET_CLOUD_SERVER: 'NO_ACCESS_TARGET_CLOUD_SERVER',
  DIFFERENT_SERVER_LOCATION: 'DIFFERENT_SERVER_LOCATION',
  DIFFERENT_OPERATING_SYSTEM: 'DIFFERENT_OPERATING_SYSTEM',
  SINGLE_PUBLIC_ALLOWED: 'SINGLE_PUBLIC_ALLOWED',
  PUBLIC_CARD_NO_PUBLIC_NETWORK: 'PUBLIC_CARD_NO_PUBLIC_NETWORK',
  INVALID_SERVER: 'INVALID_SERVER',
  IP_AVAILABLE: 'IP_AVAILABLE',
  UNAVAILABLE_DATACENTER: 'UNAVAILABLE_DATACENTER',
  NOT_READY: 'NOT_READY',
  NO_SERVER_TO_ATTACH: 'NO_SERVER_TO_ATTACH',
  IPBLOCK_IN_USE: 'IPBLOCK_IN_USE',
  NETWORK_IN_USE: 'NETWORK_IN_USE',
  NO_SAME_PASSWORD: 'NO_SAME_PASSWORD',
  WRONG_OLD_PASSWORD: 'WRONG_OLD_PASSWORD',
  RESTRICTED_ACTION: 'RESTRICTED_ACTION',
  PASSWORD_INSECURE: 'PASSWORD_INSECURE',
  INVALID_TOKEN: 'INVALID_TOKEN',
  VALIDATION_REQUIRED: 'VALIDATION_REQUIRED',
  VALIDATION_TYPE_STRING: 'VALIDATION_TYPE_STRING',
  VALIDATION_TYPE_FILE: 'VALIDATION_TYPE_FILE',
  VALIDATION_MAX: 'VALIDATION_MAX',
  VALIDATION_TYPE_ARRAY: 'VALIDATION_TYPE_ARRAY',
  VALIDATION_TYPE_BOOLEAN: 'VALIDATION_TYPE_BOOLEAN',
  VALIDATION_NOT_EXISTS: 'VALIDATION_NOT_EXISTS',
  VALIDATION_REGEX_MISMATCH: 'VALIDATION_REGEX_MISMATCH',
  VALIDATION_CONFIRM_MISMATC: 'VALIDATION_CONFIRM_MISMATC',
  VALIDATION_MIN: 'VALIDATION_MIN',
  VALIDATION_CONDITIONAL_REQ: 'VALIDATION_CONDITIONAL_REQ',
  VALIDATION_UNKNOWN_VALUE: 'VALIDATION_UNKNOWN_VALUE',
  VALIDATION_TYPE_INTEGER: 'VALIDATION_TYPE_INTEGER',
  VALIDATION_DIGIT_RANGE: 'VALIDATION_DIGIT_RANGE',
  VALIDATION_STARTS_WITH: 'VALIDATION_STARTS_WITH',
  VALIDATION_PRESENT: 'VALIDATION_PRESENT',
  VALIDATION_TYPE_EMAIL: 'VALIDATION_TYPE_EMAIL',
  VALIDATION_TYPE_DATE: 'VALIDATION_TYPE_DATE',
  VALIDATION_DATE_AFTER: 'VALIDATION_DATE_AFTER',
  VALIDATION_TYPE_NUMERIC: 'VALIDATION_TYPE_NUMERIC',
  UNKNOWN: 'UNKNOWN',
  UNKNOWN_INSTANCE_TYPE: 'UNKNOWN_INSTANCE_TYPE',
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  CANNOT_ALLOCATE_IP: 'CANNOT_ALLOCATE_IP',
  NO_SUBNET_AVAILABLE: 'NO_SUBNET_AVAILABLE',
  NETWORKS_NOT_ARRAY: 'NETWORKS_NOT_ARRAY',
  PRICING_OVERRIDE_ERROR: 'PRICING_OVERRIDE_ERROR',
  UNKNOWN_PRODUCT_PRICE_TYPE: 'UNKNOWN_PRODUCT_PRICE_TYPE',
  RESOURCE_VALUE_ERROR: 'RESOURCE_VALUE_ERROR',
  SMS_KEY_NOT_CONFIGURED: 'SMS_KEY_NOT_CONFIGURED',
  NO_AVAILABLE_DATASTORE: 'NO_AVAILABLE_DATASTORE',
  FETCH_SPOOFGUARD_POLICIES_ERROR: 'FETCH_SPOOFGUARD_POLICIES_ERROR',
  BACKUPS_ALREADY_ENABLED: 'BACKUPS_ALREADY_ENABLED',
  BACKUPS_UNAVAILABLE_ON_LOCATION: 'BACKUPS_UNAVAILABLE_ON_LOCATION',
  BACKUPS_ALREADY_DISABLED: 'BACKUPS_ALREADY_DISABLED',
  BACKUPS_DISABLED: 'BACKUPS_DISABLED',
  WRONG_RESTORE_POINT: 'WRONG_RESTORE_POINT',
  BACKUP_SERVER_UNAVAILABLE: 'BACKUP_SERVER_UNAVAILABLE',
  BACKUP_JOB_RUNNING: 'BACKUP_JOB_RUNNING',
  DISK_SIZE_IS_LESS_THAN_THE_MINIMUM: 'DISK_SIZE_IS_LESS_THAN_THE_MINIMUM',
  NAME_ALREADY_IN_USE: 'NAME_ALREADY_IN_USE',
  INVALID_IP_ADDRESS: 'INVALID_IP_ADDRESS',
  GATEWAY_DOES_NOT_BELONG_TO_NETWORK: 'GATEWAY_DOES_NOT_BELONG_TO_NETWORK',
  SERVER_EXISTS_IN_LOAD_BALANCER: 'SERVER_EXISTS_IN_LOAD_BALANCER',
  SERVER_MUST_POWERED_OFF: 'SERVER_MUST_POWERED_OFF',
  CLOUD_SERVER_NOT_POWERED_OFF: 'CLOUD_SERVER_NOT_POWERED_OFF',
  OPERATING_SYSTEM_MISMATCH: 'OPERATING_SYSTEM_MISMATCH',
  VNC_ALREADY_SET: 'VNC_ALREADY_SET',
  NO_DELETE_PRIMARY: 'NO_DELETE_PRIMARY',
  INVALID_LOCATION: 'INVALID_LOCATION',
  UNKNOWN_LOCATION: 'UNKNOWN_LOCATION',
  NO_DISK_IMAGE_MOUNTED: 'NO_DISK_IMAGE_MOUNTED',
  NETWORK_ALREADY_CONNECTED: 'NETWORK_ALREADY_CONNECTED',
  NO_PUBLIC_NETWORK_CARD: 'NO_PUBLIC_NETWORK_CARD',
  ATTACHABLE_BUSY: 'ATTACHABLE_BUSY',
  ROUTER_UPLINK_IS_PRIVATE_NETWORK: 'ROUTER_UPLINK_IS_PRIVATE_NETWORK',
  SAME_SOURCE_DESTINATION_INSTANCE: 'SAME_SOURCE_DESTINATION_INSTANCE',
  ROUTER_HAS_ONLY_ONE_IP_ADDRESS: 'ROUTER_HAS_ONLY_ONE_IP_ADDRESS',
  IP_SEC_USES_THIS_IP_ADDRESS: 'IP_SEC_USES_THIS_IP_ADDRESS',
  LOAD_BALANCER_USES_THIS_IP_ADDRESS: 'LOAD_BALANCER_USES_THIS_IP_ADDRESS',
  INVALID_IP_ADDRESS_IN_PRIVATE_NETWORK:
    'INVALID_IP_ADDRESS_IN_PRIVATE_NETWORK',
  NETWORK_OVERLAP: 'NETWORK_OVERLAP',
  GATEWAY_CANNOT_MATCH_WITH_CIDR: 'GATEWAY_CANNOT_MATCH_WITH_CIDR',
  ADD_CERTIFICATE_ERROR: 'ADD_CERTIFICATE_ERROR',
  DELETE_CERTIFICATE_ERROR: 'DELETE_CERTIFICATE_ERROR',
  CANNOT_HAVE_MORE_NETWORK_CARDS: 'CANNOT_HAVE_MORE_NETWORK_CARDS',
  CANNOT_CONNECT_NETWORK_CARD_TO_SAME_NETWORK:
    'CANNOT_CONNECT_NETWORK_CARD_TO_SAME_NETWORK',
  CANNOT_CONFIGURE_SAME_SUBNET: 'CANNOT_CONFIGURE_SAME_SUBNET',
  OVERLAPPING_NETWORK_ADDRESS_1: 'OVERLAPPING_NETWORK_ADDRESS_1',
  OVERLAPPING_NETWORK_ADDRESS_2: 'OVERLAPPING_NETWORK_ADDRESS_2',
  SECONDARY_IP_DOES_NOT_BELONG_TO_NETWORK:
    'SECONDARY_IP_DOES_NOT_BELONG_TO_NETWORK',
  NETWORK_CARD_TYPE_MISSMATCH_WITH_NETWORK_TYPE:
    'NETWORK_CARD_TYPE_MISSMATCH_WITH_NETWORK_TYPE',
  IP_SEC_USES_UPLINK_IP_ADDRESS: 'IP_SEC_USES_UPLINK_IP_ADDRESS',
  LOAD_BALANCER_USES_UPLINK_IP_ADDRESS: 'LOAD_BALANCER_USES_UPLINK_IP_ADDRESS',
  CANNOT_EDIT_PUBLIC_NETWORK_WITH_ANOTHER_ONE:
    'CANNOT_EDIT_PUBLIC_NETWORK_WITH_ANOTHER_ONE',
  SECONDARY_IPS_NOT_ALLOWED: 'SECONDARY_IPS_NOT_ALLOWED',
  NETWORK_TYPE_MISSMATCH: 'NETWORK_TYPE_MISSMATCH',
  NETWORK_ADDRESS_CONFLICT: 'NETWORK_ADDRESS_CONFLICT',
  OVERLAPPING_NETWORK_ADDRESS: 'OVERLAPPING_NETWORK_ADDRESS',
  NETWORK_CARD_IN_USE_FOR_LOAD_BALANCER:
    'NETWORK_CARD_IN_USE_FOR_LOAD_BALANCER',
  NETWORK_CARD_IN_USE_FOR_IP_SEC: 'NETWORK_CARD_IN_USE_FOR_IP_SEC',
  NETWORK_CARD_IN_USE_FOR_STATIC_ROUTING:
    'NETWORK_CARD_IN_USE_FOR_STATIC_ROUTING',
  UPLINK_NETWORK_CARD_MUST_BE_PUBLIC: 'UPLINK_NETWORK_CARD_MUST_BE_PUBLIC',
  REMOTE_ENDPOINT_CANNOT_MATCH_LOCAL_ENDPOINT:
    'REMOTE_ENDPOINT_CANNOT_MATCH_LOCAL_ENDPOINT',
  REMOTE_ENDPOINT_ALREADY_EXISTS: 'REMOTE_ENDPOINT_ALREADY_EXISTS',
  REMOTE_ENDPOINT_IS_USED_IN_ROUTER: 'REMOTE_ENDPOINT_IS_USED_IN_ROUTER',
  NOT_VALID_CIDR: 'NOT_VALID_CIDR',
  INVALID_IP_PRIVATE_NETWORK_SUBNET: 'INVALID_IP_PRIVATE_NETWORK_SUBNET',
  INVALID_PUBLIC_SUBNET: 'INVALID_PUBLIC_SUBNET',
  LOCAL_SUBNET_MUST_BE_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
    'LOCAL_SUBNET_MUST_BE_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE',
  INVALID_REMOTE_SUBNET: 'INVALID_REMOTE_SUBNET',
  REMOTE_SUBNET_ALREADY_EXISTS: 'REMOTE_SUBNET_ALREADY_EXISTS',
  REMOTE_SUBNET_CANNOT_OVERLAP_PRIVATE_NETWORKS:
    'REMOTE_SUBNET_CANNOT_OVERLAP_PRIVATE_NETWORKS',
  LOADBALANCER_INVALID_IP_ADDRESS: 'LOADBALANCER_INVALID_IP_ADDRESS',
  LOADBALANCER_DUPLICATE_PORTS_ERROR: 'LOADBALANCER_DUPLICATE_PORTS_ERROR',
  INVALID_IP_ADDRESS_FOR_SERVICE: 'INVALID_IP_ADDRESS_FOR_SERVICE',
  UNABLE_TO_FETCH_DATA: 'UNABLE_TO_FETCH_DATA',
  SOURCE_IP_MUST_BE_SPECIFIED: 'SOURCE_IP_MUST_BE_SPECIFIED',
  DESTINATION_IP_MUST_BE_SPECIFIED: 'DESTINATION_IP_MUST_BE_SPECIFIED',
  TRANSLATED_IP_MUST_BE_SPECIFIED: 'TRANSLATED_IP_MUST_BE_SPECIFIED',
  INVALID_SOURCE_IP_ADDRESS_FORMAT: 'INVALID_SOURCE_IP_ADDRESS_FORMAT',
  INVALID_DESTINATION_IP_ADDRESS_FORMAT:
    'INVALID_DESTINATION_IP_ADDRESS_FORMAT',
  INVALID_PREFIX: 'INVALID_PREFIX',
  SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
    'SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE',
  SOURCE_IP_STATIC_ROUTE_NO_NEXT_HOP_IN_APPLIED_ON:
    'SOURCE_IP_STATIC_ROUTE_NO_NEXT_HOP_IN_APPLIED_ON',
  SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK:
    'SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK',
  SOURCE_ADDRESS_AND_DESTINATION_ADDRESS_CANNOT_BELONG_TO_THE_SAME_NETWORK:
    'SOURCE_ADDRESS_AND_DESTINATION_ADDRESS_CANNOT_BELONG_TO_THE_SAME_NETWORK',
  DESTINATION_IP_NO_STATIC_NEXT_HOP_IN_SOURCE_NETWORK:
    'DESTINATION_IP_NO_STATIC_NEXT_HOP_IN_SOURCE_NETWORK',
  INVALID_TRANSLATED_IP: 'INVALID_TRANSLATED_IP',
  TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
    'TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE',
  TRANSLATED_IP_STATIC_NEXT_HOP_IS_USED_IN_APPLIED_NETWORK:
    'TRANSLATED_IP_STATIC_NEXT_HOP_IS_USED_IN_APPLIED_NETWORK',
  TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK:
    'TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK',
  NEXT_HOP_IS_NOT_USED_IN_APPLIED_NETWORK:
    'NEXT_HOP_IS_NOT_USED_IN_APPLIED_NETWORK',
  INVALID_DESTINATION_IP: 'INVALID_DESTINATION_IP',
  UNKNOWN_NAT_TYPE: 'UNKNOWN_NAT_TYPE',
  INVALID_SOURCE_PORT_FORMAT: 'INVALID_SOURCE_PORT_FORMAT',
  INVALID_SOURCE_PORT_VALUES: 'INVALID_SOURCE_PORT_VALUES',
  INVALID_SOURCE_PORT_VALUE: 'INVALID_SOURCE_PORT_VALUE',
  INVALID_DESTINATION_PORT_FORMAT: 'INVALID_DESTINATION_PORT_FORMAT',
  INVALID_DESTINATION_PORT_VALUES: 'INVALID_DESTINATION_PORT_VALUES',
  INVALID_DESTINATION_PORT_VALUE: 'INVALID_DESTINATION_PORT_VALUE',
  INVALID_TRANSLATED_PORT_FORMAT: 'INVALID_TRANSLATED_PORT_FORMAT',
  INVALID_TRANSLATED_PORT_VALUES: 'INVALID_TRANSLATED_PORT_VALUES',
  INVALID_TRANSLATED_PORT_VALUE: 'INVALID_TRANSLATED_PORT_VALUE',
  INVALID_NETWORK_ADDRESS: 'INVALID_NETWORK_ADDRESS',
  NETWORK_ADDRESS_MATCHES_UPLINK_INTERFACE:
    'NETWORK_ADDRESS_MATCHES_UPLINK_INTERFACE',
  INVALID_NEXT_HOP_ADDRESS: 'INVALID_NEXT_HOP_ADDRESS',
  NETWORK_ADDRESS_CONFLICT_WITH_NETWORK_CARD:
    'NETWORK_ADDRESS_CONFLICT_WITH_NETWORK_CARD',
  NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_NETWORK_CARD:
    'NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_NETWORK_CARD',
  STATIC_ROUTE_IS_IN_USE_FOR_AN_IP_SEC_SITE:
    'STATIC_ROUTE_IS_IN_USE_FOR_AN_IP_SEC_SITE',
  STORAGE_SERVER_INACTIVE: 'STORAGE_SERVER_INACTIVE',
  NO_DIFFERENCE_IN_ACCOUNT_SIZE: 'NO_DIFFERENCE_IN_ACCOUNT_SIZE',
  CANNOT_RESIZE_ACCOUNT_DUE_TO_ITS_CURRENT_USAGE:
    'CANNOT_RESIZE_ACCOUNT_DUE_TO_ITS_CURRENT_USAGE',
  INVALID_FOLDER_FORMAT: 'INVALID_FOLDER_FORMAT',
  FILE_NO_EXISTS: 'FILE_NO_EXISTS',
  FOLDER_ALREADY_EXISTS: 'FOLDER_ALREADY_EXISTS',
  FOLDER_NO_EXISTS: 'FOLDER_NO_EXISTS',
  FORBIDDEN_IP_ADDRESS: 'FORBIDDEN_IP_ADDRESS',
  TWOFA_ALREADY_ENABLED: 'TWOFA_ALREADY_ENABLED',
  TWOFA_NOT_ENABLED: 'TWOFA_NOT_ENABLED',
  TWOFA_INVALID: 'TWOFA_INVALID',
  TWOFA_NOT_PENDING: 'TWOFA_NOT_PENDING',
  PHONE_ALREADY_VERIFIED: 'PHONE_ALREADY_VERIFIED',
  SMS_SEND_ERROR: 'SMS_SEND_ERROR',
  INVALID_NETWORK: 'INVALID_NETWORK',
  INVALID_NETWORK_PREFIX: 'INVALID_NETWORK_PREFIX',
  EMAIL_ALREADY_VERIFIED: 'EMAIL_ALREADY_VERIFIED',
  INVALID_SMS_TOKEN: 'INVALID_SMS_TOKEN',
  INVALID_EMAIL_TOKEN: 'INVALID_EMAIL_TOKEN',
  INVALID_SMS_TOKEN_EXPIRED: 'INVALID_SMS_TOKEN_EXPIRED',
  INVALID_EMAIL_TOKEN_EXPIRED: 'INVALID_EMAIL_TOKEN_EXPIRED',
  INVALID_FORMAT: 'INVALID_FORMAT',
  LEAD_REGISTRATION_NOT_ENABLED: 'LEAD_REGISTRATION_NOT_ENABLED',
  PRODUCT_PRICING_EXISTS: 'PRODUCT_PRICING_EXISTS',
  BUSY: 'BUSY',
  BACKUP_RUNNING: 'BACKUP_RUNNING',
  SERVER_READONLY: 'SERVER_READONLY',
  SERVER_ACTIVE_SNAPSHOT: 'SERVER_ACTIVE_SNAPSHOT',
  ANOTHER_DISK_IMAGE_IS_ALREADY_MOUNTED:
    'ANOTHER_DISK_IMAGE_IS_ALREADY_MOUNTED',
  CLOUD_SERVER_IS_NOT_IN_RESCUE_MODE: 'CLOUD_SERVER_IS_NOT_IN_RESCUE_MODE',
  SERVER_IS_IN_RESCUE_MODE: 'SERVER_IS_IN_RESCUE_MODE',
  SERVER_IS_PROTECTED: 'SERVER_IS_PROTECTED',
  ROUTER_IS_PROTECTED: 'ROUTER_IS_PROTECTED',
  CANNOT_DELETE_ADMIN_FIREWALL_RULE: 'CANNOT_DELETE_ADMIN_FIREWALL_RULE',
  VPN_NOT_DEPLOYED: 'VPN_NOT_DEPLOYED',
  INVALID_IP_ADDRESS_RANGE: 'INVALID_IP_ADDRESS_RANGE',
  LOCAL_ADDRESS_MUST_BE_THE_FIRST_IP_OF_THE_POOL:
    'LOCAL_ADDRESS_MUST_BE_THE_FIRST_IP_OF_THE_POOL',
  INVALID_DNS_IP_ADDRESS: 'INVALID_DNS_IP_ADDRESS',
  INVALID_ROUTE_IP_ADDRESS: 'INVALID_ROUTE_IP_ADDRESS',
  INVALID_ROUTE_NETMASK: 'INVALID_ROUTE_NETMASK',
  DISK_IS_ALREADY_STORED_TO_THIS_DATASTORE:
    'DISK_IS_ALREADY_STORED_TO_THIS_DATASTORE',
  NO_AVAILABLE_FREE_SPACE_TO_THE_SELECTED_DATASTORE:
    'NO_AVAILABLE_FREE_SPACE_TO_THE_SELECTED_DATASTORE',
  CLOUD_SERVER_ALREADY_EXISTS_TO_THE_RESOURCE_POOL:
    'CLOUD_SERVER_ALREADY_EXISTS_TO_THE_RESOURCE_POOL',
  EXCEEDED_RESOURCE_POOL_MAX_VM_LIMIT: 'EXCEEDED_RESOURCE_POOL_MAX_VM_LIMIT',
  CLOUD_SERVER_IS_NOT_READY: 'CLOUD_SERVER_IS_NOT_READY',
  INVALID_EXPIRATION_DATE: 'INVALID_EXPIRATION_DATE',
  CANNOT_EDIT_THIS_IP_ADDRESS: 'CANNOT_EDIT_THIS_IP_ADDRESS',
  CANNOT_CHANGE_IP_ADDRESS_RESERVATION: 'CANNOT_CHANGE_IP_ADDRESS_RESERVATION',
  CANNOT_EDIT_PUBLIC_NETWORK: 'CANNOT_EDIT_PUBLIC_NETWORK',
  NETWORK_IS_NOT_READY: 'NETWORK_IS_NOT_READY',
  NOT_FOUND: 'NOT_FOUND',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  BILLING_UNAVAILABLE: 'BILLING_UNAVAILABLE',
  FIRMWARE_NOT_FOUND: 'FIRMWARE_NOT_FOUND',
  FAILED_TO_SHUTDOWN: 'FAILED_TO_SHUTDOWN',
  NO_AVAILABLE_IP_FOUND: 'NO_AVAILABLE_IP_FOUND',
  FAILED_TO_APPROVE_IP: 'FAILED_TO_APPROVE_IP',
  FAILED_TO_PUBLISH_IP: 'FAILED_TO_PUBLISH_IP',
  UNKNOWN_PYLON_CUSTOMER: 'UNKNOWN_PYLON_CUSTOMER',
  ORDER_ALREADY_EXISTS: 'ORDER_ALREADY_EXISTS',
  CANNOT_UPDATE_UNKNOWN_ORDER: 'CANNOT_UPDATE_UNKNOWN_ORDER',
  INSTANCE_STATUS_IS_DISABLED: 'INSTANCE_STATUS_IS_DISABLED',
  THIS_OPERATION_IS_UNAVAILABLE: 'THIS_OPERATION_IS_UNAVAILABLE',
  INFRASTRUCTURE_IS_UNAVAILABLE: 'INFRASTRUCTURE_IS_UNAVAILABLE',
  ERROR_WITH_SCOPES: 'ERROR_WITH_SCOPES',
  DATA_NOT_FETCHED: 'DATA_NOT_FETCHED',
  NETWORK_NOT_READY: 'NETWORK_NOT_READY',
  CORES_MUST_BE_MULTIPLE_OF_CORES_PER_SOCKET:
    'CORES_MUST_BE_MULTIPLE_OF_CORES_PER_SOCKET',
  NO_OTHER_PUBLIC_NETWORKS: 'NO_OTHER_PUBLIC_NETWORKS',
  IP_ADDRESS_DOES_NOT_BELONG_TO_THIS_NETWORK:
    'IP_ADDRESS_DOES_NOT_BELONG_TO_THIS_NETWORK',
  FILE_DOES_NOT_FOUND: 'FILE_DOES_NOT_FOUND',
  INSTANCE_MUST_HAVE_AT_LEAST_ONE_PUBLIC_NETWORK_CARD:
    'INSTANCE_MUST_HAVE_AT_LEAST_ONE_PUBLIC_NETWORK_CARD',
  VPN_CONCENTRATOR_CANNOT_RELEASE_PUBLIC_IP_ADDRESS:
    'VPN_CONCENTRATOR_CANNOT_RELEASE_PUBLIC_IP_ADDRESS',
  PRIVATE_IP_ADDRESS_CANNOT_BELONG_TO_PUBLIC_NETWORK:
    'PRIVATE_IP_ADDRESS_CANNOT_BELONG_TO_PUBLIC_NETWORK',
  OVERLAPPING_NETWORK_ADDRESS_WITH_IP_SEC:
    'OVERLAPPING_NETWORK_ADDRESS_WITH_IP_SEC',
  SECONDARY_CANNOT_MATCH_WITH_PRIMARY_IP:
    'SECONDARY_CANNOT_MATCH_WITH_PRIMARY_IP',
  NETWORK_CARDS_DO_NOT_BELONG_TO_THIS_ROUTER:
    'NETWORK_CARDS_DO_NOT_BELONG_TO_THIS_ROUTER',
  STATIC_ROUTE_USES_TUNNEL_IP_ADDRESS: 'STATIC_ROUTE_USES_TUNNEL_IP_ADDRESS',
  GLOBAL_PSK_MUST_BE_SET: 'GLOBAL_PSK_MUST_BE_SET',
  TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER:
    'TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER',
  TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER_INTERNAL_CARD:
    'TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER_INTERNAL_CARD',
  CANNOT_EDIT_SESSION_TYPE: 'CANNOT_EDIT_SESSION_TYPE',
  STATIC_ROUTE_USES_TUNNEL_CIDR: 'STATIC_ROUTE_USES_TUNNEL_CIDR',
  IP_SEC_SITE_USES_GLOBAL_PSK: 'IP_SEC_SITE_USES_GLOBAL_PSK',
  INVALID_TUNNEL_ADDRESS: 'INVALID_TUNNEL_ADDRESS',
  INVALID_TUNNEL_PREFIX: 'INVALID_TUNNEL_PREFIX',
  SERVICE_SOURCE_PORT_ALREADY_EXISTS: 'SERVICE_SOURCE_PORT_ALREADY_EXISTS',
  INVALID_PERSISTENCE_MODE_FOR_TCP: 'INVALID_PERSISTENCE_MODE_FOR_TCP',
  NONE_SAME_PRIVATE_NETWORK: 'NONE_SAME_PRIVATE_NETWORK',
  CANNOT_ADD_SAME_CLOUD_SERVER: 'CANNOT_ADD_SAME_CLOUD_SERVER',
  UNABLE_TO_FIND_POOL: 'UNABLE_TO_FIND_POOL',
  NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_IP_SEC_PEER_SUBNET:
    'NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_IP_SEC_PEER_SUBNET',
  IP_SEC_PEER_SUBNET_CANNOT_BE_SUBNET_OF_NETWORK_ADDRESS:
    'IP_SEC_PEER_SUBNET_CANNOT_BE_SUBNET_OF_NETWORK_ADDRESS',
  INVALID_NEXT_HOP: 'INVALID_NEXT_HOP',
  OVERLAP_NEXT_HOP_ADDRESS_WITH_TUNNEL_CIDR:
    'OVERLAP_NEXT_HOP_ADDRESS_WITH_TUNNEL_CIDR',
  CANNOT_ACCESS_NETWORK: 'CANNOT_ACCESS_NETWORK',
  CANNOT_ADD_ANOTHER_NETWORK_CARD: 'CANNOT_ADD_ANOTHER_NETWORK_CARD',
  INVALID_NETWORK_TYPE: 'INVALID_NETWORK_TYPE',
  NETWORK_ALREADY_IN_USE: 'NETWORK_ALREADY_IN_USE',
  INVALID_CIDR: 'INVALID_CIDR',
  INVALID_NETWORK_CARD: 'INVALID_NETWORK_CARD',
  INVALID_VALUE: 'INVALID_VALUE',
  INVALID_CIDR_FOR_ROUTES: 'INVALID_CIDR_FOR_ROUTES',
  INVALID_ROUTE: 'INVALID_ROUTE',
  CANNOT_DELETE_PUBLIC_CARD: 'CANNOT_DELETE_PUBLIC_CARD',
  CANNOT_DELETE_LAST_PRIVATE_CARD: 'CANNOT_DELETE_LAST_PRIVATE_CARD',
  CARD_IS_USED_AS_USER_ROUTE: 'CARD_IS_USED_AS_USER_ROUTE',
  POOL_NAME_ALREADY_EXISTS: 'POOL_NAME_ALREADY_EXISTS',
  INVALID_START_IP_ADDRESS: 'INVALID_START_IP_ADDRESS',
  POOL_RANGE_OVERPLAPS_EXISTING_POOLS: 'POOL_RANGE_OVERPLAPS_EXISTING_POOLS',
  IP_ADDRESS_CONFLICTS_WITH_THE_NETWORK_CARDS:
    'IP_ADDRESS_CONFLICTS_WITH_THE_NETWORK_CARDS',
  CANNOT_EDIT_IP_ADDRESSES: 'CANNOT_EDIT_IP_ADDRESSES',
  VPN_PROFILE_USES_THIS_POOL: 'VPN_PROFILE_USES_THIS_POOL',
  PROFILE_NAME_ALREADY_EXISTS: 'PROFILE_NAME_ALREADY_EXISTS',
  VPN_USER_USES_THIS_PROFILE: 'VPN_USER_USES_THIS_PROFILE',
  FAILED_TO_FETCH_DATA: 'FAILED_TO_FETCH_DATA',
  VCENTER_MISMATCH: 'VCENTER_MISMATCH',
  STORAGE_ACCOUNT_IS_PROTECTED: 'STORAGE_ACCOUNT_IS_PROTECTED',
  VPN_CONCENTRATOR_IS_PROTECTED: 'VPN_CONCENTRATOR_IS_PROTECTED',
  REMOTE_ENDPOINT_CANNOT_BE_ASSIGNED_TO_NETWORK_CARD:
    'REMOTE_ENDPOINT_CANNOT_BE_ASSIGNED_TO_NETWORK_CARD',
  CANNOT_DELETE_LAST_INTERNAL_NETWORK_CARD:
    'CANNOT_DELETE_LAST_INTERNAL_NETWORK_CARD',
  NO_DELETE_UPLINK_NETWORK_CARD: 'NO_DELETE_UPLINK_NETWORK_CARD',
  RESOURCE_NAME_ALREADY_EXISTS: 'RESOURCE_NAME_ALREADY_EXISTS',
  CANNOT_EDIT_RESOURCE_TYPE: 'CANNOT_EDIT_RESOURCE_TYPE',
  CANNOT_DELETE_RESOURCE: 'CANNOT_DELETE_RESOURCE',
  LICENSE_INSTANCE_ALREADY_EXISTS: 'LICENSE_INSTANCE_ALREADY_EXISTS',
  LICENSE_INSTANCE_NOT_ACTIVE: 'LICENSE_INSTANCE_NOT_ACTIVE',
  ADDON_SERVICE_INSTANCE_ALREADY_EXISTS:
    'ADDON_SERVICE_INSTANCE_ALREADY_EXISTS',
  ADDON_SERVICE_INSTANCE_NOT_ACTIVE: 'ADDON_SERVICE_INSTANCE_NOT_ACTIVE',
  DISK_EXIST_IN_BACKUPS: 'DISK_EXIST_IN_BACKUPS',
  LOGIN_BLOCKED_RS: 'LOGIN_BLOCKED_RS',
  REGISTER_BLOCKED_RS: 'REGISTER_BLOCKED_RS',
  S_DENIES_ACCESS: 'S_DENIES_ACCESS',
  R_NOT_FOUND: 'R_NOT_FOUND',
  INVALID_NAME: 'INVALID_NAME',
  MISSING_OR_EXPIRED_TOKEN: 'MISSING_OR_EXPIRED_TOKEN',
  MISSING_PERMISSION: 'MISSING_PERMISSION',
  CANNOT_ASSIGN_SAME_PRIVACY: 'CANNOT_ASSIGN_SAME_PRIVACY',
  INVITE_NOT_FOUND: 'INVITE_NOT_FOUND',
  INVITE_EXPIRED: 'INVITE_EXPIRED'
}

export const MachinePasswordOptions = {
  GENERATE: 'GENERATE',
  MANUAL: 'MANUAL',
  MANUAL_NO_EMAIL: 'MANUAL_NO_EMAIL'
}

export const PriceOptions = {
  FIXED: 'FIXED',
  DISCOUNT: 'DISCOUNT'
}

export const RateOptions = {
  FIXED: 'MONTHLY',
  DISCOUNT: 'HOURLY'
}

export const ServiceTypes = {
  DISK: 'DISK',
  ROUTER: 'ROUTER',
  CLOUD_SERVER: 'CLOUD SERVER'
}

export const DedicatedServerTaskType = {
  DEDICATED_SERVER_POWER: 'DEDICATED_SERVER_POWER'
}

export const ActionTypes = {
  CUSTOMER_LEAD_SUBMIT: 'CUSTOMER_LEAD_SUBMIT',
  CUSTOMER_LEAD_APPROVE: 'CUSTOMER_LEAD_APPROVE',
  CUSTOMER_LEAD_REJECT: 'CUSTOMER_LEAD_REJECT',
  CUSTOMER_LEAD_DUPLICATE: 'CUSTOMER_LEAD_DUPLICATE',

  //  Cloud Servers
  CLOUD_SERVER_EDIT_INFOS: 'CLOUD_SERVER_EDIT_INFOS',
  CLOUD_SERVER_CONSOLE: 'CLOUD_SERVER_CONSOLE',
  CLOUD_SERVER_PROTECTION_ENABLE: 'CLOUD_SERVER_PROTECTION_ENABLE',
  CLOUD_SERVER_PROTECTION_DISABLE: 'CLOUD_SERVER_PROTECTION_DISABLE',

  CLOUD_SERVER_BACKUPS_UPDATE: 'CLOUD_SERVER_BACKUPS_UPDATE',

  CLOUD_SERVER_FIRWALL_RULE_ADD: 'CLOUD_SERVER_FIRWALL_RULE_ADD',
  CLOUD_SERVER_FIRWALL_RULE_EDIT: 'CLOUD_SERVER_FIRWALL_RULE_EDIT',
  CLOUD_SERVER_FIRWALL_RULE_DELETE: 'CLOUD_SERVER_FIRWALL_RULE_DELETE',

  CLOUD_SERVER_SNAPSHOT_EDIT: 'CLOUD_SERVER_SNAPSHOT_EDIT',

  CLOUD_SERVER_DISK_EDIT_INFOS: 'CLOUD_SERVER_DISK_EDIT_INFOS',

  //  SSH KEYS

  SSH_KEY_CREATE: 'SSH_KEY_CREATE',
  SSH_KEY_EDIT_INFOS: 'SSH_KEY_EDIT_INFOS',
  SSH_KEY_DELETE: 'SSH_KEY_DELETE',

  //  PUBLIC NETWORKS

  PUBLIC_NETWORK_CREATE: 'PUBLIC_NETWORK_CREATE',
  PUBLIC_NETWORK_UPDATE: 'PUBLIC_NETWORK_UPDATE',
  PUBLIC_NETWORK_DELETE: 'PUBLIC_NETWORK_DELETE',

  //  PRIVATE NETWORKS

  PRIVATE_NETWORK_EDIT_INFOS: 'PRIVATE_NETWORK_EDIT_INFOS',
  PRIVATE_NETWORK_PROTECTION_ENABLE: 'PRIVATE_NETWORK_PROTECTION_ENABLE',
  PRIVATE_NETWORK_PROTECTION_DISABLE: 'PRIVATE_NETWORK_PROTECTION_DISABLE',

  //  IP ADDRESS BLOCK

  IP_ADDRESS_BLOCK_CREATE: 'IP_ADDRESS_BLOCK_CREATE',
  IP_ADDRESS_BLOCK_UPDATE: 'IP_ADDRESS_BLOCK_UPDATE',
  IP_ADDRESS_BLOCK_DELETE: 'IP_ADDRESS_BLOCK_DELETE',

  //  VMWARE CONTROLLER

  VMWARE_VCENTER_CREATE: 'VMWARE_VCENTER_CREATE',
  VMWARE_VCENTER_UPDATE: 'VMWARE_VCENTER_UPDATE',
  VMWARE_VCENTER_DISK_TYPES_ASSIGN: 'VMWARE_VCENTER_DISK_TYPES_ASSIGN', // to do

  //  DATACENTER

  DATACENTER_CREATE: 'DATACENTER_CREATE',
  DATACENTER_UPDATE: 'DATACENTER_UPDATE',
  DATACENTER_DELETE: 'DATACENTER_DELETE',

  //  ROUTER

  ROUTER_EDIT_INFOS: 'ROUTER_EDIT_INFOS',
  ROUTER_PROTECTION_ENABLE: 'ROUTER_PROTECTION_ENABLE',
  ROUTER_PROTECTION_DISABLE: 'ROUTER_PROTECTION_DISABLE',

  ROUTER_CERTIFICATE_ADD: 'ROUTER_CERTIFICATE_ADD',
  ROUTER_CERTIFICATE_NOTES_EDIT: 'ROUTER_CERTIFICATE_NOTES_EDIT',
  ROUTER_CERTIFICATE_DELETE: 'ROUTER_CERTIFICATE_DELETE',

  ROUTER_FIRWALL_RULE_ADD: 'ROUTER_FIRWALL_RULE_ADD',
  ROUTER_FIRWALL_RULE_EDIT: 'ROUTER_FIRWALL_RULE_EDIT',
  ROUTER_FIRWALL_RULE_DELETE: 'ROUTER_FIRWALL_RULE_DELETE',

  //  ROUTER IPSECS

  ROUTER_IPSEC_ADD: 'ROUTER_IPSEC_ADD',
  ROUTER_IPSEC_EDIT: 'ROUTER_IPSEC_EDIT',
  ROUTER_IPSEC_DELETE: 'ROUTER_IPSEC_DELETE',
  ROUTER_IPSEC_GLOBAL_UPDATE: 'ROUTER_IPSEC_GLOBAL_UPDATE',
  ROUTER_IPSEC_GLOBAL_DELETE: 'ROUTER_IPSEC_GLOBAL_DELETE',

  //  ROUTER STATIC ROUTES

  ROUTER_STATIC_ROUTE_ADD: 'ROUTER_STATIC_ROUTE_ADD',
  ROUTER_STATIC_ROUTE_EDIT: 'ROUTER_STATIC_ROUTE_EDIT',
  ROUTER_STATIC_ROUTE_DELETE: 'ROUTER_STATIC_ROUTE_DELETE',

  // ROUTER NAT

  ROUTER_NAT_ADD: 'ROUTER_NAT_ADD',
  ROUTER_NAT_EDIT: 'ROUTER_NAT_EDIT',
  ROUTER_NAT_DELETE: 'ROUTER_NAT_DELETE',

  //  STORAGE

  STORAGE_EDIT_INFOS: 'STORAGE_EDIT_INFOS',
  STORAGE_API_TOKEN_CREATE: 'STORAGE_API_TOKEN_CREATE',
  STORAGE_PROTECTION_ENABLE: 'STORAGE_PROTECTION_ENABLE',
  STORAGE_PROTECTION_DISABLE: 'STORAGE_PROTECTION_DISABLE',

  // Vpn Concentrator

  VPN_CONCENTRATOR_EDIT_INFOS: 'VPN_CONCENTRATOR_EDIT_INFOS',

  //  DEDICATED SERVERS

  DEDICATED_SERVER_EDIT_INFOS: 'DEDICATED_SERVER_EDIT_INFOS',
  DEDICATED_SERVER_CONSOLE: 'DEDICATED_SERVER_CONSOLE',
  DEDICATED_SERVER_CONSOLE_RESTART: 'DEDICATED_SERVER_CONSOLE_RESTART',

  // ---------ADMIN ROUTES----------

  //  CUSTOMERS

  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_UPDATE: 'CUSTOMER_UPDATE',
  CUSTOMER_INFO_UPDATE: 'CUSTOMER_INFO_UPDATE',
  CUSTOMER_RESOURCES_UPDATE: 'CUSTOMER_RESOURCES_UPDATE',
  CUSTOMER_PRICING_CREATE: 'CUSTOMER_PRICING_CREATE',
  CUSTOMER_PRICING_UPDATE: 'CUSTOMER_PRICING_UPDATE',

  USER_STATUS_UPDATE: 'USER_STATUS_UPDATE',

  //  PRODUCTS

  PRODUCT_CREATE: 'PRODUCT_CREATE',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRODUCT_DELETE: 'PRODUCT_DELETE',

  //  PRODUCTS CATEGORIES

  PRODUCT_CATEGORIES_CREATE: 'PRODUCT_CATEGORIES_CREATE',
  PRODUCT_CATEGORIES_UPDATE: 'PRODUCT_CATEGORIES_UPDATE',
  PRODUCT_CATEGORIES_DELETE: 'PRODUCT_CATEGORIES_DELETE',

  //  RESOURCES

  RESOURCES_CREATE: 'RESOURCES_CREATE',
  RESOURCES_UPDATE: 'RESOURCES_UPDATE',
  RESOURCES_DELETE: 'RESOURCES_DELETE',

  //  LICENSES

  LICENCES_CREATE: 'LICENCES_CREATE',
  LICENCES_UPDATE: 'LICENCES_UPDATE',
  LICENCES_DELETE: 'LICENCES_DELETE',
  LICENCES_ASSOCIATE: 'LICENCES_ASSOCIATE',
  LICENCES_QUANTITY_EDIT: 'LICENCES_QUANTITY_EDIT',
  LICENCES_DISASSOCIATE: 'LICENCES_DISASSOCIATE',

  // ADD-ONS
  ADDONS_CREATE: 'ADDONS_CREATE',
  ADDONS_UPDATE: 'ADDONS_UPDATE',
  ADDONS_DELETE: 'ADDONS_DELETE',
  ADDONS_ASSOCIATE: 'ADDONS_ASSOCIATE',
  ADDONS_DISASSOCIATE: 'ADDONS_DISASSOCIATE',

  // RESOURCE GROUPS

  RESOURCE_GROUPS_CREATE: 'RESOURCE_GROUPS_CREATE',
  RESOURCE_GROUPS_UPDATE: 'RESOURCE_GROUPS_UPDATE',
  RESOURCE_GROUPS_DELETE: 'RESOURCE_GROUPS_DELETE',

  //  CLOUD SERVER

  CLOUD_SERVER_STATUS_UPDATE: 'CLOUD_SERVER_STATUS_UPDATE',
  CLOUD_SERVER_OPERATING_SYSTEM_VERSION_UPDATE:
    'CLOUD_SERVER_OPERATING_SYSTEM_VERSION_UPDATE',
  CLOUD_SERVER_READ_ONLY_MODE_UPDATE: 'CLOUD_SERVER_READ_ONLY_MODE_UPDATE',

  //  NETWORK

  NETWORK_STATUS_UPDATE: 'NETWORK_STATUS_UPDATE',

  // IP ADDRESS

  IP_RESERVATION_UPDATE: 'IP_RESERVATION_UPDATE',
  IP_INTERNAL_NOTE_UPDATE: 'IP_INTERNAL_NOTE_UPDATE',

  CUSTOMER_USERS_INVITE: 'CUSTOMER_USERS_INVITE',
  CUSTOMER_USER_EDIT: 'CUSTOMER_USER_EDIT',
  USER_ROLE_UPDATE: 'USER_ROLE_UPDATE',
  CUSTOMER_USER_REMOVE: 'CUSTOMER_USER_REMOVE',

  // BACKUP SERVER

  BACKUP_SERVER_CREATE: 'BACKUP_SERVER_CREATE',
  BACKUP_SERVER_UPDATE: 'BACKUP_SERVER_UPDATE',
  BACKUP_SERVER_DELETE: 'BACKUP_SERVER_DELETE'
}

export const InstanceStatusEnum = {
  DONE: 'DONE',
  DISABLED: 'DISABLED'
}

export const SystemOperationEnum = {
  CUSTOMER_RESOURCES_SYNC: 'CUSTOMER_RESOURCES_SYNC',
  CUSTOMER_ORDERS_SYNC: 'CUSTOMER_ORDERS_SYNC',
  VMWARE_IMPORT_REPORT: 'VMWARE_IMPORT_REPORT',
  VMWARE_NETWORK_SYNC: 'VMWARE_NETWORK_SYNC',
  VMWARE_MIGRATE_HOST: 'VMWARE_MIGRATE_HOST',
  VMWARE_CROSS_VCENTER_MIGRATE: 'VMWARE_CROSS_VCENTER_MIGRATE',
  VMWARE_DVPORT_GROUP: 'VMWARE_DVPORT_GROUP',
  VMWARE_DVPORT_GROUP_NSXT: 'VMWARE_DVPORT_GROUP_NSXT',
  VMWARE_RESOURCE_POOL_CREATE: 'VMWARE_RESOURCE_POOL_CREATE'
}

export const MigrationTypeEnum = {
  HOST: 'Host',
  DATASTORE: 'Datastore',
  BOTH: 'Both'
}

export const PortGroupOptionsEnum = {
  DS_PORT_GROUP: 'DS_PORT_GROUP',
  NSX_PORT_GROUP: 'NSX_PORT_GROUP'
}
