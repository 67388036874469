<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { setCssVar, useQuasar } from 'quasar'

// commons
import { branding } from 'src/commons/branding'

declare const window: Window & { dataLayer: Record<string, unknown>[]; }

onMounted(() => {
  const custom_script = document.createElement('script')
  custom_script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-GSMZ99DKVG')
  document.head.appendChild(custom_script)
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    js: new Date(),
    config: 'G-GSMZ99DKVG'
  })
})

const q = useQuasar()

if (!localStorage.getItem('theme')) {
  localStorage.setItem('theme', String(q.dark.isActive))
} else if (localStorage.getItem('theme') === 'true' && !q.dark.isActive) {
  q.dark.toggle()
}

q.iconMapFn = iconName => {
  // iconName is the content of QIcon "name" prop

  // your custom approach, the following
  // is just an example:
  if (iconName.startsWith('lci-') === true) {
    return {
      cls: iconName
    }
  }

  // when we don't return anything from our
  // iconMapFn, the default Quasar icon mapping
  // takes over
}

watch(() => q.dark.isActive, () => {
  setCssVar('primary', branding.value.BRAND_COLOR)
})
</script>
