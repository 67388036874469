// TODO replace all store calls with pinia store

import { appStore } from 'src/store/app'
import { notificationsStore } from 'src/store/notifications'
import { userStore } from 'src/store/user'
// eslint-disable-next-line import/no-named-default
import { AxiosError, default as Axios } from 'axios'

// import { Router } from 'src/router';
import { boot } from 'quasar/wrappers'

import { i18n } from '../boot/i18n'

import { ErrorMessages } from '../commons/Enums'
import { humanReadable } from 'src/commons/Utilities'

export interface ServerResponse extends AxiosError {
  error: string;
  message: string;
  field: string;
}
const publicRoutes = [
  '/login',
  '/register',
  '/reset-password',
  '/forgot-password'
]

export const api = Axios.create({
  baseURL: process.env.api,
  withCredentials: true
})

export default boot(({ app }) => {
  const AppStore = appStore()
  const user = userStore()
  const notifications = notificationsStore()
  api.interceptors.response.use(
    response => {
      return response
    },
    (error: AxiosError) => {
      const path = window.location.pathname
      let showNotification = true
      if (
        error.response &&
        error.response.status === 401 &&
        publicRoutes.indexOf(path) === -1
      ) {
        console.warn('Session expired')
        if (user.getUser?.id) {
          AppStore.openLoginModal()
        }
        showNotification = false
      } else if (
        error.response &&
        error.response.status === 401 &&
        (error.response.data as ServerResponse).error === 'UNAUTHENTICATED'
      ) {
        showNotification = false
      } else if (
        error.response &&
        error.response.status === 403 &&
        (error.response.data as ServerResponse).error ===
          'REQUIRES_PASSWORD_CHANGE'
      ) {
        showNotification = false
      } else if (
        error.response &&
        error.response.data &&
        (error.response.data as ServerResponse).error === 'AUTH_ERROR'
      ) {
        showNotification = false
      } else {
        console.error(error)
      }

      if (showNotification) {
        if (
          error.response &&
          error.response.data &&
          (error.response.data as ServerResponse).message
        ) {
          const errorMessage = (error.response.data as ServerResponse).message
          const enumExists = Object.values(ErrorMessages).includes(errorMessage)
          const notificationText = enumExists ? 'enums.tasks.error.' + (error.response.data as ServerResponse).message : humanReadable(errorMessage)

          notifications.notify({
            color: 'negative',
            text: i18n.global.t(
              notificationText,
              {
                field: (error.response.data as ServerResponse).field
              }
            )
          })
        } else if (
          error.response &&
          error.response.data &&
          (error.response.data as ServerResponse).error
        ) {
          notifications.notify({
            color: 'negative',
            text: i18n.global.t(
              'enums.tasks.error.' + (error.response.data as ServerResponse).error
            )
          })
        } else {
          notifications.notify({
            color: 'negative',
            text: error.toString()
          })
        }
      }

      return Promise.reject(error)
    }
  )

  app.config.globalProperties.$axios = api
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})
